// Maintain a provider to man

import dashboardApi from "../../../api/dashboard";
import { getItem } from "../../../utils/persistanceStorage";
import { useParams } from "react-router";
import { useState, useEffect } from "react";
import { FaStopwatch } from "react-icons/fa";
import { FaUser, FaUsers, FaSquare, FaThLarge } from "react-icons/fa";
import { colors } from "../../../config/colors";
import { autoevents } from "../../../config/monitor";

const Dashboard = ({ courseid }) => {
  const params = useParams();

  const [timeSessionStart, setTimeSessionStart] = useState(0);
  const [timeCurr, setTimeCurr] = useState(0);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [numActivitiesOpened, setNumActivitiesOpened] = useState(0);

  const [usrSessionLength, setUsrSessionLength] = useState(0);
  const [avgSessionLength, setAvgSessionLength] = useState(0);
  const [usrSessionActs, setUsrSessionActs] = useState(0);
  const [avgSessionActs, setAvgSessionActs] = useState(0);

  const [numUsrActSq, setNumUsrActSq] = useState(0);
  const [numUsrActLg, setNumUsrActLg] = useState(0);
  const [numAvgActSq, setNumAvgActSq] = useState(0);
  const [numAvgActLg, setNumAvgActLg] = useState(0);

  useEffect(() => {
    refreshSessionStats();
  }, []);

  const renderUsrIcons = () => {
    const largeIcons = Math.floor(usrSessionActs / 4);
    const squareIcons = usrSessionActs % 4;

    if (usrSessionActs == 0) {
      return <span className="text-xs font-light">Start working to track your progress</span>;
    }

    return (
      <>
        {[...Array(largeIcons)].map((_, i) => (
          <FaThLarge key={`large-${i}`} className="text-3xl" style={{ color: colors.bar_score }} />
        ))}
        {[...Array(squareIcons)].map((_, i) => (
          <FaSquare key={`square-${i}`} className="text-3xl" style={{ color: colors.bar_score }} />
        ))}
      </>
    );
  };

  const renderAvgIcons = () => {
    const largeIcons = Math.floor(avgSessionActs / 4);
    const squareIcons = avgSessionActs % 4;

    return (
      <>
        {[...Array(largeIcons)].map((_, i) => (
          <FaThLarge key={`large-${i}`} className="text-3xl" style={{ color: colors.bar_lightblue }} />
        ))}
        {[...Array(squareIcons)].map((_, i) => (
          <FaSquare key={`square-${i}`} className="text-3xl px-0" style={{ color: colors.bar_lightblue }} />
        ))}
      </>
    );
  };

  const refreshSessionStats = () => {
    dashboardApi
      .getSessionStats(getItem("user-data")?.sessionid, courseid)
      .then((response) => {
        setUsrSessionLength(response.data.userSessionLength);
        setAvgSessionLength(response.data.avgSessionLength);
        setUsrSessionActs(response.data.usrActivities);
        setAvgSessionActs(response.data.avgActivities);
      })
      .catch((error) => {
        console.error("Error fetching session stats");
      });
  };

  useEffect(() => {
    const refreshInterval = setInterval(refreshSessionStats, autoevents.dashboardRefresh);

    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  return (
    <div className="flex max-w-screen-lg w-3/5 min-w-3/5">
      <div className="flex items-center">
        <FaStopwatch className="text-bar_score text-8xl" />
      </div>

      <div className="flex flex-col justify-center items-center">
        <div className="flex flex-col">
          <div className="flex justify-between items-center p-2">
            <div className="text-3xl font-semibold text-gray-700 px-2">{usrSessionLength} m</div>
            <div className="text-gray-600">
              Your Current <br></br>Session Time
            </div>
          </div>
          <div className="flex justify-between items-center p-2">
            <div className="text-3xl font-semibold text-gray-700 px-2">{avgSessionLength} m</div>
            <div className="text-gray-600">
              Class Average<br></br>This Week
            </div>
          </div>
        </div>
      </div>

      <div className="w-4">&nbsp;</div>

      <div className="flex flex-1 flex-col ml-4">
        <div className="flex flex-row">&nbsp;</div>
        <div className="flex flex-row">
          <div className="pr-8">
            <FaUser className="text-bar_score text-5xl" />
          </div>
          <div className="grid grid-cols-8 gap-1 items-center">{renderUsrIcons()}</div>
        </div>

        <div className="flex flex-row">
          <div className="pr-8">
            <FaUsers className="text-5xl" style={{ color: colors.bar_lightblue }} />
          </div>
          <div className="grid grid-cols-8 gap-1 items-center">{renderAvgIcons()}</div>
        </div>

        <div className="ml-20 m-0 p-0">
          <div className="text-gray-700">Session Activities</div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
