import ConceptSocialBars from "./ConceptSocialBars";
import ConceptFeedbackIcon from "./ConceptFeedbackIcon";


const ConceptHeader = ({ concept, isActive, hovering, activityProgress, wantedHeight, conceptFeedback }) => {
  // Either truncate the title if its a concept or show the whole title if its a misconception
  const titleStyle =
    concept.type == "concept"
      ? {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          fontWeight: 600,
          paddingLeft: 10,
        }
      : {};

  return (
      <div className="relative w-full" data-tip={conceptFeedback}>
        <div className="flex justify-between">
          <div style={titleStyle} className="p-1 text font-MS text-center">
            {`${concept.name}`}
          </div>
          <div className="mr-2">
            <ConceptFeedbackIcon concept={concept} activityProgress={activityProgress} isActive={isActive} hovering={hovering} />
          </div>
        </div>
        {/* TODO: Concept Social Bars should use social progress as well and accept it as a prop */}
        {concept.type !== "fauxconcept" && (
          <ConceptSocialBars
            concept={concept}
            hovering={hovering}
            activityProgress={activityProgress}
            isActive={isActive}
            wantedHeight={wantedHeight}
          />
        )}
      </div>

  );
};

export default ConceptHeader;
