import { createContext, useContext, useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router";
import courseApi from "../api/course";
import { getUserEventDataForLearningMaterial, getUserEventDataForLearningMaterialForCourse, getUserEventsForCourse } from "../api/userevents";
import { useUser } from "./UserContext";
const TeacherPortalContext = createContext();

export function TeacherPortalProvider({ children }) {
  const { getCourseFromId } = useUser();
  const navigate = useNavigate();
  const [course, setCourse] = useState(null);
  const [activities, setActivities] = useState([]);
  const params = useParams();

  const [activitiesWithUsageData, setActivitiesWithUsageData] = useState({});

  const [loadingInfo, setLoadingInfo] = useState(true);
  const [loadingError, setLoadingError] = useState("");
  const [quizesInformation, setQuizesInformation] = useState({});
  const [studentInformation, setStudentInformation] = useState([]);

  const [activitiesWithUsageDataGroupedByType, setActivitiesWithUsageDataGroupedByType] = useState(null);

  const [userEvents, setUserEvents] = useState([])
  useEffect(() => {

    // Would like to refactor these into a single function, optimizing awaits and promises as necesary. 
    // For now this works but can use improvement.
    getCourseInformation();
    getUserEvents();
    getStudents();
    getCourseDetails();

  }, []);

  const getUserEvents = () => {
    getUserEventsForCourse(params.courseid, 0, 10).then((response) => {
      setUserEvents(response.data.user_events)
    })
  }
  const getStudents = () => {
    courseApi.getStudentsInformation(params.courseid).then((response) => {
      setStudentInformation(response.data.course.students)
    })
  }

  useEffect(() => {
    async function fetchActivities() {

      if (activities.length > 0) {
        const activityUserDataPromise = getActivitiesUsageData(activities)

        const quizPromises = activities.filter((a) => a.activitytype == "learning_material_quiz").map((quiz) => GetQuizInformation(quiz.activityid))


        const activityUserData = await activityUserDataPromise

        setActivitiesWithUsageData(activityUserData)

        const activityUserDataGroupedByType = activityUserData.reduce((acc, activity) => {
          if (!acc[activity.activitytype]) {
            acc[activity.activitytype] = []
          }
          acc[activity.activitytype].push(activity)
          return acc
        }, {})

        setActivitiesWithUsageDataGroupedByType(activityUserDataGroupedByType)

        const quizesInformation = await Promise.all(quizPromises)
        setQuizesInformation(quizesInformation)


        setLoadingInfo(false);
      }
    }
    fetchActivities();


  }, [activities])

  const GetQuizInformation = async (quizid) => {

    const quizInformationRes = await courseApi.getQuizInformation(
      quizid
    );


    if (
      quizInformationRes.status == 200
    ) {
      return quizInformationRes.data
    }
  };


  const getActivitiesUsageData = async (activitiesLocal) => {


    const usageDataPerActivityResponse = (await getUserEventDataForLearningMaterialForCourse(params.courseid)).data;
    const usageDataPerActivity = usageDataPerActivityResponse.course.learning_materials;
    const usageDataPerActivityDict = {};
    usageDataPerActivity.forEach((usageData) => {
      usageDataPerActivityDict[usageData.activityid] = usageData;
    })

    const localActivitiesWithUsageData = activitiesLocal.map((activity) => {
      return {
        ...activity,
        ...usageDataPerActivityDict[activity.activityid]
      }
    })
    return localActivitiesWithUsageData
  }

  const getCourseInformation = () => {
    // Get the course information
    const courseInfo = getCourseFromId(params.courseid);
    if (courseInfo) {
      setCourse(courseInfo);
      // Try to set experiment group for the course
    } else {
      navigate("/");
    }

  };

  const getCourseDetails = async () => {
    try {
      // Get course details
      const course_activities_res = await courseApi.getActivitiesForCourse(
        params.courseid
      );

      if (
        course_activities_res.status == 200
      ) {
        setActivities(course_activities_res.data.activities);


      } else {
        setLoadingError("failed to load info");
      }
    } catch (error) {

      localStorage.clear();
      navigate("/login");
    }
  };
  const loadMoreUserEvents = async (page) => {
    const userEventsRes = await getUserEventsForCourse(params.courseid, userEvents.length, 10);
    if (userEventsRes.status == 200) {
      const newArray = userEvents.concat(userEventsRes.data.user_events)
      setUserEvents(newArray)
    }
  }

  const loadActivityUsageData = async (activity) => {
    const learningMaterialRes = await getUserEventDataForLearningMaterial(
      activity.activityid
    );


    if (
      learningMaterialRes.status == 200
    ) {

      activity = learningMaterialRes.data;
      return

    }
  };

  const value = {
    loadingInfo,
    loadingError,
    course,
    activities,
    activitiesWithUsageData,
    userEvents,
    loadActivityUsageData,
    loadMoreUserEvents,
    activitiesWithUsageDataGroupedByType,
    quizesInformation,
    studentInformation,
  };

  return (
    <TeacherPortalContext.Provider value={value}>
      {children}
    </TeacherPortalContext.Provider>
  );
}

export function useTeacherPortalContext() {
  return useContext(TeacherPortalContext);
}



