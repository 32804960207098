import { Box, Card, Tooltip, Typography, Grid } from "@material-ui/core";
import TeacherPortalVerticalProgressBarWithTooltip from "../../general/progress_bar/VerticalProgressBarWithTooltip";
import { colors } from "../../../../config/colors";


const TeacherPortalQuestionStatisticsOption = ({ option, amount_chosen, amount_total, point_worth }) => {

    const chosen_ratio = amount_total == 0 ? 0 : amount_chosen / amount_total;

    return (<>
        <Card style={{ backgroundColor: point_worth ? colors.success : colors.error, marginBottom: 16, border: "1px solid #f2f2f2", paddingLeft: 8, paddingBottom: 8 }}>
            <Grid container>
                <Grid item xs={8}>
                    <Box>
                        <Typography dangerouslySetInnerHTML={{ __html: option }} />
                    </Box>
                </Grid>
                <Grid item xs={1}>
                    <Box>
                        {point_worth ? <Typography>(Correct)</Typography> : <Typography>(Wrong)</Typography>}
                    </Box>
                </Grid>
                <Grid item xs={1}>
                    <Box>
                        <Tooltip title={amount_chosen + " out of " + amount_total + " students chose this answer"}><Typography>
                            {amount_chosen} / {amount_total}
                        </Typography>
                        </Tooltip>
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <TeacherPortalVerticalProgressBarWithTooltip floatProgress={chosen_ratio} tooltipString={Math.round(100 * chosen_ratio) + "% of students chose this answer"} />
                </Grid>
            </Grid>
        </Card>
    </>
    )

};

export default TeacherPortalQuestionStatisticsOption