import Grid from "@material-ui/core/Grid";
import { Box, Typography, List, ListItem, ListItemIcon, Divider, ListItemText } from "@material-ui/core";
import TeacherPortalQuiz from "./quiz/Quiz";
import { NavLink } from "react-router-dom";
import { AiOutlineContacts, AiOutlineHome, AiOutlineUserSwitch } from "react-icons/ai";
import { useEffect, useState } from "react";
import Icon from "../general/Icon";
import TeacherPortalLearningMaterials from "./learning_materials/LearningMaterials";
import TeacherPortalCourseDashboard from "./course/CourseDashboard";
import TeacherPortalStudents from "./students/Students";
const TeacherPortalSidebarNavigation = ({ course, activities, activitiesWithUsageData, setSelectedPage, quizesInformation, activitiesWithUsageDataGroupedByType, userEvents, loadMoreUserEvents, students }) => {
    const [menuItems, setMenuItems] = useState([]);
    useEffect(() => {
        const dashboardPage = <TeacherPortalCourseDashboard activitiesWithUsageDataGroupedByType={activitiesWithUsageDataGroupedByType} quizesInformation={quizesInformation} userEvents={userEvents} loadMoreUserEvents={loadMoreUserEvents} />

        setMenuItems([
            {
                name: "General",
                items: [
                    {
                        name: "Student view",
                        icon: <AiOutlineUserSwitch size={30} color={"black"} />,
                        link: "/course/" + course.id
                    },
                    {
                        name: "Dashboard",
                        icon: <AiOutlineHome size={30} color={"black"} />,
                        component: dashboardPage
                    }
                ]
            },
            {
                name: "Quizes",
                items: activities != undefined ? activities.filter(a => a.activitytype == "learning_material_quiz").map((activity) => {
                    return {
                        name: activity.activitytitle,
                        icon: <Icon type={activity.activitytype} color="black" />,
                        component: <TeacherPortalQuiz quizInformation={quizesInformation.find((a) => a.activityid == activity.activityid)} />
                    }
                }) : []
            },
            {
                name: "Learning Material",
                items:
                    [{
                        icon: <Icon type="learning_material_paws_reading" color="black" />,
                        name: "All Learning materials",
                        component:
                            <TeacherPortalLearningMaterials
                                activitiesWithUsageData={activitiesWithUsageData}
                                course={course}
                            />
                    }]
                        .concat(activities.filter(() => false).filter(a => a.activitytype != "learning_material_quiz").map((activity) => {
                            return {
                                name: activity.activitytitle,
                                icon: <Icon type={activity.activitytype} color="black" />,
                                component: "/course/" + course.courseid + "/teacher_portal/learning_material/" + activity.activityid
                            }
                        }))
            },
            {
                name: "Students",
                items: [
                    {
                        icon: <AiOutlineContacts size={30} color={"black"} />,
                        name: "All Students",
                        component: <TeacherPortalStudents students={students} quizesInformation={quizesInformation}
                        activities={activities} 
                        activitiesWithUsageData = {activitiesWithUsageData}/>
                    }
                ]
            }
        ])

        setSelectedPage(dashboardPage)
    }, [course, activities, activitiesWithUsageData, quizesInformation, activitiesWithUsageDataGroupedByType, userEvents, loadMoreUserEvents])

    return <>
        <Box>
            {menuItems != undefined ? menuItems.map((menuItem) =>
                <>
                    <Box sx={{ paddingTop: 20, paddingBottom: 20 }}>
                        <Box sx={{ paddingLeft: "15px" }}>
                            <Typography variant="h6" align="left" ><b>{menuItem.name}</b></Typography>
                        </Box >
                        <List>
                            {menuItem.items.map((menuSubItem) => {
                                const children = (<Grid container>
                                    <Grid item xs={2}>
                                        <ListItemIcon>
                                            {menuSubItem.icon}
                                        </ListItemIcon>
                                    </Grid>
                                    <Grid item xs={10}>

                                        <ListItemText primary={menuSubItem.name} />
                                    </Grid>
                                </Grid>)

                                if (menuSubItem.component) {
                                    return <ListItem key="home" button onClick={() => setSelectedPage(menuSubItem.component)}>
                                        {children}
                                    </ListItem>
                                }
                                else {
                                    return <ListItem key="home" button component={NavLink} to={menuSubItem.link}>
                                        {children}
                                    </ListItem>
                                }
                            }
                            )}
                        </List>

                    </Box>
                    <Divider />
                </>
            ) : <></>}
        </Box>
    </>
};

export default TeacherPortalSidebarNavigation