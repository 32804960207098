import ChooseGoalOrientationView from "./ChooseGoalOrientationView";
import OnboardingCompleteView from "./OnboardingCompleteView";
import OnboardingSlide from "../components/activities/onboarding/Slide";
import Loading from "../components/general/Loading";
import OnboardingQuestion from "../components/activities/onboarding/Question";
import NavBar from "../components/general/NavBar";
import courseApi from "../api/course";
import generalApi from "../api/general";
import { useCourse } from "../contexts/CourseProvider";
import { useState, useEffect } from "react";
import { answerOnboardingQuestion } from "../api/postactivity";
import { saveEvent } from "../api/userevents";
import xapiverbs from "../config/xapiverbs";

const OnboardingView = ({onComplete, askGoalOrientation}) => {
  const { course } = useCourse();
  const [activities, setActivities] = useState();
  const [current, setCurrent] = useState();
  const [currentActivity, setCurrentActivity] = useState();
  const [currentAnswer, setCurrentAnswer] = useState();
  const [complete, setComplete] = useState();
  const [goalOrientation, setGoalOrientation] = useState();
  const [suggestedGoalOrientation, setSuggestedGoalOrientation] = useState();

  // Calls API to get the onboarding activity/sub activities
  useEffect(() => {
    async function fetchData() {
      var res = await courseApi.getCourseOnboardingActivity(course.courseid);
      setActivities(res.data.data);
      const index = res.data.data.findIndex(
        (e) => e.activityid == res.data.index
      );
      setCurrent(index == -1 ? 0 : index);
    }
    fetchData();
  }, []);

  // Send answer and get new activity
  useEffect(() => {
    async function fetchData() {
      if (current != null && current < activities?.length) {
        setCurrentActivity(
          (
            await generalApi.getActivityDetails(
              activities[current].activityid,
              null
            )
          ).data.mainActivity
        );
      }
    }
    fetchData();
  }, [current]);

  // Set stored answer as current answer
  // Set the middle value as the default answer if it's a scale question
  useEffect(() => {
    const question = currentActivity?.datajson?.question;
    if(currentActivity?.answer != null) {
      setCurrentAnswer(currentActivity?.answer);
    } else if (question?.type == "scale") {
      setCurrentAnswer(Math.round((question.scale_max - question.scale_min) / 2));
    }
  }, [currentActivity]);

  const next = async () => {
    setCurrent(current + 1);
    saveEvent(currentActivity?.activityid, course.courseid, xapiverbs.LOAD);
    if (currentActivity != null && currentAnswer != null) {
      const res = await answerOnboardingQuestion(
        currentActivity.activityid,
        currentAnswer
      );

      setComplete(res.data.complete);
      if(res.data.goalOrientation != null) {
        processSuggestedData(res.data.goalOrientation)
      }
    }
  };
  const back = async () => {
    setCurrent(current - 1);
    saveEvent(currentActivity?.activityid, course.courseid, xapiverbs.LOAD);
    if (currentActivity != null && currentAnswer != null) {
      // const res = await answerOnboardingQuestion(
      //   currentActivity.activityid,
      //   currentAnswer
      // );
      // setComplete(res.data.complete);
      // if(res.data.goalOrientation != null) {
      //   processSuggestedData(res.data.goalOrientation)
      // }
    }
  };  

  const processSuggestedData = (data) => {
    const negative = data.negative;
    const positive = data.positive;
    const mastery = data.mastery;
    const performance = data.performance;

    setSuggestedGoalOrientation(`${(mastery > performance ? 'mastery' : 'performance')}-${negative > positive ? 'avoidance' : 'approach'}`);
  }

  if (currentActivity == null) return <Loading />;

  if (complete && goalOrientation == null && askGoalOrientation)
    return (
      <ChooseGoalOrientationView setGoalOrientation={setGoalOrientation} suggestedGoalOrientation={suggestedGoalOrientation} />
    );

  if (goalOrientation != null && askGoalOrientation)
    return <OnboardingCompleteView onComplete={() => onComplete(goalOrientation)} />;

  if (complete && !askGoalOrientation){
    return <OnboardingCompleteView onComplete={() => onComplete(true)} />;
  }

  return (
    <div className="z-[51] fixed top-0 left-0 flex flex-col h-screen w-screen bg-background">
      <NavBar />
      {currentActivity.activitytype == "onboarding_slide" && (
        <OnboardingSlide key={current} slide={currentActivity} />
      )}
      {currentActivity.activitytype == "onboarding_question" && (
        <OnboardingQuestion
          key={current}
          question={currentActivity}
          onChange={setCurrentAnswer}
        />
      )}
      <div className="fixed bottom-4 left-1/2 -translate-x-1/2 flex flex-row">
      <button
          onClick={back}
          className="p-4 w-fit bg-accent min-w-[100px] text-center shadow-default rounded-lg cursor-pointer hover:scale-110 duration-200 self-end"
        >
          Back
        </button>      
        <div className="w-[100px]" />
        <button
          onClick={next}
          className="p-4 w-fit bg-accent min-w-[100px] text-center shadow-default rounded-lg cursor-pointer hover:scale-110 duration-200 self-end"
        >
          Next
        </button>
  
      </div>
    </div>
  );
};

export default OnboardingView;
