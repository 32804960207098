import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { colors } from "./../../../config/colors";

const QuizSelectOption = ({
  score,
  index,
  question,
  handleOptionClick,
  isActive,
}) => {
  const questionData = question ? question?.datajson : null;
  // const questionData = question ? JSON.parse(question?.datajson) : null;

  const user_selections = score?.childActivities[index]?.user_selections;
  
  const isCorrect = user_selections?.some(
    (selectionIndex) => questionData?.answerScores[selectionIndex] == 1
  );

  const bgColor = isCorrect ? colors.completed : colors.error;

  return (
    <motion.div
      key={`option-${index}`}
      animate={{
        borderColor: isActive ? "#000" : bgColor,
        backgroundColor: bgColor,
      }}
      transition={{ duration: 0.1, type: "tween" }}
      className="w-[30px] font-MS text-txt_secondary text-center hover:scale-110 duration-200 ease-in-out py-1 shadow-default rounded border-2 border-card_dark cursor-pointer bg-card_dark"
      onClick={handleOptionClick}
    >
      {index + 1}
    </motion.div>
  );
};

export default QuizSelectOption;
