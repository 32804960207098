import { motion } from "framer-motion";
import { useState } from "react";
import { useCourse } from "../../../contexts/CourseProvider";
import useOutsideClick from "./../../../hooks/useOutsideClick";
import useWindowSize from "./../../../hooks/useWindowSize";
import ConceptHeader from "./ConceptHeader";
import MaterialList from "./MaterialList";
import * as acttypes from "../../../config/activitytypes.js";
import { generalConceptFeedback } from "../../../utils/feedback";
import { scoringMethod } from "../../../config/experimentgroups.js";

const contentVars = {
  visible: {
    opacity: 1,
    transition: {
      duration: 0.2,
      when: "beforeChildren",
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.2,
      when: "afterChildren",
    },
  },
};

const ConceptCardExpanded = ({ concept, cardRef, conceptBorder, handleClick, activityProgress, bgColor }) => {
  const { width, height } = useWindowSize();
  const { ref } = useOutsideClick(handleClick);
  const [hovering, setHovering] = useState(false);
  const { activities, getMisconceptionsWithActivities, conceptScores, course } = useCourse();

  const childMisconceptions = getMisconceptionsWithActivities(concept.id);

  // Check on what horizontal side the concept card should render
  const horizontalDisplay = cardRef?.getBoundingClientRect()?.left > width / 2 ? { right: 0 } : { left: 0 };

  // Check on what vertical side the concept card should render
  const verticalDisplay = cardRef?.getBoundingClientRect()?.top > height / 2 ? { bottom: 0 } : { top: 0 };

  // Find the conceptActivities which is part of the concept
  // let conceptActivities = concept.activity_ids.map((id) => activities?.find((activity) => activity.activityid === id));
  
  let conceptActivities = concept.activity_ids.map((id) =>
    activities?.find((activity) => activity.activityid === id && acttypes.isLearning(activity.activitytype))
  );
  let conceptExercises = concept.activity_ids.map((id) =>
    activities?.find((activity) => activity.activityid === id && acttypes.isExercise(activity.activitytype))
  );
  conceptExercises = conceptExercises.filter(function (element) {
    return element !== "" && element?.valueOf() !== undefined;
  });

  const feedbackText = generalConceptFeedback(concept,conceptScores[concept.id], scoringMethod(Number(course.experimentgroup)));

  return (
    <>
      <motion.div
        className={`${bgColor} max-w-sm overflow-hidden rounded text-left cursor-default flex flex-col gap-2 z-40 shadow-large absolute`}
        initial="hidden"
        animate="visible"
        exit="hidden"
        style={{ ...horizontalDisplay, ...verticalDisplay, ...conceptBorder }}
        variants={contentVars}
        onHoverStart={() => setHovering(true)}
        onHoverEnd={() => setHovering(false)}
        ref={ref}
      >
        {/* Concept Card details */}
        <ConceptHeader isActive={true} concept={concept} hovering={hovering} activityProgress={activityProgress} />

        <div className="flex flex-col gap-4">
          {/* Content description */}
          <motion.div variants={contentVars} className="px-2 text-sm min-w-[400px]">
            {concept.desc}
          </motion.div>

          {/* Content conceptActivities */}
          {concept.type == "concept" && (
            <div>
              {conceptExercises?.length > 0 && (
                <div>
                  <div className="font-MS text-txt_secondary text-xs font-bold w-full px-2 text-center py-1 mb-1 bg-info">
                    EXERCISES
                  </div>
                </div>
              )}
              {conceptExercises?.length > 0 && (
                <MaterialList materialList={conceptExercises} />
              )}

              {/****************** Feedback Text ***************/}

              <div className="text-sm p-2 leading-4 mt-[-2]"  dangerouslySetInnerHTML={{ __html: feedbackText, }}></div>
              
              
              <div className="font-MS text-txt_secondary text-xs font-bold w-full px-2 text-center py-1 mb-1 bg-info">
                LEARNING MATERIAL
              </div>
              <MaterialList materialList={conceptActivities} />
            </div>
          )}
          {/* Concent childMisconceptions */}
          {childMisconceptions?.length > 0 && (
            <div>
              <div className="font-MS w-full text-center bg-error py-1 mb-2 font-bold text-txt_secondary">MISCONCEPTIONS</div>

              <div className="flex flex-col px-2">
                {childMisconceptions.map((mis) => (
                  <div className="flex items-center" key={mis.id}>
                    <div
                      className="truncate col-span-1 w-1/3"
                      style={{
                        opacity: conceptScores[mis.id]?.userscore <= 0 ? 0.5 : 1,
                      }}
                      data-tip={mis.name}
                    >
                      {mis.name}
                    </div>
                    {mis.activities.length > 0 && (
                      <div
                        className="col-span-2 text-txt_primary_light"
                        style={{
                          opacity: conceptScores[mis.id]?.userscore <= 0 ? 0.5 : 1,
                        }}
                      >
                        <MaterialList materialList={mis.activities} />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </motion.div>
    </>
  );
};

export default ConceptCardExpanded;
