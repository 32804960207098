import DOMPurify from "dompurify";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import generalApi from "../../../api/general";

function ReadAndAnswerMCQ({ activityid, questiondata, showAnswer, onSubmit, onLoadExisting }) {
  const sanitizedQuestion = DOMPurify.sanitize(questiondata.text);
  const params = useParams();
  const [locked, setLocked] = useState(false);
  const [selected, setSelected] = useState(-1);
  const [answerAlreadyCorrect, setAnswerAlreadyCorrect] = useState(false);
  const correctIndexes = questiondata.answerScores
    .map((value, index) => (value > 0 ? index : -1))
    .filter((index) => index !== -1);

  const updateAnswers = () => {
    const activityAnswers = generalApi.getActivityAnswer(activityid, params.courseid).then((activityAnswers) => {
      const prev_selected = activityAnswers?.data?.results?.answer?.userAnswerOptionIndexes;
      const prev_success = activityAnswers?.data?.results?.success;

      if (prev_selected) {
        setSelected(prev_selected[0]);
        onLoadExisting(activityid);
      }
      if (prev_success) {
        setAnswerAlreadyCorrect(true);
        setLocked(true);
      }
    });
  };

  useEffect(() => {
    updateAnswers();
    answerAlreadyCorrect && setLocked(true);
  }, []); 

  const unlock = () => {
    setLocked(false);
  };

  const handleOptionSelect = (index) => {
    !showAnswer && !locked && setSelected(index);
  };

  /**
   * Submit function should submit the answer to the backend for grading, and load the status from the backend
   * If the answer was correct, we can set the lock
   * If the answer was incorrect, we keep the answer saved and allow the user to try again
   */
  const submit = () => {
    !locked && !showAnswer && onSubmit(activityid, selected);
    setLocked(true);
    onLoadExisting(activityid);
  };

  return (
    <div className="text-left mx-10">
      <p className="text-gray-500 text-sm mb-0 mt-10 ">Question</p>
      <hr />
      
      <p className="mb-4 text-lg font-medium" dangerouslySetInnerHTML={{ __html: sanitizedQuestion }}></p>
      <div className="divide-y divide-slate-200">
        {questiondata.options.map((option, index) => {
          const isCorrect = correctIndexes.includes(index);
          const isSelected = selected === index;

          const optionClassName = `px-4 py-1 my-1 cursor-pointer ${
            isSelected && (showAnswer || locked)
              ? isCorrect
                ? "bg-green-400"
                : "bg-red-400"
              : isSelected && !(showAnswer || locked)
              ? "bg-blue-200"
              : ""
          } hover:shadow-md`;

          return (
            <div
              key={index}
              className={optionClassName}
              onClick={() => {
                if (!showAnswer && !locked) {
                  handleOptionSelect(index);
                }
              }}
            >
              <span>{option}</span>
              {showAnswer && isCorrect && <span className="text-green-600 ml-2">(Correct)</span>}
              {showAnswer && !isCorrect && isSelected && <span className="text-red-600 ml-2">(Incorrect)</span>}
            </div>
          );
        })}
      </div>
      {!showAnswer && !locked && (
        <div
          className="btn cursor-pointer bg-accent hover:bg-amber-400 duration-500 text-white font-bold px-4 rounded w-20 text-center"
          onClick={() => !locked && submit()}
        >
          Submit
        </div>
      )}
    </div>
  );
}

export default ReadAndAnswerMCQ;
