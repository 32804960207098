import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  setState,
} from "react";
import "./LBstyle.css";
import _ from "lodash";
import { useCourse } from "../../../contexts/CourseProvider";
import ReactTooltip from "react-tooltip";
import { saveEvent } from "../../../api/userevents";
import xapiverbs from "../../../config/xapiverbs";
import { useParams, useNavigate, Navigate } from "react-router";
import { getCurrUnixDate } from "../../../utils/date";

const FilterButtons = ({ updateCC, concept, updateActive, activecc }) => {

  const params = useParams();

  function updateStates() {
    updateCC(concept.id)
    updateActive(concept.id)
    saveEvent(concept.id, params.courseid, xapiverbs.INTERACTED, {
      action: "filter",
      timestamp: getCurrUnixDate(),
    });
  }

  const bgColor = activecc == concept.id ? '#227c9d' : '#e8e9ea' 
  const shadowColor = bgColor == '#e8e9ea' ? '' : '#227c9d 0 3px 7px inset'
  const textColor = activecc == concept.id ? 'white' : 'black'

  return (
    <div style={{ display: "inline" }} data-for="tool" data-tip>
      <button
        className="button-30"
        onClick={() => updateStates()}
        title={concept.name}
        id="topicbutton"
        style={{
          backgroundColor: bgColor,
          boxShadow: shadowColor,
          color: textColor
        }}
      >
        {concept.name}
      </button>
    </div>
  );
};

export default FilterButtons;
