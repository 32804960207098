import TeacherPortalQuestionStatisticsOption from "./QuestionStatisticsOption";

const TeacherPortalQuestionStatisticsOptions = ({ selectedQuestion }) => {

    return (<>
        {selectedQuestion.datajson.options.map((option, index) => {
            return <>
                <TeacherPortalQuestionStatisticsOption
                    option={option}
                    amount_chosen={selectedQuestion.statistics.answer_distribution.answer_distribution[index]}
                    amount_total={selectedQuestion.statistics.answer_distribution.total_answers}
                    point_worth={selectedQuestion.datajson.answerScores[index]} />
            </>
        })
        }
    </>)

};

export default TeacherPortalQuestionStatisticsOptions