const StartOfQuiz = ({ handleBeginQuiz }) => {
  return (
    <>
      <div>
        Hi. The quiz will begin once you click the button below. Each question has one correct answer. When you select an answer and click the NEXT button, the answer is saved. Once you answer all the questions, your can't go back. The answers are
        submitted only when you click the Submit button at the end of the quiz.
      </div>
      <div
        onClick={handleBeginQuiz}
        className="p-4 w-fit bg-accent shadow-default rounded-lg cursor-pointer hover:scale-110 duration-200"
      >
        BEGIN QUIZ
      </div>
    </>
  );
};

export default StartOfQuiz;
