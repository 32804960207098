import { useReducer, useState } from "react";
import { useActivity } from "../../../contexts/ActivityProvider";
import { useCourse } from "../../../contexts/CourseProvider";
import { getCurrUnixDate } from "../../../utils/date";
import Exercise from "../exercise/Exercise";
import { saveEvent } from "../../../api/userevents";
import xapiverbs from "../../../config/xapiverbs";

const ExerciseContent = () => {
  const { course, updateConceptScores } = useCourse();
  const { activity, childActivities, markActivityAsCompleted } = useActivity();
  
  const activitydata = activity.datajson;
  
  const data= {
    title: activitydata.title,
    introText: activitydata.introText,
    bridge: activitydata.bridge,
    questions: childActivities,
    showAnswers: activitydata.showAnswers
  };

  return (
    <div className="p-4 grid grid-cols-2 divide-x">
      <Exercise title={data.title} introText={data.introText} bridge={data.bridge} questions={data.questions} showAnswer={data.showAnswers} />
    </div>
  );
};

export default ExerciseContent;
