import { useEffect, useState } from "react";
import { useActivity } from "../../../contexts/ActivityProvider";
import FallBackLink from "./FallBackLink";
import Loading from "./../../general/Loading";
import ErrorScreen from "./../../general/ErrorScreen";
import { getItem } from "../../../utils/persistanceStorage";
import { MdOutlineOpenInNew } from "react-icons/md";
import OpenTabScreen from "../../general/OpenTabScreen";

const PawsTracing = () => {
  const { activityData } = useActivity();
  const [loadingIframe, setLoadingIframe] = useState(true);

  const username = getItem("user-data")?.username;
  const pawsurl = activityData.url + "&usr=" + username + "&sid=" + getItem("user-data").sessionid;

  const openLinkInNewTab = () => {
    const newWindow = window.open(pawsurl, "_blank", "width=600,height=400");
    if (newWindow) {
      newWindow.focus();
    }
  };

  return (
    <div className="p-4">
      <div className="relative h-full w-full pt-[10%] overflow-hidden my-4">
        <div className="w-5/6 p-6 flex flex-col items-center rounded-lg cursor-pointer " onClick={openLinkInNewTab}>
          <div className="h-5/6 transition-all duration-300 transform hover:scale-105">
            <OpenTabScreen />
          </div>
          <div className="h-1/6 w-3/5 flex justify-between items-center text-lg">
            Click here to open the problem in a new tab. Keep this window open while you're working on the problem.
          </div>
        </div>
      </div>
      <FallBackLink url={pawsurl} />

      {/* <a href={pawsurl} target="_blank">Click Here</a> to open activity in a new tab. Keep this window open. 
      
      <div className="relative w-full pt-[40%] overflow-hidden my-4">
        {loadingIframe && <Loading />}
        <iframe
          title="web content"
          src={pawsurl}
          className="w-full h-full absolute top-0 bottom-0 right-0 left-0"
          onLoad={() => setLoadingIframe(false)}
        />
      </div>
      */}
    </div>
  );
};

export default PawsTracing;
