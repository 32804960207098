import { TableRow, TableCell, Tooltip, Grid, Typography } from "@material-ui/core";
import { activityTypeToName } from "../../../../utils/teacher_portal/activityTypeToString";
import { limitString } from "../../../../utils/string";
import React from "react";
import { colors } from "../../../../config/colors";
import TeacherPortalLearningMaterialModal from "../learning_material/LearningMaterialModal";
import Icon from "../../../general/Icon";

const TeacherPortalLearningMaterialRow = ({ activityWithUsageData }) => {
    const [showModal, setShowModal] = React.useState(false)
    const activityNameShort = limitString(activityWithUsageData.activitytitle, 50)
    const activityIsShortened = activityNameShort !== activityWithUsageData.activitytitle
    return (
        <>
            {showModal
                && <TeacherPortalLearningMaterialModal
                    activityWithUsageData={activityWithUsageData}
                    onClose={() => setShowModal(false)} />
            }
            <TableRow
                key={activityWithUsageData.activityid}
                onClick={() => setShowModal(true)}
                hover
                style={{
                    cursor: "pointer",
                }}

            >
                <TableCell>
                    <Tooltip title={activityTypeToName(activityWithUsageData.activitytype)}>
                        <Grid container>
                            <Grid item>
                                <Icon type={activityWithUsageData.activitytype} color={"black"} />
                            </Grid>
                            <Grid item>
                                <Typography variant="body2" style={{ marginTop: 0, paddingTop: "6px", paddingLeft: "2px" }} >
                                    {activityTypeToName(activityWithUsageData.activitytype)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Tooltip>

                </TableCell>
                <TableCell component="th" scope="row">

                    {activityIsShortened ? <Tooltip title={activityWithUsageData.activitytitle}>
                        <div>
                            {activityNameShort}
                        </div>
                    </Tooltip> :
                        activityWithUsageData.activitytitle}


                </TableCell>
                <TableCell>
                    {activityWithUsageData.first_accessed ? activityWithUsageData.first_accessed.toLocaleString().split('00:')[0] : "Never"}
                </TableCell>
                <TableCell>
                    {activityWithUsageData.last_accessed ? activityWithUsageData.last_accessed.toLocaleString().split('00:')[0] : "Never"}
                </TableCell>
                <TableCell>
                    {activityWithUsageData.total_usage_data.count_events}
                </TableCell>
                <TableCell>
                    {activityWithUsageData.total_usage_data.count_users}
                </TableCell>
                <TableCell>
                    {activityWithUsageData.total_usage_data.count_users_last_30_days}</TableCell>

            </TableRow>
        </>
    )

}
export default TeacherPortalLearningMaterialRow;
