import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  setState,
} from "react";
import "./LBstyle.css";
import _ from "lodash";
import { useCourse } from "../../../contexts/CourseProvider";
import FilterButtons from "./FilterButtons.jsx";
import ReactTooltip from "react-tooltip";
import {RxUpdate} from "react-icons/rx";
import { saveEvent } from "../../../api/userevents";
import xapiverbs from "../../../config/xapiverbs";
import { useParams, useNavigate, Navigate } from "react-router";
import { getCurrUnixDate } from "../../../utils/date";

import Icon from "../../general/Icon";

const FilterButton = ({ updateCC, lg }) => {
  const { concepts, activities, lgs } = useCourse();
  const params = useParams();

  let currentActiveQuizID = 24000;
  let activequizzesIDs = [24000];

  for(let i = 0; i < lgs.length; i++)
  {
    if(lgs[i].lgactive == 1)
    {
      currentActiveQuizID = lgs[i].mainactivityid
      if(!activequizzesIDs.includes(lgs[i].mainactivityid))
      {
        activequizzesIDs.push(lgs[i].mainactivityid)
      }
    }
  }

  
  const [activeID, setActiveID] = useState(currentActiveQuizID);
  
  const newList = [
    { name: "Total", id: currentActiveQuizID, type: "concept" },
    ...concepts,
  ];

  function updateActive(ccid){
    setActiveID(ccid)
  }

  function handleClick() {
    saveEvent(activeID, params.courseid, xapiverbs.INTERACTED, {
      action: "refreshed",
      timestamp: getCurrUnixDate(),
    });
    window.location.reload()
  }

  return (
    <div>
      <div id="nav">
        <div id="filterlist">
          {newList?.length > 0 &&
            newList.map(
              (con) =>
                con.type == "concept" && (
                  <FilterButtons updateCC={updateCC} concept={con} updateActive={updateActive} activecc={activeID} key={con.id} />
                )
            )}

          <ReactTooltip id="tool" effect="solid" delayShow={400} data-tip={"2"}>
            <span>{"Filter based on this concept"}</span>
          </ReactTooltip>
          <button onClick={() => handleClick()} style={{padding: "10px", borderRadius: "50%", backgroundColor: '#227c9d', color: "white"}} ><RxUpdate /></button>
        </div>
      </div>
    </div>
  );
};

export default FilterButton;

/*
        <ul id="filterlist">
          <li><button class="button-30" role="button" onClick={() => updateCC(1)} title="Total" id="topicbutton">Total</button></li>
          <li><button class="button-30" role="button" onClick={() => updateCC(2)} title="Fitness" id="topicbutton">Fitness</button></li>
          <li><button class="button-30" role="button" onClick={() => updateCC(3)} title="Natuurlijke selectie" id="topicbutton">Natuurlijke selectie</button></li>
          <li><button class="button-30" role="button" onClick={() => updateCC(4)} title="Speciatie" id="topicbutton">Speciatie</button></li>
          <li><button class="button-30" role="button" onClick={() => updateCC(5)} title="Variatie" id="topicbutton">Variatie</button></li>
          <li><button class="button-30" role="button" onClick={() => updateCC(6)} title="Genetische drift" id="topicbutton">Genetische drift</button></li>
          <li><button class="button-30" role="button" onClick={() => updateCC(7)} title="Fylogenie" id="topicbutton">Fylogenie</button></li>
        </ul>*/
