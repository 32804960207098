import { useState, useEffect } from "react";
import { useCourse } from "../contexts/CourseProvider";
import { AnimatePresence, motion } from "framer-motion";
import CourseHeader from "../components/knowledgemap/CourseHeader";
import ChooseGoalOrientationView from "./ChooseGoalOrientationView";
import LearningGoal from "../components/knowledgemap/learninggoal/LearningGoal";
import { goalOrientations } from "../config/goalorientations";
import { isGoalOrientation } from "../config/experimentgroups";
import WelcomeScreen from "../components/general/WelcomeScreen";
import generalApi from "../api/general";

const KMView = () => {
  const { goalOrientation, setGoalOrientation, lgs, activeConcept, experimentGroup } = useCourse();
  const [changeGoalOrientation, setChangeGoalOrientation] = useState(false);
  const [showMessages, setShowMessages] = useState(false);
  const [welcomeActivities, setWelcomeActivities] = useState(false);

  const [messages, setMessages] = useState([]);
  
  useEffect(() => {
    const userMessages = generalApi.getMessage("notification", true).then((item) => {
      if (item?.data?.messages?.length >= 1) {
        setMessages(item.data.messages);
        setShowMessages(true);
      }
    });
    return () => {};
  }, []);

  useEffect(() => {
    // The following part checks if any activity should be loaded after welcome screen and stores them in welcome_activities
    const welcome_activities = [];

    for (const item of messages) {
      const extrajson = item.extrajson;
      if (extrajson) {
        try {
          const json_data = JSON.parse(extrajson);
          const activity = json_data.activity;
          if (activity) {
            welcome_activities.push(activity);
          }
        } catch (error) {
          console.error("Error parsing JSON from welcome messages:", error);
        }
      }
    }
    setWelcomeActivities(welcome_activities);

    return () => {};
  }, [messages]);

  const welcomeClose = () => {

    if (welcomeActivities.length > 0) {
      for (const act of welcomeActivities) {
        if (act == "ChooseGoalOrientationView") {
          setChangeGoalOrientation(true);
        }
      }
    }
    setShowMessages(false);
  };

  const handleChange = (value) => {
    setGoalOrientation(value);
    setChangeGoalOrientation(false);
  };

  const handleClick = () => {
    setChangeGoalOrientation(true);
  };

  if (changeGoalOrientation) {
    return <ChooseGoalOrientationView setGoalOrientation={handleChange} />;
  }

  return (
    <div className="w-full">
      <div className="flex flex-row">
        <CourseHeader />
        {isGoalOrientation(goalOrientation) && (
          <div className="ml-auto relative flex items-end flex-col my-auto max-w-[50%]">
            <div className="text-right">
              Goal:{" "}
              <b>{goalOrientations[goalOrientation].charAt(0).toLowerCase() + goalOrientations[goalOrientation].slice(1)}</b>.
            </div>
            <button
              className="no-underline hover:underline h-full rounded place-self-end cursor-pointer text-xs"
              onClick={handleClick}
            >
              change?
            </button>
          </div>
        )}
      </div>

      {showMessages && <WelcomeScreen messages={messages} onClose={welcomeClose} />}

      {lgs.length < 1 && <div>No content to show yet.</div>}
      {/* Create the rows of learning goals */}
      {lgs.map((lg) => (
        <LearningGoal key={`learning_goal-${lg.lgid}`} lg={lg} />
      ))}
      {/* Slight darkening of the background once there is an activeconcept */}
      <AnimatePresence exitBeforeEnter>
        {activeConcept && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="z-10 fixed top-0 left-0 w-screen h-screen bg-background_fade"
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default KMView;
