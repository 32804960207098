import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

const useWindowSize = () => {
  const [width, setWidth] = useState(isMobile ? 1280 : 1920);
  const [height, setHeight] = useState(isMobile ? 720 : 1080);

  /**
   * Sets the window height of a user whenever it is resizing
   */
  useEffect(() => {
    setWidth(window?.innerWidth);
    setHeight(window?.innerHeight);
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    });
  }, []);

  return { width, height };
};

export default useWindowSize;
