/**
 * Checks if a date object is during the current week of the user.
 * @param {obj} date date object
 * @returns boolean
 */
export const dateInCurrentWeek = (date) => {
  const todayObj = new Date();
  const todayDate = todayObj.getDate();
  const todayDay = todayObj.getDay();

  // get first date of week
  const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));

  // get last date of week
  const lastDayOfWeek = new Date(firstDayOfWeek);
  lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

  // if date is equal or within the first and last dates of the week
  return date >= firstDayOfWeek && date <= lastDayOfWeek;
};

/**
 * Checks what display state a component is based on the dates passed in
 * @param {obj} date date object
 * @returns boolean
 */
export const getDisplayState = (lg) => {
  const lgdateactive = new Date(lg.lgdateactive * 1000);
  const lgdateclose = new Date(lg.lgdateclose * 1000);
  const currdate = new Date();

  if (lg.lgactive == -1) {
    return "future";
  }
  if (lg.lgactive == 1) {
    return "active";
  }

  // If the currentdate lies between the active and close dates, display as active
  if (lgdateactive <= currdate && currdate <= lgdateclose) {
    return "active";
  }

  // If the active date lies past the current date, display as future
  if (currdate < lgdateactive) {
    return "future";
  }

  // display as past
  return "past";
};

/**
 * Retrieves the month abbreviations of a date object.
 * @param {date} date A date object
 * @returns string
 */
export const getMonthOfDate = (date) => {
  return month[date.getMonth()];
};

// All month abbreviations (how they will show up in the learning goal)
const month = [
  "Jan.",
  "Feb.",
  "Mar.",
  "Apr.",
  "May",
  "June",
  "July",
  "Aug.",
  "Sept.",
  "Oct.",
  "Nov.",
  "Dec.",
];

export const currentDate = () => {
  return new Date();
};

export const makeDate = (datestr) => {
  return new Date(Date.parse(datestr));
};

export const getCurrUnixDate = () => {
  return new Date().valueOf();
};

/**
 * THESE FUNCTIONS ARE FOR TESTING PURPOSES ONLY
 * this makes it easy to see all the learning goal variations
 */
export const datePreviousWeek = () => {
  var today = new Date();
  var prevweek = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 7
  );
  return prevweek;
};

export const dateNextWeek = () => {
  var today = new Date();
  var nextweek = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 7
  );
  return nextweek;
};
