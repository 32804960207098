// Score is a number
export const scoreOutOf10 = (score, maxscore) => {
  let prog = score / maxscore;
  if (prog === Infinity || isNaN(prog)) return 0;
  if (prog>1){
    prog=1;
  }
  return Math.round(prog * 10) || 0;
};

export const progressOutOf10 = (score, maxscore) => {
  if (score / maxscore === Infinity || isNaN(score / maxscore)) return 0;

  return score / maxscore;
};
