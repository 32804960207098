import { useEffect, useState } from "react";
import SlAvailableCourses from "./../components/home/SlAvailableCourses";
import { useUser } from "../contexts/UserContext";

const HomeView = () => {
  const { courses } = useUser();

  const coursesAsTeacher = courses.filter((course) => course.role === "teacher")
  return (
    <div className="m-6">
      <div className="text-3xl mb-4 font-MS">Subscribed Courses</div>
      <SlAvailableCourses courses={courses}/>

      { coursesAsTeacher.length > 0 &&
      <>
      <div className="text-3xl my-4 font-MS">Subscribed Courses as Teacher</div>
   
      <SlAvailableCourses courses={coursesAsTeacher} linkPostfix={"/teacher_portal"} />
      </>
}
    </div>
  );
};

export default HomeView;
