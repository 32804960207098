import axios from "./axios";

const register = ({ username, password, email }) => {
  return axios.post(
    "/register",
    {
      username,
      password,
      email,
    },
    {
      headers: {
        headers: {
          "Content-Type": "application/json",
        },
      },
    }
  );
};

const login = ({ email, password, isMobile }) => {
  return axios.post(
    "/login",
    {
      email,
      password,
      isMobile,
    },
    {
      headers: {
        headers: {
          "Content-Type": "application/json",
        },
      },
    }
  );
};

export default {
  register,
  login,
};
