import { useEffect, useState } from "react";
import { saveEvent } from "../../api/userevents";
import xapiverbs from "../../config/xapiverbs";
import { useCourse } from "../../contexts/CourseProvider";

const HoveringBox = ({ header, text, concept }) => {
  const { course, conceptScores } = useCourse();
  const [hoveringDate, setHoveringDate] = useState(null);

  useEffect ( () => {
    setHoveringDate (Date.now());
    return () => {
      
      if (hoveringDate && (Date.now() - hoveringDate) > 500){
        saveEvent(-1, course.courseid, xapiverbs.INTERACTED, {
          goalicon: true,
          concept: concept.id,
          concept_score: conceptScores[concept.id]
        });
      }
    };
  }, [hoveringDate]);

  return (
    <div className="fixed z-50 w-72 h-40 w-min-72">
      <div className="absolute bg-white shadow-sm rounded-md overflow-hidden">
        <div className="text-sm bg-accent text-white m-0 p-1">{header}</div>
        <div className="text-xs p-1">{text}</div>
      </div>
    </div>
  );
};

export default HoveringBox;
