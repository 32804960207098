import { useEffect, useState } from "react";

const Ping = ({ num }) => {
  if (num <= 0) return null;

  return (
    <span className="font-MS absolute top-0 right-0 w-[20px] h-[20px] p-2 flex items-center justify-center translate-x-1/2 -translate-y-1/2 bg-error rounded-full text-xs text-txt_secondary">
      {num}
    </span>
  );
};

export default Ping;
