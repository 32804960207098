import { useEffect, useState } from "react";
import { useActivity } from "../../../contexts/ActivityProvider";
import FallBackLink from "./FallBackLink";
import Loading from "./../../general/Loading";

const InteractiveContent = () => {
  const { activityData } = useActivity();
  const [loadingIframe, setLoadingIframe] = useState(true);

  return (
    <div className="p-4">
      <div className="mb-2">{activityData.description}</div>
      <div className="relative w-full pt-[40%] overflow-hidden my-4">
        {loadingIframe && <Loading />}
        <iframe
          title="web content"
          src={activityData.url}
          className="w-full h-full absolute top-0 bottom-0 right-0 left-0"
          onLoad={() => setLoadingIframe(false)}
        />
      </div>
      <FallBackLink url={activityData.url} />
    </div>
  );
};

export default InteractiveContent;
