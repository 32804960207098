import { Tooltip } from "@material-ui/core";
import TeacherPortalVerticalProgressBar from "./VerticalProgressBar";

const TeacherPortalVerticalProgressBarWithTooltip = (props, ref) => {

    return <>
        <Tooltip title={props.tooltipString}>
            <TeacherPortalVerticalProgressBar floatProgress={props.floatProgress} />
        </Tooltip>
    </>
};

export default TeacherPortalVerticalProgressBarWithTooltip