import { createContext, useContext, useState, useEffect } from "react";
import { useParams } from "react-router";
import courseApi from "../api/course";
import userApi from "../api/user";
import { EG_CONFIG, ExperimentGroup } from "../config/experimentgroups";
import { useNavigate } from "react-router";
import { useUser } from "./UserContext";

const CourseContext = createContext();

export function CourseProvider({ children }) {
  const { getCourseFromId } = useUser();
  const navigate = useNavigate();
  const params = useParams();

  const [loadingInfo, setLoadingInfo] = useState(true);
  const [loadingError, setLoadingError] = useState("");

  // Course state
  const [course, setCourse] = useState(null);
  const [activities, setActivities] = useState(null);
  const [experimentGroup, setExperimentGroup] = useState(ExperimentGroup.noSC);
  const [goalOrientation, setGoalOrientation] = useState(null);
  const [showDashboard, setShowDashboard] = useState(false);
  const [onboardingCompletion, setOnboardingCompletion] = useState(null);
  const [onboardingWasComplete, setOnboardingWasComplete] = useState(null);

  // Knowledge map states
  const [lgs, setlgs] = useState(null);
  const [concepts, setConcepts] = useState(null);
  const [conceptRelations, setConceptRelations] = useState(null);
  const [conceptScores, setConceptScores] = useState(null);
  const [activeConcept, setActiveConcept] = useState(null);
  const [activeLearningGoal, setActiveLearningGoal] = useState(null);

  useEffect(() => {
    getCourseInformation();
    getCourseDetails();
  }, []);

  useEffect(() => {
    if (goalOrientation != null) {
      setExperimentGroup(ExperimentGroup.goalOrientation[goalOrientation]);
    }
  }, [goalOrientation]);

  useEffect(() => {
    if (onboardingCompletion && !onboardingWasComplete) {
      userApi.setOnboardingCompleted(params.courseid);
    }
  }, [onboardingCompletion]);

  /**
   * Gets all the important course information to show the learning goals,
   * constructs, relations and scores.
   */
  const getCourseInformation = () => {
    // Get the course information
    const courseInfo = getCourseFromId(params.courseid);
    if (courseInfo) {
      setCourse(courseInfo);
      // Try to set experiment group for the course
      try {
        setExperimentGroup(EG_CONFIG[Number(courseInfo.experimentgroup)]);
      } catch {
        setExperimentGroup(ExperimentGroup.SCbyDefault);
        console.log("Experiment Group Reverting to Default");
      }

      try {
        setShowDashboard(EG_CONFIG[Number(courseInfo.experimentgroup)].dashboard);
      } catch {
        console.log("Problem getting dashboard");
      }
    } else {
      console.log("Experiment Group Not Found ", Number(courseInfo.experimentgroup));
      navigate("/");
    }
  };

  const getCourseDetails = async () => {
    try {
      // Get course details
      const course_activities_res = await courseApi.getActivitiesForCourse(params.courseid);

      const course_lgs_res = await courseApi.getCourseLGs(params.courseid);
      const course_concepts_res = await courseApi.getCourseConcepts(params.courseid);
      const course_conceptrelations_res = await courseApi.getCourseConceptRelations(params.courseid);
      const course_conceptscores = await courseApi.getCourseConceptScores(params.courseid);
      const course_onboardingCompletionStatus = await userApi.getOnboardingCompletionStatus(params.courseid);

      const goal_orientation_res = await userApi.getGoalOrientation(params.courseid);

      if (course_onboardingCompletionStatus.status == 200) {
        if (course_onboardingCompletionStatus.data.completed) {
          setOnboardingWasComplete(course_onboardingCompletionStatus.data.completed);
          setOnboardingCompletion(course_onboardingCompletionStatus.data.completed);
        }
      }

      if (
        course_activities_res.status == 200 &&
        course_lgs_res.status == 200 &&
        course_concepts_res.status == 200 &&
        course_conceptrelations_res.status == 200 &&
        course_conceptscores.status == 200 &&
        goal_orientation_res.status == 200
      ) {
        setActivities(course_activities_res.data.activities);
        setConceptRelations(course_conceptrelations_res.data.results);
        setConceptScores(course_conceptscores.data.results);
        setConcepts(course_concepts_res.data.results);
        setlgs(course_lgs_res.data.results);
        setGoalOrientation(goal_orientation_res.data.goalorientation);
        setLoadingInfo(false);
      } else {
        setLoadingInfo(false);
        setLoadingError("failed to load info");
      }
    } catch (error) {
      console.error("ERROR: ", error);

      localStorage.clear();
      navigate("/login");
    }
  };

  const updateConceptScores = async () => {
    const course_conceptscores = await courseApi.getCourseConceptScores(params.courseid);

    if (course_conceptscores.status == 200) {
      setConceptScores(course_conceptscores.data.results);
    }
  };

  /**
   * Util functions
   */

  // Finds the concept relations for a certain concept
  const getConceptRelation = (id) => {
    return conceptRelations?.find((relation) => relation.id == id);
  };

  //  Check if a concept is a tree or not --  If a concept has children or has no parent and no children it is a tree
  const conceptIsTree = (id) => {
    const hasChildren = getConceptRelation(id)?.children?.length > 0;
    const isChild = conceptRelations.find((relation) => relation.children.includes(id.toString())) || false;

    if (hasChildren && !isChild) return true;
    if (!isChild && !hasChildren) return true;
    return false;
  };

  // Gets all the concept children of a certain concept
  const getConceptChildren = (id) => {
    const childrenIds = conceptRelations.find((relation) => relation.id == id)?.children || [];
    return (childrenIds.map((id) => findConceptFromId(id)) || []).filter((concept) => concept) || [];
  };

  const getMisconceptionsWithActivities = (id) => {
    return getConceptChildren(id)
      .filter((con) => con.type == "misconception")
      .map((mis) => {
        return {
          ...mis,
          activities: mis.activity_ids.map((id) => activities?.find((activity) => activity.activityid == id)),
        };
      });
  };

  const findConceptFromId = (id) => {
    return concepts?.find((concept) => concept.id == id);
  };

  useEffect(() => {
    if (params?.activityid) {
      setActiveConcept(null);
    }
  }, [params]);

  const state = {
    loadingInfo,
    loadingError,
    updateConceptScores,

    // general course state
    setLoadingInfo,
    experimentGroup,
    goalOrientation,
    setGoalOrientation,
    setOnboardingCompletion,
    course,
    activities,
    setActivities,
    onboardingCompletion,
    showDashboard,

    // knowledge map
    lgs,
    concepts,
    conceptRelations,
    conceptScores,
    activeConcept,
    setActiveConcept,
    activeLearningGoal,
    setActiveLearningGoal,

    // util functions
    getConceptChildren,
    conceptIsTree,
    getConceptRelation,
    getMisconceptionsWithActivities,
    findConceptFromId,
  };

  return <CourseContext.Provider value={state}>{children}</CourseContext.Provider>;
}

export function useCourse() {
  return useContext(CourseContext);
}
