import { useEffect, useState } from "react";
import { useParams } from "react-router";
import YouTube from "react-youtube";
import { saveEvent } from "../../../api/userevents";
import xapiverbs from "../../../config/xapiverbs";
import { useActivity } from "../../../contexts/ActivityProvider";
import FallBackLink from "./FallBackLink";

const VideoContent = () => {
  const params = useParams();
  const { activityData, markActivityAsCompleted } = useActivity();

  const youtube_id = youtubeParser(activityData.url);

  const handleYTPlay = (def) => {
    saveEvent(params.activityid, params.courseid, xapiverbs.INTERACTED, {
      action: "play_video",
      time_video: def.target.getCurrentTime(),
    });
  };

  const handleYTPause = (def) => {
    saveEvent(params.activityid, params.courseid, xapiverbs.INTERACTED, {
      action: "pause_video",
      time_video: def.target.getCurrentTime(),
    });
  };

  const handleYTEnd = (def) => {
    saveEvent(params.activityid, params.courseid, xapiverbs.INTERACTED, {
      action: "stop_video",
      time_video: def.target.getCurrentTime(),
    });
  };

  // const handleYTStateChange = (def) => {
  //   // if user has watched 90% of the video, complete the activity
  //   if (def.target.getCurrentTime() / def.target.getDuration() > 0.9) {
  //     markActivityAsCompleted(params.activityid);
  //   }
  // };

  return (
    <div className="w-full p-4">
      <div className="mb-2">{activityData.description}</div>
      {youtube_id && activityData.yt_video && (
        <div className="relative w-full pt-[40%] overflow-hidden my-4">
          <YouTube
            videoId={youtube_id}
            onPlay={handleYTPlay}
            onPause={handleYTPause}
            onEnd={handleYTEnd}
            iframeClassName="w-full h-full absolute top-0 bottom-0 right-0 left-0"
            // onStateChange={handleYTStateChange}
          />
        </div>
      )}

      <FallBackLink url={activityData.url} />
    </div>
  );
};

/**
 * Parses the id from a youtube url
 */
const youtubeParser = (url) => {
  var regExp =
    /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  var match = url.match(regExp);
  if (match && match[2].length == 11) {
    return match[2];
  } else {
    return console.error("Something went wrong parsing youtube url:", url);
  }
};

export default VideoContent;
