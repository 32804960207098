import { useEffect, useState, useRef, componentDidMount, setState } from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import { useCourse } from "../contexts/CourseProvider";
import { AnimatePresence, motion } from "framer-motion";
import CourseHeader from "../components/knowledgemap/CourseHeader";
import LearningGoalLB from "../components/knowledgemap/learninggoal/LearningGoalLB";
import QuizButton from "../components/knowledgemap/learninggoal/QuizButton";
import Leaderboard from "../components/knowledgemap/leaderboard/Leaderboard";
import FilterButton from "../components/knowledgemap/leaderboard/FilterButton";
import { useParams } from "react-router";
import { getItem } from "../utils/persistanceStorage";
import { useUser } from "../contexts/UserContext";

import courseApi from "../api/course";
import { Outlet } from "react-router";
import "../components/knowledgemap/leaderboard/LBstyle.css";

const CourseLeaderboardView = () => {
  const params = useParams();
  const userinfo = useUser();

  const { lgs, activeConcept, conceptScores, concepts } = useCourse();

  let currentActiveQuizID = 24000;
  let activequizzesIDs = [24000];

  for(let i = 0; i < lgs.length; i++)
  {
    if(lgs[i].lgactive == 1)
    {
      currentActiveQuizID = lgs[i].mainactivityid
      if(!activequizzesIDs.includes(lgs[i].mainactivityid))
      {
        activequizzesIDs.push(lgs[i].mainactivityid)
      }
    }
  }


  const [filterid, setFilterID] = useState(currentActiveQuizID);
  const [prevfilterid, setPrevFilterID] = useState(0)
  const [score, setScore] = useState([]);
  const [listlb, setlistLB] = useState([]);
  const [height, setHeight] = useState("178px")
  const ref1 = useRef(null)
  useEffect(() => {
    if(filterid == prevfilterid){
      setPrevFilterID(filterid)
    }
    else{
    if(filterid == currentActiveQuizID)
    {
      courseApi
      .getLeaderboardQuiz(params.courseid, currentActiveQuizID)
      .then((res) => {
          setScore(res.data);
          setlistLB(res.data.results)
        }
      )
      .catch((e) => {
        console.error(e);
      });
    }
    /*courseApi
      .getLeaderboard(params.courseid, currentActiveQuizID)
      .then((res) => {
        setScore(res.data);
        setlistLB(res.data.results)
        }
      )
      .catch((e) => {
        console.error(e);
      });*/
    else{
      courseApi
        .getLeaderboardConstruct(params.courseid, filterid)
        .then((res) => {
          setScore(res.data);
          setlistLB(res.data.results)
          }
        )
        .catch((e) => {
          console.error(e);
        });
    }}
  }, [filterid]);

  useEffect(() => {
    const newheight = parseInt(ref1.current.clientHeight + 100)+"px"
    setHeight(newheight)
  }, [])

  function updateLB(x) {
    if(x == currentActiveQuizID)
    {
      courseApi
      .getLeaderboardQuiz(params.courseid, currentActiveQuizID)
      .then((res) => {
          setScore(res.data);
          setlistLB(res.data.results)
          setFilterID(x)
          setPrevFilterID(x)
        }
      )
      .catch((e) => {
        console.error(e);
      });
    }
    else{
      courseApi
        .getLeaderboardConstruct(params.courseid, x)
        .then((res) => {
          setScore(res.data);
          setlistLB(res.data.results)
          setFilterID(x)
          setPrevFilterID(x)
          }
        )
        .catch((e) => {
          console.error(e);
        });
    }

  }

  return (
    <div className="w-full p-4">
      <CourseHeader />
      <div className="secondcol first" style={{display: "inline"}}>
        {activequizzesIDs.map((quizid, index) => (
          <QuizButton lg={lgs[0]} quizid={quizid} quizindex={(index+ 1)} key={Math.random()}/>
        ))}
      </div>
      <div className="secondcol second" id="filterbuttons" ref={ref1}>
        <FilterButton updateCC={updateLB} lg={lgs[0]} />
      </div>

      <div className=" column left" style={{top: height, textAlign: "center"}}>
        {lgs.length < 1 && <div>No content to show yet.</div>}
        {/* Create the rows of learning goals */}
        {lgs.map((lg) => (
          <LearningGoalLB
            key={`learning_goal-${lg.lgid}`}
            lg={lg}
            filterid={filterid}
          />
        ))}
        {/* Slight darkening of the background once there is an activeconcept */}
        <AnimatePresence exitBeforeEnter>
          {activeConcept && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="z-10 fixed top-0 left-0 w-screen h-screen bg-background_fade"
            />
          )}
        </AnimatePresence>
      </div>
      {listlb.length > 0 && (
        <div className="column middle">
          <Leaderboard
            filterid={filterid}
            key={filterid}
            scores={listlb}
            userid={getItem("user-data")?.userid}
            concepts={concepts}
            allscores={score}
            activity={filterid == currentActiveQuizID}
          />
        </div>
      )}
    </div>
  );
};

export default CourseLeaderboardView;
