import { Outlet } from "react-router";
import Loading from "../components/general/Loading";
import { ExperimentGroup } from "../config/experimentgroups";
import { useCourse } from "../contexts/CourseProvider";
import CourseLeaderboardView from "./CourseLeaderboardView";
import KMView from "./KMView";
import OnboardingView from "./OnboardingView";
import WelcomeView from "./WelcomeView";
import { useEffect, useState } from "react";
import generalApi from "../api/general";

const CourseView = () => {
  const {
    course,
    experimentGroup,
    goalOrientation,
    setGoalOrientation,
    loadingInfo,
    loadingError,
    onboardingCompletion,
    setOnboardingCompletion,
  } = useCourse();

  if (loadingInfo)
    return (
      <div className="p-4">
        <div>If the page is loading too long. Please try refreshing the page or log out and in again.</div>
        <div>
          <Loading />
        </div>
      </div>
    );

  if (loadingError) return <div className="p-4">{loadingError}</div>;

  if (goalOrientation == null && experimentGroup == ExperimentGroup.goalOrientation)
    return (
      <>
        <OnboardingView onComplete={setGoalOrientation} askGoalOrientation={true} />
      </>
    );
  else if (!onboardingCompletion) {
    return (
      <>
        <OnboardingView onComplete={setOnboardingCompletion} askGoalOrientation={false} />
      </>
    );
  }

  if (experimentGroup == ExperimentGroup.leaderboard) {
    return (
      <>
        <div className="relative flex">
          <Outlet />
          <CourseLeaderboardView />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="relative flex">
        <Outlet />
        <KMView />
      </div>
    </>
  );
};

export default CourseView;
