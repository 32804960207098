import { useEffect, useState } from "react";
import { Grid, Typography, Paper, Card, CardHeader, CardContent } from "@material-ui/core";
import TeacherPortalLearningMaterialUsageTimelineChart from "./LearningMaterialUsage";
import Icon from "../../../general/Icon";

const TeacherPortalLearningMaterial = ({ activityWithUsageData, hasBeenOpen }) => {
    const [body, setBody] = useState(null);

    const generateTimeline = () => {
        return <TeacherPortalLearningMaterialUsageTimelineChart activityWithUsageData={activityWithUsageData} />
    }

    useEffect(() => {
        if (hasBeenOpen && body === null) {
            setTimeout(() => setBody(generateTimeline()), 100);
        }
    }, [hasBeenOpen])

    return <Grid item xs={12}>
        <Card>
            <CardContent>
                <Grid container>
                    <Grid item >

                        <Icon size={60} type={activityWithUsageData.activitytype} color="black" />
                    </Grid>
                    <Grid item xs={11}>
                        <CardHeader title={activityWithUsageData.activitytitle} />
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Paper style={{ padding: "8px", height: "100%" }}>
                            <Typography variant="h6"> Usage Information</Typography>
                            <Typography variant="body2" align="left" >Accessed by <b>{activityWithUsageData.total_usage_data.count_users}</b> users in lifetime
                            </Typography>
                            <Typography variant="body2">
                                Accessed by <b>{activityWithUsageData.total_usage_data.count_users_last_30_days}</b> users in the last 30 days </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={10}>
                        {body}

                    </Grid>
                </Grid>
            </CardContent>

        </Card>

    </Grid>
}

export default TeacherPortalLearningMaterial;