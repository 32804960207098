import { Player } from "@lottiefiles/react-lottie-player";
import { requireAsset } from "../utils/asset";
import NavBar from "../components/general/NavBar";


const OnboardingCompleteView = ({onComplete}) => {
    return (
        <div className="z-[51] fixed top-0 left-0 flex flex-col h-screen w-screen bg-background">
          <NavBar />
          <div className="flex flex-col grow justify-center content-center max-w-md mx-auto">
            <h1 className="text-4xl text-center font-MS font-extrabold">
              All done!
            </h1>
            <Player
              src={requireAsset("success.json")}
              className="w-full h-full max-w-60 max-h-60"
              keepLastFrame
              autoplay
            />
            <div className="text-center">
              Thank you! 
            </div>
          </div>
          <div className="fixed bottom-4 left-1/2 -translate-x-1/2 flex flex-row">
            <button
              onClick={() => onComplete()}
              className="p-4 w-fit bg-accent min-w-[100px] text-center shadow-default rounded-lg cursor-pointer hover:scale-110 duration-200 self-end"
            >
              Go To Dashboard
            </button>
          </div>
        </div>
      );
}

export default OnboardingCompleteView;