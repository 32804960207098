import { useEffect, useState } from "react";
import { useCourse } from "../../../contexts/CourseProvider";

const ConceptPil = ({ conceptid, type }) => {
  const { concepts } = useCourse();

  const concept = concepts.find(
    (concept) => concept.id == conceptid && concept.type == type
  );

  if (!concept) return <></>;

  return (
    <div className="relative p-4 bg-card_dark w-fit rounded-lg">
      <div>{concept?.name}</div>
    </div>
  );
};

export default ConceptPil;
