import { motion } from "framer-motion";

const ConceptCardDisabled = ({ concept }) => {
  return (
    <motion.div className="shadow-default text-txt_primary relative bg-card_dark rounded min-w-[50%] max-w-[610px] text-center">
      <div className="relative">
        <div className="p-3 truncate text-lg">{concept.name}</div>
      </div>
    </motion.div>
  );
};

export default ConceptCardDisabled;
