import { Link } from "react-router-dom";
import { ImCheckmark } from "react-icons/im";
import { colors } from "../../config/colors";
import ReactTooltip from "react-tooltip";

const MaterialTile = ({ material, icon, filterid }) => {
  var bggColor = filterid.some((concon) => concon == material.activityid)
    ? "#277c9d"
    : "#e8e9ea";

  if (filterid.length < 1) {
    bggColor = "#277c9d";
  }
  return (
    <div>
    <Link to={`${material.activityid}`} className="cursor-pointer" key={Math.random()}>
      <div
        className="bg-bar-lightblue relative hover:scale-110 duration-200 p-1 rounded"
        style={{ backgroundColor: bggColor, marginBottom: "10px" }}
        data-tip={material.activitytitle}
        key={Math.random()}
      >
        {material.completed && (
          <div className="absolute opacity-80">
            <ImCheckmark size={10} color={colors.bar_average} />
          </div>
        )}
        {icon && <div>{icon}</div>}
      </div>
    </Link>
    
    <ReactTooltip delayShow={400} effect="solid" />
    </div>
  );
};

export default MaterialTile;
