import { TreeNode } from "react-organizational-chart";
import { useCourse } from "../../../contexts/CourseProvider";
import { getDisplayState } from "../../../utils/date";
import ConceptCard from "./ConceptCard";

const ConceptNode = ({ concept, lg }) => {
  const { getConceptChildren } = useCourse();

  const displayState = getDisplayState(lg);

  const LabelNode = () => (
    <div className="flex align-center justify-center">
      <ConceptCard
        concept={concept}
        disabled={displayState === "future"}
        lg={lg}
      />
    </div>
  );

  return (
    <TreeNode label={<LabelNode />}>
      {getConceptChildren(concept.id).map(
        (childConcept) =>
          childConcept.type === "concept" && (
            <ConceptNode
              key={`node-${concept.id}`}
              concept={childConcept}
              lg={lg}
            />
          )
      )}
    </TreeNode>
  );
};

export default ConceptNode;
