import { Card, Modal } from "@material-ui/core";
import TeacherPortalLearningMaterial from "./LearningMaterial";

const TeacherPortalLearningMaterialModal = ({ activityWithUsageData, onClose }) => {
    return <Modal open onClose={() => { onClose() }}>
        <Card style={
            {
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                width: "80%",
                height: "60%",
                border: "none",
            }
        }>
            <TeacherPortalLearningMaterial activityWithUsageData={activityWithUsageData} hasBeenOpen={true} />
        </Card></Modal>
}

export default TeacherPortalLearningMaterialModal;