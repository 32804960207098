import { Box, FormControlLabel, Checkbox, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Tooltip, Area, AreaChart, CartesianGrid, XAxis, YAxis, Legend } from "recharts";
import { capitalizeFirstLetter } from "../../../../utils/string";
import { colors } from "../../../../config/colors";

const colorOptions = [colors.error, colors.warning, colors.info, colors.bar_average, colors.success];

const TeacherPortalLearningMaterialUsageTimelineChart = ({ activityWithUsageData }) => {
    const [showDetail, setShowDetail] = useState(false);
    const [zoomedInOn, setZoomedInOn] = useState(undefined);
    const [amountOfDaysVisible, setAmountOfDaysVisible] = useState(undefined);
    const [controlKeyDown, setControlKeyDown] = useState(false);
    // Would like to take time to refactor.
    var use_data = activityWithUsageData.use_data;
    use_data = use_data.map((useDataInstance) => {
        var newUseData = { ...useDataInstance };
        var verbs = {};
        verbs[useDataInstance.verb] = { users: useDataInstance.count_users, events: useDataInstance.count_events };
        newUseData.verbs = verbs;

        return newUseData;
    });

    // Add event listener for shift key for scrolling in timeline
    useEffect(() => {
        const keyDownHandler = (e) => {
            if (e.key === "Control") {
                setControlKeyDown(true);
            }
        };
        const keyUpHandler = (e) => {
            if (e.key === "Control") {
                setControlKeyDown(false);
            }
        };
        window.addEventListener("keydown", keyDownHandler);
        window.addEventListener("keyup", keyUpHandler);
        return () => {
            window.removeEventListener("keydown", keyDownHandler);
            window.removeEventListener("keyup", keyUpHandler);
        };
    }, []);

    // Loop over array and check for every item if the timestamp is the same. If so, add the count_users and count_events to the existing object
    const timestampMap = {};
    for (const data of use_data) {
        const dateString = new Date(data.timestamp).toISOString().split('T')[0];
        if (timestampMap[dateString]) {
            timestampMap[dateString].count_users = Math.max(timestampMap[dateString].count_users, data.count_users);
            timestampMap[dateString].count_events += data.count_events;
            timestampMap[dateString].verbs[data.verb] = { users: data.count_users, events: data.count_events };
        } else {
            timestampMap[dateString] = data;
            timestampMap[dateString].verbs = { [data.verb]: { users: data.count_users, events: data.count_events } };
        }
        delete data.verb;
    }

    const useDataGroupedPerDay = Object.values(timestampMap);

    // Get all possible verbs
    const verbs = new Set();
    for (const data of useDataGroupedPerDay) {
        for (const verb of Object.keys(data.verbs)) {
            verbs.add(verb);
        }
    }
    const verbsSorted = Array.from(verbs).sort();
    var earliestDate = new Date(Math.min.apply(null, useDataGroupedPerDay.map(function (e) { return new Date(e.timestamp); })));
    var latestDate = new Date(Math.max.apply(null, useDataGroupedPerDay.map(function (e) { return new Date(e.timestamp); })));
    var amountOfDays = ((latestDate - earliestDate) / (1000 * 60 * 60 * 24) + 1);

    // Overwrite earliestDate so that the timespan is always 30 days, change this if you want a different timespan on the chart
    if (amountOfDaysVisible === undefined) {
        setAmountOfDaysVisible(amountOfDays);
    }
    else {
        amountOfDays = amountOfDaysVisible;
    }
    if (zoomedInOn === undefined) {
        earliestDate.setDate(earliestDate.getDate() - 2);
        amountOfDays += 4;
    }
    else {
        earliestDate = new Date(zoomedInOn)
        earliestDate.setDate(earliestDate.getDate() - (amountOfDays / 2) - 1);
        amountOfDays += 4;
    }

    var data = [];
    for (var i = 0; i < amountOfDays; i++) {
        var date = new Date(earliestDate);
        date.setDate(earliestDate.getDate() + i);
        var dateString = date.toISOString().split('T')[0];
        var usageDateOfDate = timestampMap[dateString]
        var dataPoint = { name: dateString, maximum: usageDateOfDate ? usageDateOfDate.count_users : 0 };
        for (const verb of verbsSorted) {
            if (usageDateOfDate && usageDateOfDate.verbs[verb]) {
                dataPoint[verb] = usageDateOfDate.verbs[verb].users;
            }
            else {
                dataPoint[verb] = 0;
            }
        }
        data.push(dataPoint);
    }

    const clickOnChart = (eventargs) => {
        if (controlKeyDown) {
            setZoomedInOn(undefined);
            setAmountOfDaysVisible(undefined);
        }
        else {
            setZoomedInOn(eventargs.activeLabel);
            setAmountOfDaysVisible(Math.ceil(amountOfDaysVisible / 1.5));
        }

    }

    return <>
        <Box>
            <Typography style={{ paddingTop: "35px", color: "grey", float: "right", marginTop: -60 }} >
                {zoomedInOn === undefined ? "Left click to zoom in" : "Left click to zoom in further, Control + Left click to zoom out"}
            </Typography>
            <FormControlLabel control={<Checkbox onChange={(e) => setShowDetail(e.target.checked)} style={{ color: "rgb(255 205 0)" }} />} style={{ float: "right", marginTop: 0 }} label={"Show details"} />
            <AreaChart onClick={clickOnChart}
                width={1100}
                height={400}
                data={data}
                margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 30,
                }}
                style={{ cursor: "pointer" }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis allowDecimals={false} />
                <Tooltip />
                {showDetail ? verbsSorted.map((verb, index) => {
                    return <Area type="monotone" dataKey={verb} name={capitalizeFirstLetter(verb) + " by"} stroke={colorOptions[index]} strokeWidth={3} fill={"#ffffff00"} unit=" Users" />
                })
                    : <Area type="monotone" dataKey={"maximum"} name={"Used by"} stackId="1" stroke={colors.accentdark} strokeWidth={3} fill={colors.accent} unit=" Users" />}
                <Legend verticalAlign="top" />
            </AreaChart>
        </Box>
    </>
}

export default TeacherPortalLearningMaterialUsageTimelineChart;