import Grid from "@material-ui/core/Grid";
import { Card, CardContent, CardHeader, Tab, Tabs } from "@material-ui/core";

import * as React from "react";
import TeacherPortalGeneralStudentInformationTab from "./tabs/StudentGeneralInformationTab";
import TeacherPortalStudentLearningMaterialInformationTab from "./tabs/StudentLearningMaterialInformationTab";
import TeacherPortalStudentQuizResultsInformationTab from "./tabs/StudentQuizResultsInformationTab";
import { colors } from "../../../../config/colors";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                children
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const TeacherPortalStudentTabPanel = ({ student, quizesOfStudent, activities, activitiesWithUsageData }) => {
    const [value, setValue] = React.useState(1);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return <Grid item xs={12}>
        <Card alignItems="left" align="left" justify="left">
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
            TabIndicatorProps={{style: {background:colors.accentdark}}}>
                {/*Disabled for now because of not a lot of information
                 <Tab label="User Information" {...a11yProps(0)} /> */}
                <Tab value={1} label="Learning Material Usage" {...a11yProps(1)} />
                <Tab value={2} label="Quiz results" {...a11yProps(2)} />
            </Tabs>
            <CardHeader title={student.user.firstname + " " + student.user.lastname} />
            <CardContent>
                <TabPanel value={value} index={0}>
                    <TeacherPortalGeneralStudentInformationTab student={student} />
                </TabPanel>

                <TabPanel value={value} index={1}>
                    <TeacherPortalStudentLearningMaterialInformationTab student={student} activities={activities} activitiesWithUsageData={activitiesWithUsageData} />
                </TabPanel>

                <TabPanel value={value} index={2}>
                    <TeacherPortalStudentQuizResultsInformationTab student={student} quizesOfStudent={quizesOfStudent} />
                </TabPanel>

            </CardContent>
        </Card>
    </Grid>
}


export default TeacherPortalStudentTabPanel