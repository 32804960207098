
import {Column, Table, SortDirection, AutoSizer} from "react-virtualized";
import {defaultTableRowRenderer as DefaultTableRowRenderer} from "react-virtualized";
import {defaultTableHeaderRenderer as DefaultTableHeaderRenderer} from "react-virtualized";
import "./LBstyle.css";
import FilterButton from './FilterButton.jsx';
import "react-virtualized/styles.css";
import _ from "lodash";
import React, { useCallback, useEffect, useState, useRef, setState } from "react";
import ReactDOM from "react-dom";
import { useCourse } from "../../../contexts/CourseProvider";
import ReactTooltip from "react-tooltip";
import { useParams } from "react-router";
import HorizontalBar from "../HorizontalBar";



class Leaderboard extends React.Component {
  constructor(props) {
    super(props);             
  const datakeyscore = this.props.activity ? 'activityscore': 'grade';
  //const allprogress = datakeyscore == 'activityscore' ? this.props.scores.filter((score) => score['constructid_y'] == '-1') : undefined
  //const newData = datakeyscore == 'activityscore' ? this.getUnique(allprogress, 'userid') : this.props.scores.filter((score) => score['constructid_y'] == this.props.filterid)
  //Next line allows people who have not completed a quiz to still have a placement in the topic leaderboards
  //const newnewData = datakeyscore == 'activityscore' ? this.props.scores.filter((score) => score.activityscore != "nan") : this.props.scores
  
  const myData = [].concat(this.props.scores).sort((a,b) => {
      if(a['activityscore'] == 'None' || b['activityscore'] == 'None') {return false}
      else {
        let an = parseFloat(a[datakeyscore])
        let bn = parseFloat(b[datakeyscore])
        if(an==bn)
        {
          let cn = parseFloat(a["percentage"])
          let dn = parseFloat(b["percentage"])
          return cn < dn ? 1 : -1
        }
        return an < bn ? 1 : -1
    }})

  const userid = this.props.userid;
    const sortBy = "1";
    const sortDirection = SortDirection.ASC;
    const sortedList = myData;
    const filteredList = sortedList;
    const currentCID= this.props.filterid;
    const scoreCol = this.props.concepts.find((con) => con.id == this.props.filterid) == undefined ? '' : this.props.concepts.find((con) => con.id == this.props.filterid).name;
    const scrollToIndex = sortedList.findIndex(x => parseInt(x.userid) === this.props.userid);

    this.state = {
      sortBy,
      sortDirection,
      sortedList,
      filteredList,
      scrollToIndex,
      userIndex: scrollToIndex,
      rowcount: 1000,
      userinput: '',
      min: 0,
      max: 100,
      currentCID,
      datakeyscore,
      scoreCol,
      scoreColName: "total score"
    };
    const scrollToIndexF = (index) => {
      this.setState({scrollToIndex: index})};

    this.onclick2 = this.onclick2.bind(this);
    this.scrollToIndexF = scrollToIndexF.bind(this);
    this.onclick = this.onclick.bind(this);
    this.onclick3 = this.onclick3.bind(this);
    this.onscroll = this.onscroll.bind(this);
    this._sortList = this._sortList.bind(this);
    this._sort = this._sort.bind(this);
    this.updateCID = this.updateCID.bind(this);
  }

  rowStyleFormat(row) {
    if (row.index < 0) return;
    if (this.props.userid == this.state.sortedList[row.index].userid) {
      return {
        backgroundColor: '#ffcd00',
        color: '#333',
        border: '1px solid #ffcd00',
        borderRadius: '25px',
        fontWeight: 'bold',
        fontSize: '25px'
      };
    }
    else {
    return {
      ...row.style,
      backgroundColor: '#e8e9ea',
      color: '#333',
      //margin: '35px 0px 0px 0px',
      border: '1px solid #ffcd00',
      borderRadius: '25px',
      fontSize: '25px'
    };
  };
  };

  _sortList( sortBy, sortDirection ) {
    let newList = _.sortBy(this.props.conceptScores, [sortBy]);
    if (sortDirection === SortDirection.DESC) {
      newList.reverse();
    }
    return newList;
  };

  _sort ( inputt ) {

    const sortedList = this._sortList(inputt.sortBy, inputt.sortDirection );
    const filteredList = sortedList;
    this.setState({sortBy: inputt.sortBy, sortDirection: inputt.sortDirection, sortedList: sortedList, filteredList: filteredList});
  };  

  getUnique(arr, index) {
    const unique = arr
      .map(e => e[index])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => arr[e]).map(e => arr[e])
      return unique;
  }

  onclick(event) {
    let scrollToIndex = Math.min(
      1000-1, 
      parseInt(event.target.value, 10)
    );
    if(isNaN(scrollToIndex)) {
      scrollToIndex = undefined;
    }
    this.setState({scrollToIndex});
    this.setState({undefined});
    this.setState({userinput: event.target.value})
  }

  onclick2 () {
    let scrollToIndex = this.props.userid;
    this.setState({scrollToIndex});
  }

  setBack() {
    let scrollToIndex = 200;
    this.setState({scrollToIndex});
  }

  onscroll () {
    this.setState({scrollToIndex: this.state.userIndex});
  }

  onclick3() {
    this.setState({userinput: this.state.userIndex})
    let scrollToIndex = Math.min(
      1000-1, 
      parseInt(this.state.userIndex, 10)
    );
    if(isNaN(scrollToIndex)) {
      scrollToIndex = undefined;
    }
    this.setState({scrollToIndex});
  }

  updateCID(a) {
    this.setState({currentCID: a})
  }

  userIcons = (props) => {
    return (
    <div className="firstcolumn">
      <div className="placement">{props.rowIndex + 1}.  
        <img src={props.rowData.avatarlink} width="60" height="60" alt="icons" style={{marginLeft: "5%"}}/>
      </div>
      {props.dataKey}
     </div>)
  }

  learningProgressBars = (props) => {

    return (
          <HorizontalBar
           progress={parseFloat(props.rowData.percentage)/100}
           number={parseFloat(props.rowData.percentage)}
           icon="group"
           showBar={true }
           showDetails={true}
           bgColor={"#227c9d"}
           toolTipTitle="Shows the average score of all students in the class"
         />
    )
  }


  render() {
    return (
    <div className="App" key={this.props.filterid}>


      <div style={{ height: 2300 }}>
        
      <AutoSizer>
        {({ height, width }) => (
          <Table
            width={width}
            height={height}
            headerHeight={50}
            rowHeight={50}
            sortBy={this.state.sortBy}
            sortDirection={this.state.sortDirection}
            rowCount={this.state.filteredList.length}
            rowGetter={({ index }) => this.state.filteredList[index]}
            scrollToIndex={this.state.scrollToIndex}
            scrollToAlignment="start"
            onScroll={this.onscroll}
            rowStyle={this.rowStyleFormat.bind(this)}
          >
            <Column dataKey='' width={width/10 + width/9} cellRenderer={this.userIcons} headerClassName="data-tooltip" data-tooltip="hello"/>
            <Column title="yes" width={width/7} label="Name" dataKey={"displayname"} headerClassName="data-tooltip" data-tooltip="hello"/>
            <Column title="no" width={width/6 + width/20} label={this.state.scoreCol + " score"} headerClassName="data-tooltip" dataKey={this.state.datakeyscore}data-tooltip="hello"/>
            <Column title="nope" width={width/3}label="Learning progress" cellRenderer={this.learningProgressBars} dataKey="percentage" headerClassName="data-tooltip" data-tooltip="hello"/>
          </Table>
        )}
      </AutoSizer>
    </div>
    </div>

  );
  }
}
export default Leaderboard;
