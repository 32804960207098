export const colors = {
  // https://coolors.co/fdfdfc-e8e9ea-ffcd00-f45b69-2892b8-227c9d
  // https://maketintsandshades.com/

  // Containers
  accent: "#FFCD00", // UU yellow
  accentdark: "#EEAB00", // UU yellow darker
  success: "#16CC64", // Green
  background: "#FDFDFC", // broken white
  secondary: "#F8F7F3", // dark broken white
  card: "#ffcd00", // gray tone
  card_dark: "#ffcdff", // darkish gray tone
  bar_score: "#227C9D", // blue green,
  bar_average: "#55a7c9", // cg blue,
  bar_empty_light: "#ffcdff",
  bar_empty_dark: "#ffcd00",
  bar_lightblue: '#28A2B8', 
  bar_verylightblue: '#68BFDE', 
  bar_lightblue1: '#55a7c9',
  bar_lightblue2: '#78bddb',
  bar_lightblue3: '#99d3ed',
  bar_lightblue4: '#b9eaff',
  grd_blue_light_1: '#227c9d',
  grd_blue_light_2: '#428dad',
  grd_blue_light_3: '#5b9fbd',
  grd_blue_light_4: '#73b1cd',
  grd_blue_light_5: '#8bc3dd',
  grd_blue_light_6: '#a2d6ee',
  grd_blue_light_7: '#b9e9ff',
  grd_red_blu_1: '#f45b69',
  grd_red_blu_2: '#fd80a9',
  grd_red_blu_3: '#f9a8dd',
  grd_red_blu_4: '#f2cfff',
  grd_red_blu_5: '#b1b3e7',
  grd_red_blu_6: '#6e98c6',
  grd_red_blu_7: '#227c9d',

  button: "#fff",
  icon: "#000",
  icon_light: "#fff",
  icon_alt: "#808080",
  background_fade: "#00000020", // black with 20% opacity
  link: "#227C9D",

  // Actions
  completed: "#ACD1AF", // green,
  error: "#F45B69", // red,
  warning: "#FFA500", // orange,
  info: "#00BFFF", // blue,

  // Text
  txt_primary: "#000", // black
  txt_primary_light: "#00000050", // black 50%
  txt_secondary: "#fff", // white
};
