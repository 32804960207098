import axios from "axios";
import { getItem, setItem } from "../utils/persistanceStorage";

// set the Backend API URL
// axios.defaults.baseURL = "http://192.168.2.5:80/"; //
axios.defaults.baseURL = "https://studylens.science.uu.nl/backend/"; // University

axios.interceptors.request.use(
  (config) => {
    const userdata = getItem("user-data");
    const authtoken = userdata?.access_token
      ? `Bearer ${userdata?.access_token}`
      : "";
    config.headers.Authorization = authtoken;
    return config;
  },
  (error) =>   
    Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    
    if (error.response && error.response.status === 401) { // Unauthorized error (token expired)
      const refreshToken = getItem('refresh_token');
      
      if (!refreshToken) {
        // Redirect to login if no refresh token is available
        window.location.href = '/login';
        return Promise.reject(error);
      }

      try {
        // Request new access token using refresh token
        const response = await axios.post('/refresh', {}, {
          headers: { Authorization: `Bearer ${refreshToken}` }
        });

        const newAccessToken = response.data.access_token;
        setItem('access_token', newAccessToken); // Save new access token

        // Retry the original request with new access token
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return originalRequest;
      } catch (refreshError) {
        // Redirect to login if refresh token is invalid
        window.location.href = '/login';
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default axios;
