import { AnimatePresence, motion } from "framer-motion";
import { colors } from "../../../config/colors";
import { useCourse } from "../../../contexts/CourseProvider";
import useKMAdaptations from "../../../hooks/useKMAdaptations";
import { progressOutOf10, scoreOutOf10 } from "../../../utils/scores";
import DoubleProgressBar from "./DoubleProgressBar";
import GoalSupportProgressBars from "./GoalSupportProgressBars";
import { conceptFeedbackFromConceptData } from "../../../utils/feedback";

const ConceptSocialBars = ({ concept, hovering, isActive, activityProgress, wantedHeight }) => {
  const {
    show_personal_bar,
    show_personal_bar_details,
    show_class_bar_details,
    show_class_bar,
    display_personal_bar,
    display_class_bar,
    bar_color,
    show_goal_support_bars,
    show_second_progress,
  } = useKMAdaptations(concept, hovering, isActive, activityProgress);

  const { conceptScores, goalOrientation } = useCourse();
  const scores = conceptScores[concept.id];
  if (!scores) return null;

  const user_score = progressOutOf10(scores.userscore, scores.maxscore);
  const avg_score = progressOutOf10(scores.avg_score, scores.maxscore);
  const avgActivityProgress = progressOutOf10(scores.average_learning, scores.total_learning)*100;

  const showNoScore = scores?.userscore == 0 && activityProgress == 0;
  const conceptFeedback = goalOrientation && conceptFeedbackFromConceptData(concept, conceptScores[concept.id], goalOrientation);

  return (
    <div className="flex flex-col">
      <AnimatePresence>
        {showNoScore && (
          <motion.div
            className="text-txt_primary_light w-full text-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: show_personal_bar_details ? 1 : 0 }}
            exit={{ opacity: 0 }}
          >
            &nbsp;
          </motion.div>
        )}
      </AnimatePresence>

      {show_goal_support_bars && !showNoScore && (
        <GoalSupportProgressBars
          scores={scores}
          hovering={hovering}
          suggestedHeight={wantedHeight}
          goalOrientation={goalOrientation}
          toolTipTitle={conceptFeedback}
        />
      )}

      {display_personal_bar && !showNoScore && (
        <div className="relative top-1 z-10">
          <DoubleProgressBar
            mainProgress={user_score}
            addOnProgress={activityProgress}
            number={scores.userscore}
            showBar={show_personal_bar}
            showDetails={show_personal_bar_details}
            bgColor={bar_color}
            toolTipTitle="Your Progress"
            toolTipTitleAddOn="Your Learning"
            suggestedHeight={wantedHeight}
            showAddOn={show_second_progress}
            bgEmpty={colors.bar_empty_dark}
          />
        </div>
      )}

      {display_class_bar && !showNoScore && (
        <div className={"static z-0"}>
          <DoubleProgressBar
            mainProgress={avg_score}
            addOnProgress= {avgActivityProgress}
            number={scores.avg_score}
            showBar={show_class_bar}
            showDetails={show_class_bar_details}
            bgColor={colors.bar_average}
            addOnBgColor={colors.bar_lightblue2}
            toolTipTitle="Class Average Progress"
            toolTipTitleAddOn="Average Learning"
            suggestedHeight={wantedHeight}
            showAddOn={show_second_progress}
            bgEmpty={colors.bar_empty_dark}
          />
        </div>
      )}
    </div>
  );
};

export default ConceptSocialBars;