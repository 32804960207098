import { useEffect, useState } from "react";

const ConceptExplorerView = () => {
  return (
    <div className="flex justify-center items-center w-full h-[calc(100vh-100px)]">
      W.I.P.
    </div>
  );
};

export default ConceptExplorerView;
