import { useEffect, useState } from "react";
import { PDFObject } from "react-pdfobject";
import { useActivity } from "../../../contexts/ActivityProvider";
import FallBackLink from "./FallBackLink";
import { requireAsset } from "./../../../utils/asset";

const PDFContent = () => {
  const { activityData } = useActivity();

  return (
    <div className="p-4">
      <PDFObject
        className="bg-blue-200 w-full h-full"
        height="600px"
        url={
          activityData?.source === "studylens"
            ? requireAsset(activityData.url)
            : activityData.url+"?rs:embed=true"
        }
        fallbackLink="<div>This is a <a href='[url]'>fallback link</a></div>"
        forceIframe
      />
      <FallBackLink
        url={
          activityData?.source === "studylens"
            ? requireAsset(activityData.url)
            : activityData.url
        }
      />
    </div>
  );
};

export default PDFContent;
