import { Card, Modal } from "@material-ui/core";
import TeacherPortalStudentTabPanel from "./StudentTabPanel";

const TeacherPortalStudentModal = ({ student, activities, quizesOfStudent, onClose, activitiesWithUsageData}) => {
    return <Modal open onClose={() => { onClose() }}>
        <Card style={
            {
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                width: "80%",
                height: "60%",
                border: "none",
                overflow:'scroll',
            }
        }>
            <TeacherPortalStudentTabPanel student={student} activities={activities} quizesOfStudent = {quizesOfStudent} activitiesWithUsageData={activitiesWithUsageData}/>
        </Card></Modal>
}

export default TeacherPortalStudentModal;       