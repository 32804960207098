import Grid from "@material-ui/core/Grid";
import { Box, Card, CardContent, CardHeader } from "@material-ui/core";

import * as React from "react";
import TeacherPortalLearningMaterialInteractionChart from "../learning_materials/LearningMaterialInteractionChart";
import TeacherPortalQuizResultsChart from "../chart/quiz_results/QuizResultsChart";
import TeacherPortalLatestActivities from "../activities/LatestActivities";


const TeacherPortalCourseDashboard = ({ activitiesWithUsageDataGroupedByType, quizesInformation, userEvents, loadMoreUserEvents }) => {
    const increaseHeightOfQuizes = quizesInformation.length > 6

    return <>
        <Grid container spacing={4} align="center" >
            <Grid item xs={8}>
                <Card>
                    <CardHeader title="Learning material usage" />
                    <CardContent>
                        <Box>
                            <TeacherPortalLearningMaterialInteractionChart
                                activitiesWithUsageDataGroupedByType={activitiesWithUsageDataGroupedByType}
                                width={900}
                                height={increaseHeightOfQuizes ? 900 : 400}
                            />
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={4} align="center" >
                <Card>
                    <CardHeader title="Quiz results" />
                    <CardContent>
                        <TeacherPortalQuizResultsChart quizesInformation={quizesInformation}
                            width={450}
                            minHeight={750}
                            maxHeight={increaseHeightOfQuizes ? 900 : 400}
                        />
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12}>
                <Card>
                    <CardHeader title="Latest Activities" />

                    <CardContent>
                        <TeacherPortalLatestActivities userEvents={userEvents} loadMoreUserEvents={loadMoreUserEvents} />
                    </CardContent>
                </Card>
            </Grid>


        </Grid>
    </>
};

export default TeacherPortalCourseDashboard