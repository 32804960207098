import { useEffect, useState } from "react";
import { useActivity } from "../../../contexts/ActivityProvider";
import FallBackLink from "./FallBackLink";
import Skeleton from "react-loading-skeleton";
import Loading from "./../../general/Loading";

const AudioContent = () => {
  const { activityData } = useActivity();
  const [loadingIframe, setLoadingIframe] = useState(true);

  return (
    <div className="w-full p-4">
      <div className="mb-2">{activityData.description}</div>
      {activityData.is_html && (
        <div
          dangerouslySetInnerHTML={{
            __html: activityData.html,
          }}
        />
      )}

      {!activityData.is_html && (
        <div className="w-full my-4 h-[600px]">
          {loadingIframe && <Loading />}
          <iframe
            title="web content"
            src={activityData.url}
            height="600px"
            width="100%"
            onLoad={() => setLoadingIframe(false)}
          />
        </div>
      )}
      <FallBackLink url={activityData.url} />
    </div>
  );
};

export default AudioContent;
