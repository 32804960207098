import DOMPurify from "dompurify";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import generalApi from "../../../api/general";
import { useCourse } from "../../../contexts/CourseProvider";

function ReadAndAnswerMAQ({ activityid, questiondata, showAnswer, onSubmit, onLoadExisting }) {
  const sanitizedQuestion = DOMPurify.sanitize(questiondata.text);
  const params = useParams();
  const { course, updateConceptScores } = useCourse();
  const [selected, setSelected] = useState([]);
  const [locked, setLocked] = useState(false);
  const [answerAlreadyCorrect, setAnswerAlreadyCorrect] = useState(false);
  const correctIndexes = questiondata.answerScores
    .map((value, index) => (value > 0 ? index : -1))
    .filter((index) => index !== -1);

  const submit = () => {
    !locked && !showAnswer && onSubmit(activityid, selected);
    setLocked(true);
    onLoadExisting(activityid);
  };

  const unlock = () => {
    !showAnswer && !answerAlreadyCorrect && setLocked(false);
  }

  const updateAnswers = () => {
    const activityAnswers = generalApi.getActivityAnswer(activityid, params.courseid).then((activityAnswers) => {
      const prev_selected = activityAnswers?.data?.results?.answer?.userAnswerOptionIndexes;
      const prev_success = activityAnswers?.data?.results?.success;      

      if (prev_selected) {
        setSelected(prev_selected);
        onLoadExisting(activityid);
      }

      if (prev_success) {
        setAnswerAlreadyCorrect(true);
        setLocked(true);
      }
    });
  };

  useEffect(() => {
    updateAnswers();
    answerAlreadyCorrect && setLocked(true);
  }, []); 

  const handleOptionSelect = (index) => {
    if (locked) return;

    let updatedSelected = [...selected];
    const isSelected = updatedSelected.includes(index);
    if (isSelected) {
      updatedSelected = updatedSelected.filter((item) => item !== index);
    } else {
      updatedSelected.push(index);
    }
    setSelected(updatedSelected);
  };

  return (
    <div className="text-left mx-10">
      <p className="text-gray-500 text-sm mb-0 mt-10 ">Question</p>
      <hr />
      <p className="mb-4 text-lg font-medium" dangerouslySetInnerHTML={{ __html: sanitizedQuestion }}></p>

      <div className="divide-y divide-slate-200">
        {questiondata.options.map((option, index) => {
          const isSelected = selected.includes(index);
          

          const optionClassName = `px-4 py-1 my-1 cursor-pointer ${
            isSelected && (showAnswer || locked) ? (correctIndexes.includes(index) ? "bg-green-400" : "bg-red-400") : isSelected && !(showAnswer || locked) ? "bg-blue-200" : ""
          } hover:shadow-md`;

          return (
            <div key={index} className={optionClassName} onClick={() => { if (!showAnswer) { handleOptionSelect(index); } }} >
              <span>{option}</span>
              {showAnswer && correctIndexes.includes(index) && <span className="text-green-600 ml-2">(Correct)</span>}
              {showAnswer && !correctIndexes.includes(index) && isSelected && (
                <span className="text-red-600 ml-2">(Incorrect)</span>
              )}
            </div>
          );
        })}
      </div>

      {!showAnswer && !locked && (
        <div
          className="btn cursor-pointer bg-accent hover:bg-amber-400 duration-500 text-white font-bold px-4 rounded w-20 text-center"
          onClick={() => submit()}
        >
          Submit
        </div>
      )}
      {/* {!showAnswer && locked && !answerAlreadyCorrect && (
        <div
          className="btn cursor-pointer bg-accent hover:bg-amber-400 duration-500 text-white font-bold px-4 rounded w-20 text-center"
          onClick={() => unlock()}
        >
          Reset
        </div>
      )} */}

    </div>
  );
}

export default ReadAndAnswerMAQ;
