export const CC_CONFIG = [
  // Social Group 0 - No Social comparison
  {
    default_show_personal_bar: true,
    default_show_personal_bar_details: false,
    default_show_class_bar: false,
    default_show_class_bar_details: false,
    default_show_negative_border: true,
    default_show_positive_border: true,

    hover_show_personal_bar: true,
    hover_show_personal_bar_details: true,
    hover_show_class_bar: false,
    hover_show_class_bar_details: false,
    hover_show_positive_border: true,
    hover_show_negative_border: true,

    expanded_show_personal_bar: true,
    expanded_show_personal_bar_details: true,
    expanded_show_class_bar: false,
    expanded_show_class_bar_details: false,
    expanded_show_positive_border: true,
    expanded_show_negative_border: true,

    show_positive_border: true,
    show_negative_border: true,

    use_individual_score_border: true,
    show_misconception_badges: false,
  },
  // Social Group 1 - Social comparison on demand
  {
    default_show_personal_bar: true,
    default_show_personal_bar_details: false,
    default_show_class_bar: false,
    default_show_class_bar_details: false,
    default_show_negative_border: true,
    default_show_positive_border: true,

    hover_show_personal_bar: true,
    hover_show_personal_bar_details: true,
    hover_show_class_bar: true,
    hover_show_class_bar_details: true,
    hover_show_negative_border: true,
    hover_show_positive_border: true,

    expanded_show_personal_bar: true,
    expanded_show_personal_bar_details: true,
    expanded_show_class_bar: true,
    expanded_show_class_bar_details: true,
    expanded_show_negative_border: true,
    expanded_show_positive_border: true,

    show_positive_border: true,
    show_negative_border: true,

    use_individual_score_border: true,
    show_misconception_badges: true,
  },
  // Social Group 2 - Social comparison by default
  {
    default_show_personal_bar: true,
    default_show_personal_bar_details: false,
    default_show_class_bar: true,
    default_show_class_bar_details: false,
    default_show_negative_border: true,
    default_show_positive_border: true,

    hover_show_personal_bar: true,
    hover_show_personal_bar_details: true,
    hover_show_class_bar: true,
    hover_show_class_bar_details: true,
    hover_show_negative_border: true,
    hover_show_positive_border: true,

    expanded_show_personal_bar: true,
    expanded_show_personal_bar_details: true,
    expanded_show_class_bar: true,
    expanded_show_class_bar_details: true,
    expanded_show_negative_border: true,
    expanded_show_positive_border: true,

    show_second_progress: false,

    show_positive_border: true,
    show_negative_border: true,

    use_individual_score_border: false,
    show_misconception_badges: true,
  },
  // Social Group 3 - Social comparison for Effort
  {
    default_show_personal_bar: true,
    default_show_personal_bar_details: false,
    default_show_class_bar: true,
    default_show_class_bar_details: false,
    default_show_negative_border: true,
    default_show_positive_border: true,

    hover_show_personal_bar: true,
    hover_show_personal_bar_details: true,
    hover_show_class_bar: true,
    hover_show_class_bar_details: true,
    hover_show_negative_border: true,
    hover_show_positive_border: true,

    expanded_show_personal_bar: true,
    expanded_show_personal_bar_details: true,
    expanded_show_class_bar: true,
    expanded_show_class_bar_details: true,
    expanded_show_negative_border: true,
    expanded_show_positive_border: true,

    show_second_progress: true,

    show_positive_border: true,
    show_negative_border: true,

    use_individual_score_border: false,
    show_misconception_badges: true,
  },
  // Goal orientation group - mastery-approach
  {
    dont_show_progress:true,
    show_goal_support_bars:true,
  },
  // Goal orientation group - mastery-avoidant
  {
    dont_show_progress:true,
    show_goal_support_bars:true,
  },
  // Goal orientation group - performance-approach
  {
    dont_show_progress:true,
    show_goal_support_bars:true,
  },
  // Goal orientation group - performance-avoidant
  {
    dont_show_progress:true,
    show_goal_support_bars:true,
  },
  // 8 Paws Social Group 0 - No Social comparison
  {
    default_show_personal_bar: true,
    default_show_personal_bar_details: false,
    default_show_class_bar: false,
    default_show_class_bar_details: false,
    default_show_negative_border: true,
    default_show_positive_border: true,

    hover_show_personal_bar: true,
    hover_show_personal_bar_details: true,
    hover_show_class_bar: false,
    hover_show_class_bar_details: false,
    hover_show_positive_border: true,
    hover_show_negative_border: true,

    expanded_show_personal_bar: true,
    expanded_show_personal_bar_details: true,
    expanded_show_class_bar: false,
    expanded_show_class_bar_details: false,
    expanded_show_positive_border: true,
    expanded_show_negative_border: true,

    show_positive_border: true,
    show_negative_border: true,

    use_individual_score_border: true,
    show_misconception_badges: false,

    dont_show_progress:true,
  },
  // 9 Paws Social Group 1 - Social comparison on demand
  {
    default_show_personal_bar: true,
    default_show_personal_bar_details: false,
    default_show_class_bar: false,
    default_show_class_bar_details: false,
    default_show_negative_border: true,
    default_show_positive_border: true,

    hover_show_personal_bar: true,
    hover_show_personal_bar_details: true,
    hover_show_class_bar: true,
    hover_show_class_bar_details: true,
    hover_show_negative_border: true,
    hover_show_positive_border: true,

    expanded_show_personal_bar: true,
    expanded_show_personal_bar_details: true,
    expanded_show_class_bar: true,
    expanded_show_class_bar_details: true,
    expanded_show_negative_border: true,
    expanded_show_positive_border: true,

    show_positive_border: true,
    show_negative_border: true,

    use_individual_score_border: true,
    show_misconception_badges: true,

    dont_show_progress:true,
  },
  // 10 Paws Social Group 2 - Social comparison by default
  {
    default_show_personal_bar: true,
    default_show_personal_bar_details: false,
    default_show_class_bar: true,
    default_show_class_bar_details: false,
    default_show_negative_border: true,
    default_show_positive_border: true,

    hover_show_personal_bar: true,
    hover_show_personal_bar_details: true,
    hover_show_class_bar: true,
    hover_show_class_bar_details: true,
    hover_show_negative_border: true,
    hover_show_positive_border: true,

    expanded_show_personal_bar: true,
    expanded_show_personal_bar_details: true,
    expanded_show_class_bar: true,
    expanded_show_class_bar_details: true,
    expanded_show_negative_border: true,
    expanded_show_positive_border: true,

    show_positive_border: true,
    show_negative_border: true,

    use_individual_score_border: false,
    show_misconception_badges: true,

    dont_show_progress:true,
  },
];
