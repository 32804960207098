import { getDisplayState } from "../../../utils/date";
import { useCourse } from "../../../contexts/CourseProvider";
import ConceptTile from "../concept/ConceptTile";
import LGInfoLB from "./LGInfoLB";
import {useState} from "react";

const LearningGoalLB = ({ lg, filterid}) => {
  const { concepts, conceptIsTree, getConceptChildren, findConceptFromId, activities, conceptScores } =
    useCourse();

  // displayState can be past, active or future
  const displayState = getDisplayState(lg);
  // Find the concepts details which belong to this learning goal
  const lg_concepts = lg.constructs.map((construct) =>
    findConceptFromId(construct.constructid, concepts)
  );
    //console.log(conceptScores)
  const lg_topics = concepts.filter((con) => con.type == "topic");
  //console.log(lg_topics);

  let lg_topic = 0;

  for(let i = 0; i < lg_topics.length; i++)
  {
    if (lg.constructs.some((construct) => construct.constructid == lg_topics[i].id))
    {
      lg_topic = lg_topics[i].id
    }
  }

  /**
   * Get all the concepts which are a tree node (have children or no children en no parent)
   * Sort them by having children first and singletons
   */
  const lg_tree_concepts = lg_concepts
    .filter(
      (concept) =>
        concept && conceptIsTree(concept.id) && concept.type !== "misconception"
    )
    .sort((a, b) => {
      const a_children = getConceptChildren(a.id);
      const b_children = getConceptChildren(b.id);
      return b_children.length - a_children.length;
    });

  // Load the correct tailwind classes depending on the displayState
  const getLearningGoalStyle = () => {
    if (displayState === "future") return "bg-card rounded opacity-50";
    if (displayState === "active")
      return "bg-card rounded shadow-default opacity-100";
    return "bg-card rounded opacity-100";
  };

  let requiredHeight = "180px"
  const topicconcept = concepts.find((con) => con.id == lg_topic)

  if(topicconcept != undefined) {
      let conceptActivities = topicconcept.activity_ids.map((id) =>
          activities?.find((activity) => activity.activityid === id)
      );

      requiredHeight = (180 + Math.ceil((conceptActivities.length / 6)) * 60) + "px";
  }

  return (
    <div className={getLearningGoalStyle()}>
      <div className="py-2 my-2 flex" >
        <LGInfoLB lg={lg} />

        <div className="flex gap-2 flex-wrap" style={{height: requiredHeight, width: "100%"}}>
          {lg_tree_concepts.length > 0 &&
              <ConceptTile
                key={`${lg.lgid}-${lg.lgid}`}
                concept={concepts.find((con) => con.id == lg_topic)}
                disabled={displayState === "future"}
                lg={lg}
                filterid={filterid}
              />
            }
          {lg_tree_concepts.length <= 0 && (
            <div className="p-4">
              This learning goal has no concepts to show.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LearningGoalLB;
