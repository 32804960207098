import xapiverbs from "../../../config/xapiverbs";
import { saveEvent } from "../../../api/userevents";
import { colors } from "../../../config/colors";
import { useParams } from "react-router";
import { useActivity } from "../../../contexts/ActivityProvider";

const CompleteActivityButton = ({ active = false }) => {
  const params = useParams();
  const { activity, activityStatus, markActivityAsCompleted, loadingMainInfo } = useActivity();
  const handleCompleted = () => {
    if (activity && !activityStatus.completed && active) {
      saveEvent(params.activityid, params.courseid, xapiverbs.COMPLETED);
      markActivityAsCompleted(activity.activityid);
    }
  };

  if (
    !activityStatus ||
    activity.activitytype === "learning_material_quiz" ||
    activity.activitytype.includes("learning_material_paws") || activity.activitytype.includes("readandanswer")
  )
    return null;

  return (
    <div
      onClick={handleCompleted}
      className={`truncate hover:scale-105 duration-200 min-w-[100px] cursor-pointer rounded px-2 py-1 h-fit w-fit font-MS text-sm text-center`}
      style={{
        backgroundColor: activityStatus.completed ? colors.completed : active ? colors.accent : colors.card_dark,
        cursor: activityStatus.completed || !active ? "default" : "pointer",
      }}
    >
      {activityStatus?.completed ? "Completed" : "Mark as complete"}
    </div>
  );
};

export default CompleteActivityButton;
