import { useEffect, useState } from "react";

const FallBackLink = ({ url }) => {
  return (
    <div>
      <div className="text-xs mt-4">
        If the link above is not loading or showing, please click this link
        instead:
      </div>
      <div className="text-xs text-link ">
        <a href={url} target="__blank">
          {url}
        </a>
      </div>
    </div>
  );
};

export default FallBackLink;
