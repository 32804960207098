import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { capitalizeFirstLetter } from "../../../../../utils/string";
import BoldTableCell from "../../../general/table/BoldTableCell";
import TeacherPortalLearningMaterialModal from "../../../learning_materials/learning_material/LearningMaterialModal";
import React from "react";
import { colors } from "../../../../../config/colors";

const TeacherPortalStudentLearningMaterialInformationTab = ({ student, activities, activitiesWithUsageData }) => {
    const [learningMaterialModalId, setLearningMaterialModalId] = React.useState(undefined)
    const [hoveringOnActivityId, setHoveringOnActivityId] = React.useState(undefined)

    student = {

        ...student, "use_data": student.use_data.map((useData) => {
            const activity = activities.find((activity) =>
                activity.activityid === useData.activityid)
            useData.activitytitle = activity.activitytitle
            return useData
        })
    }
    const sortedUseData = student.use_data.sort((a, b) => {
        // Sort by activitytitle
        const aTitle = a.activitytitle.toLowerCase();
        const bTitle = b.activitytitle.toLowerCase();
        if (aTitle < bTitle) {
            return -1;
        }
        if (aTitle > bTitle) {
            return 1;
        }
        return 0;
    })
    if (sortedUseData.length === 0) {
        return <Typography>This student has not used any learning materials yet.</Typography>
    }
    else {
        return <>
            {learningMaterialModalId !== undefined &&
                <TeacherPortalLearningMaterialModal activityWithUsageData={activitiesWithUsageData.find((activityWithUsageData) => activityWithUsageData.activityid == learningMaterialModalId)} onClose={() => setLearningMaterialModalId(undefined)} />
            }
            <Table>
                <TableHead>
                    <TableRow>
                        <BoldTableCell>
                            Learning Material
                        </BoldTableCell>
                        <BoldTableCell>
                            First Accessed
                        </BoldTableCell>
                        <BoldTableCell>
                            Last Accessed
                        </BoldTableCell>
                        <BoldTableCell>
                            Number of actions performed
                        </BoldTableCell>
                        <BoldTableCell>
                            Actions performed
                        </BoldTableCell>
                    </TableRow>
                </TableHead>
                <TableBody onMouseLeave={() => setHoveringOnActivityId(undefined)}>
                    {sortedUseData.map((activityUseData, index) => {
                        return <TableRow style={{
                            cursor: "pointer",
                            backgroundColor: hoveringOnActivityId === activityUseData.activityid ? colors.card_dark : "white"
                        }} onClick={() => setLearningMaterialModalId(activityUseData.activityid)} onMouseOver={() => setHoveringOnActivityId(activityUseData.activityid)}>
                            <TableCell>
                                {activityUseData.activitytitle}
                            </TableCell>
                            <TableCell>
                                {activityUseData.min_timestamp}
                            </TableCell>
                            <TableCell>
                                {activityUseData.max_timestamp}
                            </TableCell>
                            <TableCell>
                                {activityUseData.count_events}
                            </TableCell>
                            <TableCell>
                                {activityUseData.verbs.map(capitalizeFirstLetter).join(", ")}
                            </TableCell>
                        </TableRow>

                    })}
                </TableBody>
            </Table>
        </>
    }
}


export default TeacherPortalStudentLearningMaterialInformationTab