import { Card, CardContent, CardHeader } from "@material-ui/core";
import { useState } from "react";
import TeacherPortalQuestionStatistics from "./question/QuestionStatistics";
import TeacherPortalQuizQuestionSelector from "./QuizQuestionSelector";

const TeacherPortalQuizStatistics = ({ quizInformation }) => {
    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0)

    const selectedQuestion = quizInformation.questions[selectedQuestionIndex]

    return <>
        <Card >
            <CardHeader title={"Quiz: " + quizInformation.activitytitle} >
            </CardHeader>
            <CardContent>
                {
                    (
                        <>
                            <TeacherPortalQuizQuestionSelector questions={quizInformation.questions} functionToCallOnClickWithIndex={setSelectedQuestionIndex} />

                            {selectedQuestionIndex != undefined ?
                                <TeacherPortalQuestionStatistics selectedQuestion={selectedQuestion} selectedQuestionIndex={selectedQuestionIndex} /> : <></>
                            }
                        </>
                    )

                }
            </CardContent>
        </Card>
    </>
};

export default TeacherPortalQuizStatistics