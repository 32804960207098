import axios from "./axios";
import { getItem } from "./../utils/persistanceStorage";

// verb can be: "load", "click", "answer", "submit", "start", "play", "stop", "pause"

export const saveEvent = (activityid, courseid, verb, extrajson = {}) => {
  const userdata = getItem("user-data");
  return axios.post(
    "/saveuserevent",
    {
      activityid,
      courseid,
      sessionid: userdata?.sessionid,
      verb,
      extrajson,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

/*
The following request JSON is for providing extra information when the user clicks something on a knowledge map. The values are fake. Knowlege map is not an activity so you can keep it as -1 (tentatively).

{
    "activityid": -1,
    "courseid": 2,
    "sessionid": "asdf",
    "verb": "clicked",
    "extrajson": {"knowledgemap":true, "clicked_concept_id":23, "learning_goal_id":3, "timestamp": <put timestamp>}
}

When user presses Next on a quiz question, you can generate a request like this:
{
    "activityid": 61,  /// corresponds to the quiz child activity
    "courseid": 2,  // from the browser storage
    "sessionid": "asdf", // from the session storage
    "verb": "completed", // check the list we had last time. If we don't have a list, we'll make one
    "extrajson": {"option":2, "timestamp":<put timestamp>}, // user selected the third option
}

Expected:
{
    "success": true
}

I'm not using timestamp but later I'll think of a way to cache such events in the browser and send after every 10-20 such actions.
*/

export const getUserEventDataForLearningMaterial = (activityid) => {


  return axios.get(
    "/user_event_data_for_learning_material",
    {params: {activityid}},
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const getUserEventDataForLearningMaterialForCourse = (courseid) => {


  return axios.get(
    "/user_event_data_for_learning_materials_of_course",
    {params: {courseid}},
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const getUserEventsForCourse = (courseid, offset, limit) => {


  return axios.get(
    "/user_events_of_course",
    {params: {courseid, offset, limit}},
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};