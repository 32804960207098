import { getDisplayState } from "../../../utils/date";
import { useCourse } from "../../../contexts/CourseProvider";

import ConceptTree from "../concept/ConceptTree";
import LGInfo from "./LGInfo";

const LearningGoal = ({ lg }) => {
  const { concepts, conceptIsTree, getConceptChildren, findConceptFromId } =
    useCourse();

  // displayState can be past, active or future
  const displayState = getDisplayState(lg);

  // Find the concepts details which belong to this learning goal
  const lg_concepts = lg.constructs.map((construct) =>
    findConceptFromId(construct.constructid, concepts)
  );

  /**
   * Get all the concepts which are a tree node (have children or no children en no parent)
   * Sort them by having children first and singletons
   */
  const lg_tree_concepts = lg_concepts
    .filter(
      (concept) =>
        concept && conceptIsTree(concept.id) && concept.type !== "misconception"
    )
    .sort((a, b) => {
      const a_children = getConceptChildren(a.id);
      const b_children = getConceptChildren(b.id);
      return b_children.length - a_children.length;
    });

  // Load the correct tailwind classes depending on the displayState
  const getLearningGoalStyle = () => {
    if (displayState === "future") return "bg-card rounded opacity-50";
    if (displayState === "active")
      return "bg-card rounded shadow-default opacity-100";
    return "bg-card rounded opacity-100";
  };

  return (
    <div className={getLearningGoalStyle()}>
      <div className="pt-1 my-1 flex">
        <LGInfo lg={lg} />
        <div className="flex gap-2 flex-wrap">
          {/* The following iterates over each goals and finds the main Concepts defined for it */}
          {lg_tree_concepts.length > 0 &&
            lg_tree_concepts.map((concept) => (
              <ConceptTree
                key={`${concept.id}-${lg.lgid}`}
                concept={concept}
                lg={lg}
              />
            ))}
          {lg_tree_concepts.length <= 0 && (
            <div className="p-2">
               &nbsp; 
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LearningGoal;
