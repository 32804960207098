import NavBar from "../components/general/NavBar";
import { goalOrientations } from "../config/goalorientations";
import userApi from "../api/user";
import { useCourse } from "../contexts/CourseProvider";
import { useState } from "react";
import xapiverbs from "../config/xapiverbs";
import { saveEvent } from "../api/userevents";

const ChooseGoalOrientationView = (props) => {
  const { course, goalOrientation } = useCourse();
  
  const [selected, setSelected] = useState(
    props.suggestedGoalOrientation ?? goalOrientation
  );

  const submit = async () => {
    userApi.setGoalOrientation(course.courseid, selected).then(() => {
      props.setGoalOrientation(selected);

      saveEvent(
        -1,
        course.courseid,
        xapiverbs.GOALCHANGED,
        {
          goalOrientation: selected,
          previousGoalOrientation: goalOrientation,
        }
      );
    });
  };

  const multipleChoiceOptions = Object.keys(goalOrientations).map((key) => {
    return (
      <div
        key={key}
        onClick={() => setSelected(key)}
        className={`border-2 p-4 cursor-pointer hover:bg-secondary hover:scale-110 duration-200 ${
          key == selected ? "border-black" : "border-background_fade"
        }`}
      >
        {goalOrientations[key]}
      </div>
    );
  });

  return (
    <div className="z-[51] fixed top-0 left-0 flex flex-col h-screen w-screen bg-background">
      <NavBar />
      <div className="flex flex-col grow bg-card">
        <div className="flex flex-row grow mx-12 mt-12">
          <div className="w-full max-w-6xl mx-auto bg-background border-2 border-background_fade flex flex-col justify-center p-8">
            <div className="w-full text-center">
              <div className="pb-4 px-4 text-4xl">Select your Goal...</div>
              {selected != null && (
                <div className="pb-8 px-4">
                  <b>{goalOrientations[props.suggestedGoalOrientation]}</b>.
                </div>
              )}
            </div>
            <div className="mx-auto grid grid-cols-2 gap-4">
              {multipleChoiceOptions}
            </div>
          </div>
        </div>
      </div>
      <div className="fixed bottom-4 left-1/2 -translate-x-1/2 flex flex-row">
        <button
          onClick={submit}
          className="p-4 w-fit bg-accent min-w-[100px] text-center shadow-default rounded-lg cursor-pointer hover:scale-110 duration-200 self-end"
        >
          Okay
        </button>
      </div>
    </div>
  );
};

export default ChooseGoalOrientationView;
