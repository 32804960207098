import { motion } from "framer-motion";
import { useState } from "react";
import { useCourse } from "../../../contexts/CourseProvider";
import useOutsideClick from "./../../../hooks/useOutsideClick";
import useWindowSize from "./../../../hooks/useWindowSize";
import ConceptHeader from "./ConceptHeader";
import MaterialListTiles from "./MaterialListTiles";

const contentVars = {
    visible: {
        opacity: 1,
        transition: {
            duration: 0.2,
            when: "beforeChildren",
        },
    },
    hidden: {
        opacity: 0,
        transition: {
            duration: 0.2,
            when: "afterChildren",
        },
    },
};

const ConceptTileExpanded = ({
    concept,
    cardRef,
    conceptBorder,
    handleClick,
    activityProgress,
    filterid,
    conceptActivities,
    lg
}) => {
    const { width, height } = useWindowSize();
    const { ref } = useOutsideClick(handleClick);
    const [hovering, setHovering] = useState(false);
    const { activities, getMisconceptionsWithActivities, conceptScores } =
        useCourse();
    //console.log(concept);
    const childMisconceptions = getMisconceptionsWithActivities(concept.id);

    // Check on what horizontal side the concept card should render
    const horizontalDisplay =
        cardRef?.getBoundingClientRect()?.left > width / 2
            ? { right: 0 }
            : { left: 0 };

    // Check on what vertical side the concept card should render
    const verticalDisplay =
        cardRef?.getBoundingClientRect()?.top > height / 2
            ? { bottom: 0 }
            : { top: 0 };

    // To see all activities of a course in the expanded card uncomment the line below
    //conceptActivities = activities;

    return (
        <>
            <motion.div
                className="overflow-hidden bg-accent rounded text-left cursor-default flex flex-col gap-2 z-40 shadow-large absolute"
                initial="hidden"
                animate="visible"
                exit="hidden"
                style={{
                    ...conceptBorder,
                    width: "70%"
                }}
                variants={contentVars}
                onHoverStart={() => setHovering(true)}
                onHoverEnd={() => setHovering(false)}
                ref={ref}
            >
                {/* Concept Card details */}
                <ConceptHeader
                    isActive={true} 
                    concept={concept}
                    hovering={hovering}
                    activityProgress={activityProgress}
                    wantedHeight={{height: "25px"}}
                />

                <div className="flex flex-col gap-4">
                    {/* Content description */}
                    <motion.div
                        variants={contentVars}
                        className="px-2 text-sm min-w-[400px]"
                    >
                        {concept.desc}
                    </motion.div>

                    {/* Content conceptActivities */}
                    {concept.type == "topic" && (
                        <div>
                            <div className="font-MS text-txt_secondary font-bold w-full px-2 text-center py-1 mb-2 bg-info" style={{backgroundColor: "#ffcd00"}}>
                                LEARNING MATERIALS
                            </div>
                            <MaterialListTiles materialList={conceptActivities} filterid={filterid} />
                        </div>
                    )}
                </div>
            </motion.div>
        </>
    );
};

export default ConceptTileExpanded;
