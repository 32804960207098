import { useEffect, useState } from "react";
import { useActivity } from "../../../../contexts/ActivityProvider";
import { useParams } from "react-router";
import { useCourse } from "../../../../contexts/CourseProvider";
import userApi from "../../../../api/user";
import ConceptPil from "../../quiz/ConceptPil";
import QuizResultStats from "./QuizResultStats";
import QuestionFormat from "../../quiz/QuestionFormat";
import QuestionSelectList from "../../quiz/QuestionSelectList";
import { colors } from "./../../../../config/colors";

const QuizResultContent = () => {
  const params = useParams();
  const { activity, childActivities } = useActivity();
  const { experimentGroup } = useCourse();
  const [score, setScore] = useState(null);
  const [childActivity, setChildActivity] = useState(null);
  const [loading, setLoading] = useState(true);

  // Question information
  let childActivityData = childActivity
    ? // ? JSON.parse(childActivity?.datajson)
      childActivity?.datajson
    : null;

  const childActivityAnswer = score?.childActivities?.find(
    (act) => act.activityid === childActivity?.activityid
  );

  useEffect(() => {
    userApi
      .getUserActivityScores(params?.activityid)
      .then((res) => {
        setScore(res.data);
        if (typeof res.data == "string") {
          childActivityData = childActivity
            ? JSON.parse(childActivity.datajson)
            : null;
        } else {
          childActivityData = childActivity
            ? // ? JSON.parse(childActivity.datajson)
              childActivity.datajson
            : null;
        }
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
      });
  }, []);

  /**
   * Load the first child activity whenever childActivities is loaded in
   */
  useEffect(() => {
    if (childActivities?.length > 0) {
      setChildActivity(childActivities[0]);
    }
  }, [childActivities]);

  if (loading) return <div className="p-4">Loading results.</div>;

  if (!activity && !score && !childActivity) return null;

  const handleOptionStyle = (optionIndex) => {
    // If answer is the correct answer
    if (childActivityData?.answerScores[optionIndex])
      return { backgroundColor: colors.completed };

    // If answer is the wrong answer
    if (childActivityAnswer?.user_selections.includes(optionIndex))
      return { backgroundColor: colors.error };

    return {};
  };

  return (
    <>
      <div className="relative p-4">
        {/* SHOW SUBQUESTIONS */}
        {/* <QuizResultStats
          score={score}
          showClassDetails={experimentGroup?.rp?.show_class_score}
        /> */}
        {/* Line div */}
        {/* <div className="bg-card_dark h-[2px] rounded w-full my-2"></div> */}
        {/* Quiz select option list */}
        <QuestionSelectList
          questions={childActivities}
          score={score}
          activeQuestion={childActivity}
          setActiveQuestion={setChildActivity}
        />
        {/* Question with options */}
        <QuestionFormat
          question={childActivity}
          questionData={childActivityData}
          handleOptionStyle={handleOptionStyle}
          showAnswers
          answersIndexes={childActivityAnswer?.user_selections}
        />
        {/* Related concepts and misconceptions */}
        <div className="flex justify-between gap-4 mt-4">
          {experimentGroup?.rp?.show_concepts &&
            childActivityData?.answerConcepts?.filter((num) => num !== 0)
              .length > 0 && (
              <div className="flex flex-col items-start gap-2">
                <div className="font-MS font-semibold">Concepts</div>
                <div className="flex gap-2">
                  {childActivityData?.answerConcepts.map((conceptid, index) => (
                    <ConceptPil
                      key={`${conceptid}-${index}`}
                      conceptid={conceptid}
                      type="concept"
                    />
                  ))}
                </div>
              </div>
            )}
          {experimentGroup?.rp?.show_misconceptions &&
            childActivityData?.answerMisconceptions.filter((num) => num !== 0)
              .length > 0 && (
              <div className="flex flex-col gap-2">
                <div className="font-MS font-semibold">Misconceptions</div>
                <div className="flex gap-2">
                  {childActivityData?.answerMisconceptions.map(
                    (conceptid, index) => (
                      <ConceptPil
                        key={`${conceptid}-${index}`}
                        conceptid={conceptid}
                        type="misconception"
                      />
                    )
                  )}
                </div>
              </div>
            )}
        </div>
      </div>
    </>
  );
};

export default QuizResultContent;
