import QuestionFormat from "./QuestionFormat";

const ActiveQuiz = ({
  quizState,
  quizDispatch,
  handlePreviousQuestion,
  handleNextQuestion,
}) => {
  const handleOptionStyle = (index) => {
    return {
      borderLeft: quizState.selectedOptions.includes(index)
        ? "10px solid black"
        : "none",
      borderRight: quizState.selectedOptions.includes(index)
        ? "10px solid black"
        : "none",
    };
  };

  const handleOptionClick = (index) => {
    quizDispatch({ type: "updateSelectedOptions", options: [index] });
  };

  return (
    <div className="relative rounded">
      <QuestionFormat
        question={quizState.subQuestion}
        questionData={quizState.subQuestionData}
        handleOptionStyle={handleOptionStyle}
        handleOptionClick={handleOptionClick}
        enableClick
      />
      {/* Display the previous and next buttons */}
      <div className="flex justify-evenly">
        <div className="min-w-[200px]">
          {quizState.subQuestionIndex > 0 && (
            <div
              onClick={handlePreviousQuestion}
              className="p-4 w-fit bg-accent min-w-[100px] text-center shadow-default rounded-lg cursor-pointer hover:scale-110 duration-200 self-end"
            >
              PREVIOUS
            </div>
          )}
        </div>
        <div className="min-w-[200px]">
          {quizState.selectedOptions?.length > 0 && (
            <div
              onClick={handleNextQuestion}
              className="p-4 w-fit bg-accent min-w-[100px] text-center shadow-default rounded-lg cursor-pointer hover:scale-110 duration-200 self-end"
            >
              NEXT
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ActiveQuiz;
