import { AnimatePresence, motion } from "framer-motion";
import { BiGroup, BiUser } from "react-icons/bi";
import ReactTooltip from "react-tooltip";
import { colors } from "./../../config/colors";

const HorizontalBar = ({
  progress,
  showDetails = false,
  showBar = false,
  number = undefined,
  icon = "person",
  bgColor = colors.bar_average,
  toolTipTitle = "",
}) => {
  // attribute [icon] should be "person" or "group" otherwise throw an error
  if (icon !== "person" && icon !== "group")
    return console.error("HorizontalBar - icon prop is invalid");

    return (
      <div className="w-full bg-bar_class rounded text-white h-[14px]" style={{backgroundColor: 'white', height: "30px", borderRadius: '5px'}}>
        <motion.div
          className="w-full bg-bar_class rounded text-white h-[14px]"
          initial={{
            width: showBar ? `${Math.min(progress * 100, 100) || 0}%` : 0,
            backgroundColor: bgColor,
          }}
          animate={{
            width: showBar ? `${Math.min(progress * 100, 100) || 0}%` : 0,
          }}
          exit={{ width: 0 }}
          transition={{ type: "ease" }}
          data-tip={toolTipTitle}
          data-for="horizontal-bar-tooltip"
          style={{height: "30px", backgroundColor: 'white', width: '100%', borderRadius: '5px'}}
        >
          {/* <ReactTooltip
            id="horizontal-bar-tooltip"
            delayShow={400}
            effect="solid"
          /> */}
          <AnimatePresence exitBeforeEnter>
            {/* Check whether the icon and number should be displayed or not */}
            {showDetails && (
              <motion.div
                className="flex items-center justify-between h-full"
                initial={{ opacity: 0 }}
                animate={{ opacity: showDetails ? 1 : 0 }}
                exit={{ opacity: 0 }}
                style={{width: "100%", textAlign: "center"}}
              >
                {/* Icon */}
  
                {/* Number */}
                <span
                  className="pr-1 text-sm text-txt_secondary"
                  style={{ opacity: showDetails ? 1 : 0 , color: 'black', fontSize: "25px", width: '100%', textAlign: "center"}}
                >
                  {number}%
                </span>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      </div>
    );
};

export default HorizontalBar;
