import { useEffect, useState } from "react";

const OpenTabScreen = () => {
  return (
    <div role="status" className="p-4">
      <svg fill="#000000" height="200" width="200" viewBox="0 0 24 24" className="animate-pulse fill-info mr-4 text-card_dark"   xmlns="http://www.w3.org/2000/svg"><path d="M10 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V14M12 12L20 4M20 4V9M20 4H15" stroke="black" /></svg>
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export default OpenTabScreen;
