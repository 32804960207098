import { NavLink } from "react-router-dom";
import { requireAsset } from "../../utils/asset";

//FIXME: Course images should come from a db variable, not assets
const SlCourseCard = ({ course, linkPostfix = "" }) => {
  return (
    <NavLink
      to={`/course/${course.courseid + linkPostfix}`}
      className="texct-gray-500 hover:bg-gray-100 w-fit rounded place-self-center"
    >
      <div className="hover:scale-105 duration-200 flex flex-col min-w-[200px] shadow-heavy rounded p-4 bg-card">
        <div
          className="min-w-[200px] h-[200px] rounded object-cover "
          style={{
            backgroundImage: `url(${requireAsset(
              `courseimages/${course.courseid}.jpg`
            )})`,
            backgroundSize: "cover",
          }}
        />
        <div className="p-4 font-MS">
          <div className="text-lg font-semibold">{course.coursename}</div>
          <div className="text-txt_primary_light">{`Period ${course.period} - ${course.courseyear}`}</div>
        </div>
      </div>
    </NavLink>
  );
};

export default SlCourseCard;
