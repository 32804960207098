import { Table, TableBody, TableCell, TableHead, TableRow, Typography, Tooltip } from "@material-ui/core";
import BoldTableCell from "../../../general/table/BoldTableCell";

const TeacherPortalStudentQuizResultsInformationTab = ({ student, quizesOfStudent }) => {

    if (quizesOfStudent.length === 0) {
        return <Typography>This student has not taken any quizes yet.</Typography>
    }
    else {
        return <Table>
            <TableHead>
                <TableRow>
                    <BoldTableCell>
                        Quiz
                    </BoldTableCell>
                    <BoldTableCell>
                        Score
                    </BoldTableCell>
                    <BoldTableCell>
                        Max Score
                    </BoldTableCell>
                    <BoldTableCell>
                        Percentage
                    </BoldTableCell>
                    <BoldTableCell>
                        Started at
                    </BoldTableCell>
                    <BoldTableCell>
                        Time taken
                    </BoldTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {quizesOfStudent.map((quiz) => {
                    const startTime = new Date(quiz.minTimestamp)
                    const endTime = quiz.maxTimestamp ? new Date(quiz.maxTimestamp) : undefined
                    const timeDifferenceInSeconds = endTime ? (endTime.getTime() - startTime.getTime()) / 1000 : undefined
                    return <TableRow>
                        <TableCell>
                            {quiz.title}
                        </TableCell>
                        <TableCell>
                            {quiz.score}
                        </TableCell>
                        <TableCell>
                            {quiz.maxScore}
                        </TableCell>
                        <TableCell>
                            {Math.round(quiz.score / quiz.maxScore * 90 + 10) / 10}
                        </TableCell>
                        <TableCell>
                            {startTime.toLocaleString()}
                        </TableCell>
                        <TableCell>
                            <Tooltip title={endTime ? "Finished quiz at " + endTime.toLocaleString() : "User never finished quiz."}>
                                <Typography>
                                    {
                                        timeDifferenceInSeconds === undefined ? "Did not finish" :
                                            Math.floor(timeDifferenceInSeconds / 3600) + "hrs " + Math.floor(timeDifferenceInSeconds % 3600 / 60) + "m " + Math.floor(timeDifferenceInSeconds % 60) + "s"
                                    }
                                </Typography>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table>
    }
}


export default TeacherPortalStudentQuizResultsInformationTab