import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/auth/AuthContext';
import { useEffect } from 'react';

const ForcedLogout = () => {
  const navigate = useNavigate();
  // const timeoutLogout = useAuth();
  const { timeoutLogout } = useAuth();


  useEffect(() => {
    timeoutLogout();
  }, []);
  

  const handleLogout = () => {
    navigate('/login');
  };
  return (
    <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg text-center">
        <h1 className="text-2xl font-semibold mb-4">You have been logged out due to inactivity.</h1>
        <button
          onClick={handleLogout}
          className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-300"
        >
          Go to Login
        </button>
      </div>
    </div>
  );
};

export default ForcedLogout;
