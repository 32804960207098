import { Link } from "react-router-dom";
import { BsCheckLg } from "react-icons/bs";
import { ImCheckmark } from "react-icons/im";
import { colors } from "../../config/colors";

// TODO: We can show an icon if a homework activity has been started/seen by the user
const MaterialCard = ({ material, icon }) => {
  return (
    <Link to={`${material.activityid}`} className="cursor-pointer">
      <div
        className="bg-bar_score relative hover:scale-110 duration-200 p-1 rounded"
        data-tip={material.activitytitle}
      >
        {material.completed && (
          <div className="absolute opacity-60 content-end" style={{ bottom: 0, right: 0 }}>
            <ImCheckmark size={20} color={colors.bar_empty_light} />
          </div>
        )}
        {icon && <div>{icon}</div>}
      </div>
    </Link>
  );
};

export default MaterialCard;
