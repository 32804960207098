import { useEffect } from "react";
import { useState } from "react";

const OnboardingQuestion = (props) => {
  const question = props.question.datajson;
  const [answer, setAnswer] = useState(
    question.type == "scale" || question.type == "scale_nonlikert" ? Math.ceil((question.scale_max - question.scale_min + 1) / 2) : question.type == "consent" ? true : null
  );

  useEffect(() => {
    handleChange(answer);
  }, []);

  const handleChange = (answer) => {
    setAnswer(answer);
    if (props.onChange != null) {
      props.onChange(answer);
    }
  };

  return (
    <div className="flex flex-col grow bg-card">
      <div className="flex flex-row grow sm:mx-12 sm:mt-12">
        <div className="w-full max-w-6xl mx-auto bg-background sm:border-2 border-b-0 border-background_fade flex flex-col justify-center p-8">
          {question.type == "consent" && (
            <span className="text-md mb-12 text-left">
              <div dangerouslySetInnerHTML={{ __html: question.html }} />
            </span>
          )}
          {(question.type == "scale" || question.type == "scale_nonlikert") && (
            <span className="text-center text-4xl mb-12">“{question.question}”</span>
          )}

          <div className="mx-8">
            {question.type == "consent" && (
              <div className="mx-auto">
                <input
                  type="checkbox"
                  id="consentbox"
                  name="consentbox"
                  // onClick={(e) => handleChange(e.target.value)}
                  onChange ={(e) => handleChange(e.target.checked )}
                  defaultChecked
                />{" "}
                <label htmlFor="consentbox">I have read the information and I consent to participate in the research.</label>
              </div>
            )}
            {question.type == "scale" && (
              <div className="mx-auto max-w-[28rem]">
                <input
                  type="range"
                  min={question.scale_min}
                  max={question.scale_max}
                  value={answer}
                  onChange={(e) => handleChange(e.target.value)}
                  className="w-full h-2 bg-background_fade rounded-lg appearance-none cursor-pointer dark:card accent-accent"
                />
                <div className="flex">
                  <div className="text-sm">Strongly disagree</div>
                  <div className="text-sm ml-auto">Strongly agree</div>
                </div>
              </div>
            )}
            {question.type == "scale_nonlikert" && (
              <div className="mx-auto max-w-[28rem]">
                <input
                  type="range"
                  min={question.scale_min}
                  max={question.scale_max}
                  value={answer}
                  onChange={(e) => handleChange(e.target.value)}
                  className="w-full h-2 bg-background_fade rounded-lg appearance-none cursor-pointer dark:card accent-accent"
                />
                <div className="flex">
                  <div className="text-sm">{question.scale_min}</div>
                  <div className="text-sm ml-auto">{question.scale_max}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingQuestion;
