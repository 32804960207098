import { Box, Grid } from "@material-ui/core";
import { useState } from "react";
import { colors } from "../../../config/colors";

// FIXME: Multiple answer questions

const TeacherPortalQuizQuestionSelector = ({ questions, functionToCallOnClickWithIndex }) => {

    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0)

    return <>
        <Grid container align="left" spacing={2} >
            {questions.map((question, index) => {
                return <Grid item xs={1}>
                    <Box
                        className="hover:scale-110"
                        border={index == selectedQuestionIndex ? "2px #4f4f4f solid" : "1px #8f8f8f solid"}
                        sx={{
                            width: "100%",
                            height: "100%",
                            backgroundColor: colors.error

                        }}
                        onClick={() => {
                            setSelectedQuestionIndex(index)
                            functionToCallOnClickWithIndex(index)
                        }
                        }
                        color="black">
                        <Box sx={{ backgroundColor: colors.success, width: Math.round((question.statistics.scores_total.achieved / question.statistics.scores_total.maximum_achievable) * 100) + "%", height: "100%" }}></Box>
                        <Box align="center" sx={{ width: "100%", position: "relative", top: "-100%", zIndex: 1 }}>{index + 1}</Box>

                    </Box>
                </Grid>
            })
            }
        </Grid>
    </>
};

export default TeacherPortalQuizQuestionSelector