import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useCourse } from "../../contexts/CourseProvider";
import { Link } from "react-router-dom";
import Dashboard from "./dashboard/Dashboard";

const CourseHeader = () => {
  const params = useParams();
  const { course, showDashboard } = useCourse();
 
  return (
    <div className="w-full flex flex-row bg-gray-100 gap-2 p-4 shadow-md mx-auto">
      
      <div className="flex w-2/5">
        <Link to={`/course/${course.courseid}`}>
          <div className="text-3xl font-MS">{course?.coursename}</div>
        </Link>
      </div>
            
      {(showDashboard && showDashboard=="stats" && <Dashboard courseid={course?.courseid} />)}

    </div>
  );
};

export default CourseHeader;
