import Grid from "@material-ui/core/Grid";
import { Card, CardContent, CardHeader, Divider, Table, TableBody, TableHead, TableRow } from "@material-ui/core";

import * as React from "react";
import TeacherPortalStudentRow from "./student/StudentRow";
import SortingBoldTableCell from "../general/table/SortingBoldTableCell";
import TeacherPortalSearchBar from "../general/search_bar/SearchBar";

const TeacherPortalStudents = ({ students, quizesInformation, activities, activitiesWithUsageData }) => {

    const [sortBy, setSortBy] = React.useState("name");
    const [order, setOrder] = React.useState("asc");
    const [studentsProcessed, setStudentsProcessed] = React.useState([]);
    const [studentsSorted, setStudentsSorted] = React.useState([]);
    const [quizesOfEachStudent, setQuizesOfEachStudent] = React.useState({});
    const [searchQuery, setSearchQuery] = React.useState("");


    function processStudents() {
        // Go over all students and calculate last active timestamp, sum of events on learning materals
        const studentsLocal = students.map((student) => {
            const lastLearningMaterialInteractionMoment = student.use_data.reduce((acc, use_data) => {
                const endedTimestamp = new Date(use_data.max_timestamp).getTime()
                if (endedTimestamp > acc) {
                    return endedTimestamp
                }
                return acc
            }, 0)

            const sumOfLearningMaterialInteractionEventCounts = student.use_data.reduce((acc, use_data) => {
                return acc + use_data.count_events
            }, 0)
            const distinctLearningMaterialsUsed = student.use_data.reduce((acc, use_data) => {
                if (use_data.activityid in acc) {
                    return acc
                }
                acc[use_data.activityid] = true
                return acc
            }, {})
            const amountOfLearningMaterialsUsed = Object.keys(distinctLearningMaterialsUsed).length

            return {
                ...student,
                lastLearningMaterialInteractionMoment: lastLearningMaterialInteractionMoment,
                sumOfLearningMaterialInteractionEventCounts: sumOfLearningMaterialInteractionEventCounts,
                amountOfLearningMaterialsUsed: amountOfLearningMaterialsUsed
            }
        })

        setStudentsProcessed(studentsLocal)

    }
    function processQuizesOfStudents() {
        // Go over quizes and add the details of student's entry on that quiz to a dict with keys of the student id for later access. 
        var quizesOfEachStudentLocal = {}

        quizesInformation.map((quiz) => {
            quiz.user_activities.map((user_activity) => {
                const userId = user_activity.userid
                const amountOfQuestions = quiz.questions.length
                if (!(userId in quizesOfEachStudentLocal)) {
                    quizesOfEachStudentLocal[userId] = {}
                    quizesOfEachStudentLocal[userId].quizes = []
                }

                quizesOfEachStudentLocal[userId].quizes.push({
                    title: quiz.activitytitle,
                    questions: quiz.questions,
                    score: user_activity.score,
                    maxScore: user_activity.maxscore,
                    minTimestamp: user_activity.userdata[0].startedTimestamp,
                    maxTimestamp: user_activity.userdata[amountOfQuestions - 1] ? user_activity.userdata[amountOfQuestions - 1].endedTimestamp : undefined,
                })
                return false;
            })
            return false;
        }
        )

        Object.keys(quizesOfEachStudentLocal).map((userId) => {
            const quizesOfStudent = quizesOfEachStudentLocal[userId]
            const averageOfQuizes = quizesOfStudent.quizes.reduce((acc, quiz) => acc + quiz.score / quiz.maxScore, 0) / quizesOfStudent.quizes.length
            quizesOfEachStudentLocal[userId] = {
                ...quizesOfEachStudentLocal[userId],
                averageScore: averageOfQuizes
            }
            return false;
        })
        setQuizesOfEachStudent(quizesOfEachStudentLocal)
    }

    React.useEffect(() => {
        processStudents()
        processQuizesOfStudents()
    }, [students])

    React.useEffect(() => {
        sortStudents()

    }, [sortBy, order, quizesOfEachStudent, studentsProcessed, studentsSorted])


    function sortStudents() {

        const studentsSortedLocal = studentsProcessed.sort((a, b) => {
            var aValue
            var bValue
            if (sortBy === "name") {
                aValue = (a.user.firstname + a.user.lastname).toLowerCase();
                bValue = (b.user.firstname + b.user.lastname).toLowerCase();;
            }
            else if (sortBy === "averageScore") {
                aValue = quizesOfEachStudent[a.user.userid] ? quizesOfEachStudent[a.user.userid].averageScore : 0
                bValue = quizesOfEachStudent[b.user.userid] ? quizesOfEachStudent[b.user.userid].averageScore : 0
            }
            else if (sortBy === "lastLearningMaterialInteraction") {
                aValue = a.lastLearningMaterialInteractionMoment
                bValue = b.lastLearningMaterialInteractionMoment
            }
            else if (sortBy === "amountOfLearningMaterialsUsed") {
                aValue = a.amountOfLearningMaterialsUsed
                bValue = b.amountOfLearningMaterialsUsed
            }
            else if (sortBy === "sumOfLearningMaterialInteractionEventCounts") {
                aValue = a.sumOfLearningMaterialInteractionEventCounts
                bValue = b.sumOfLearningMaterialInteractionEventCounts
            }

            if (aValue < bValue) {
                return order == "asc" ? -1 : 1;
            }
            if (aValue > bValue) {
                return order == "asc" ? 1 : -1;
            }
            return 0;
        })
        return studentsSortedLocal
    }

    return <>
        <Grid container spacing={4}>
            <Grid item xs={12} align="left" >
                <Card >
                    <Grid container>
                        <Grid item xs={6}>
                            <CardHeader title="Students" />
                        </Grid>
                        <Grid item xs={6} align="right">
                            <TeacherPortalSearchBar
                                name="Search students"
                                setClicked={(query) => setSearchQuery(query)}
                            />
                        </Grid>
                    </Grid>

                    <Divider />
                    <CardContent align="center">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <SortingBoldTableCell
                                        active={sortBy}
                                        setDirection={setOrder}
                                        setActive={setSortBy}
                                        name="name"
                                        direction={order}
                                    >
                                        Name
                                    </SortingBoldTableCell>
                                    <SortingBoldTableCell
                                        active={sortBy}
                                        setDirection={setOrder}
                                        setActive={setSortBy}
                                        direction={order}
                                        name="averageScore"
                                    >
                                        Average quiz grade
                                    </SortingBoldTableCell>
                                    <SortingBoldTableCell
                                        active={sortBy}
                                        setDirection={setOrder}
                                        setActive={setSortBy}
                                        direction={order}
                                        name="lastLearningMaterialInteraction"
                                    >
                                        Last active
                                    </SortingBoldTableCell>
                                    <SortingBoldTableCell
                                        active={sortBy}
                                        setDirection={setOrder}
                                        setActive={setSortBy}
                                        direction={order}
                                        name="amountOfLearningMaterialsUsed"
                                    >
                                        Learning materials used
                                    </SortingBoldTableCell>
                                    <SortingBoldTableCell
                                        active={sortBy}
                                        setDirection={setOrder}
                                        setActive={setSortBy}
                                        direction={order}
                                        name="sumOfLearningMaterialInteractionEventCounts"
                                    >
                                        Amount of events
                                    </SortingBoldTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {sortStudents().filter(
                                    (student) => {
                                        if (searchQuery === "") {
                                            return true
                                        }
                                        else {
                                            return (student.user.firstname + " " + student.user.lastname).toLowerCase().includes(searchQuery.toLowerCase())
                                        }
                                    }
                                )
                                    .map((student) => {
                                        return <TeacherPortalStudentRow student={student} activities={activities} quizesOfStudent={quizesOfEachStudent && student.user.userid in quizesOfEachStudent ? quizesOfEachStudent[student.user.userid] : {}}
                                            activitiesWithUsageData={activitiesWithUsageData} />
                                    }
                                    )}
                            </TableBody>
                        </Table>
                    </CardContent>

                </Card>
            </Grid>
        </Grid>
    </>
};

export default TeacherPortalStudents