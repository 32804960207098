import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useCourse } from "../../../contexts/CourseProvider";
import { getDisplayState, getMonthOfDate } from "../../../utils/date";
import { colors } from "../../../config/colors";

const LGInfoLB = ({ lg }) => {
  const navigate = useNavigate();

  // displayState can be past, active or future
  const displayState = getDisplayState(lg);

  const { activities } = useCourse();

  // Convert unix timestamp to javascript new Date()
  const dateactive = new Date(lg.lgdateactive * 1000);
  const displayDate = `${getMonthOfDate(dateactive)} ${dateactive.getDate()}`;

  // Find the first quiz activity
  const quizActivity = activities.find(
    (a) => a.activityid == lg.mainactivityid
  );
  const exerciseActivity = activities.find((a) => a.activityid == lg.exerciseactivityid);
  //const exerciseActivity = activities.find((a) => a.activityid == lg.exerciseactivityid);
  const extraProperties = lg.extraProps1;

  return (
    <div className="flex flex-col items-center gap-2 rounded px-4 justify-center px-2">
      <div
        className="font-MS text-txt_primary text-sm"
        style={{
          fontWeight: displayState === "active" ? "bold" : "default",
        }}
      >
        {displayDate}
      </div>

      {exerciseActivity && (
            <div
              onClick={() =>
                navigate(`${exerciseActivity.activityid}`, {
                  state: {},
                })
              }
              className="text-txt_secondary hover:scale-105 duration-200 cursor-pointer px-2 my-1 py-1 w-24 h-fit text-center rounded shadow-default text-sm truncate"
              style={{
                backgroundColor: colors.accent,
              }}
            >
              Practicum
            </div>
          )}
      {exerciseActivity == undefined && (
            <div 
              className="text-txt_secondary hover:scale-105 duration-200 cursor-pointer px-2 my-1 py-1 w-24 h-fit text-center rounded shadow-default text-sm truncate"
              style={{
                color: "grey"
              }}
            >
              Practicum
            </div>
          )}
    </div>
  );
};

export default LGInfoLB;
