import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from "@material-ui/core"
import { limitString } from "../../../utils/string";
import { activityTypeToName } from "../../../utils/teacher_portal/activityTypeToString";
import Icon from "../../general/Icon";
import { capitalizeFirstLetter } from "../../../utils/string";
import BoldTableCell from "../general/table/BoldTableCell";

const TeacherPortalLatestActivities = (params) => {
    const { userEvents, loadMoreUserEvents } = params;

    const handleLoadMore = () => {
        loadMoreUserEvents()
    }

    return <>
        <Table padding="none">
            <TableHead>
                <TableRow>
                    <BoldTableCell>Activity Type</BoldTableCell>
                    <BoldTableCell>Activity Name</BoldTableCell>
                    <BoldTableCell>Username</BoldTableCell>
                    <BoldTableCell>Action</BoldTableCell>
                    <BoldTableCell>Time</BoldTableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                {userEvents && userEvents.map((userEvent) => {
                    return <TableRow>
                        <TableCell>
                            <Tooltip title={activityTypeToName(userEvent.activitytype)}>
                                <Box>
                                    <Icon type={userEvent.activitytype} color="black" />
                                </Box>
                            </Tooltip>
                        </TableCell>
                        <TableCell>
                            <Tooltip title={userEvent.activitytitle}>
                                <Typography>
                                    {limitString(userEvent.activitytitle, 40)}
                                </Typography>
                            </Tooltip>
                        </TableCell>
                        <TableCell>
                            <Tooltip title={"Username: " + userEvent.username}>
                                <Typography>
                                    {userEvent.firstname + " " + userEvent.lastname}
                                </Typography>
                            </Tooltip>
                        </TableCell>
                        <TableCell>
                            <Typography>
                                {capitalizeFirstLetter(userEvent.verb)}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography>
                                {userEvent.timestamp}
                            </Typography>
                        </TableCell>


                    </TableRow>
                })}
            </TableBody>
        </Table>
        <Box sx={{ marginTop: "10px" }}>
            <Button onClick={handleLoadMore}>
                Load more...
            </Button>
        </Box>
    </>
}

export default TeacherPortalLatestActivities