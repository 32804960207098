import { TableCell, TableRow } from "@material-ui/core";

import * as React from "react";
import TeacherPortalStudentModal from "./StudentModal";
const TeacherPortalStudentRow = ({ student, quizesOfStudent, activities, activitiesWithUsageData }) => {
    const [showingModal, setShowingModal] = React.useState(false);
    return <>
        {
            showingModal &&
            <TeacherPortalStudentModal student={student} activities={activities} quizesOfStudent={quizesOfStudent.quizes ? quizesOfStudent.quizes : []} onClose={() => setShowingModal(false)} activitiesWithUsageData={activitiesWithUsageData} />
        }
        <TableRow
            hover
            style={{
                cursor: "pointer",
            }}
            onClick={() => setShowingModal(true)}

        >
            <TableCell>
                {student.user.firstname + " " + student.user.lastname}
            </TableCell>
            <TableCell>
                {quizesOfStudent.averageScore ? Math.round(((quizesOfStudent.averageScore * 9 + 1) * 10)) / 10 : "No quizes made"}
            </TableCell>
            <TableCell>
                {new Date(student.lastLearningMaterialInteractionMoment).toDateString()}
            </TableCell>
            <TableCell>
                {student.amountOfLearningMaterialsUsed}
            </TableCell>
            <TableCell>
                {student.sumOfLearningMaterialInteractionEventCounts}
            </TableCell>
        </TableRow>
    </>
}


export default TeacherPortalStudentRow