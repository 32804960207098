import { motion } from "framer-motion";

const ConceptCardDisabled = ({ concept }) => {
  return (
    <motion.div className="shadow-default text-txt_primary relative bg-card_dark rounded min-w-[240px] max-w-[240px] min-h-[60px] text-center">
      <div className="relative">
        <div className="p-3 truncate text-lg">{concept.name}</div>
      </div>
    </motion.div>
  );
};

export default ConceptCardDisabled;
