import { createContext, useContext, useEffect, useState } from "react";
import { getItem, setItem } from "../utils/persistanceStorage";
import userApi from "../api/user";
import { useLocation, useNavigate } from "react-router";
import { saveEvent } from "./../api/userevents";
import xapiverbs from "../config/xapiverbs";

const UserContext = createContext();

export function UserProvider({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [courses, setCourses] = useState([]);

  /**
   * On mount - Gets the user its courses and if needed sets localstorage
   */
  useEffect(() => {
    const locUserData = getItem("user-data");
    const locUserCourses = getItem("user-courses");

    if (locUserCourses) {
      setCourses(locUserCourses);
    }

    if (locUserData && !locUserCourses) {
      userApi.getCoursesForUser().then((res) => {
        setItem("user-courses", res?.data?.results || []);
        setCourses(res?.data?.results || []);
      });
    }
  }, []);

  /** Redirect the user to course page if the user only has one course */
  useEffect(() => {
    if ( courses.length > 0 && 2 > courses.length && location.pathname === "/home" ) {
      navigate(`/course/${courses[0].courseid}`);
    }}, [courses]);

  /** Finding a course with certain courseid */
  const getCourseFromId = (courseid) => {
    const locCourses = getItem("user-courses");
    return locCourses.find((course) => course.courseid === courseid);
  };

  useEffect(() => {
    saveEvent(-1, -1, xapiverbs.LOAD, { path: location.pathname });
  }, [location]);

  const value = { courses, getCourseFromId, };

  return <UserContext.Provider value={value}>{children} </UserContext.Provider>;
}

export function useUser() {
  return useContext(UserContext);
}
