import { Outlet } from "react-router";
import Loading from "../components/general/Loading";
import Grid from "@material-ui/core/Grid";
import { Box } from "@material-ui/core";
import TeacherPortalSidebarNavigation from "../components/teacher_portal/SidebarNavigation";
import { useTeacherPortalContext } from "../contexts/TeacherPortalProvider";
import React from "react";

const TeacherPortalCourseView = () => {
  const { course, activities, loadingInfo, loadingError, activitiesWithUsageDataGroupedByType, quizesInformation, activitiesWithUsageData, userEvents, loadMoreUserEvents, studentInformation } = useTeacherPortalContext();
  const [selectedPage, setSelectedPage] = React.useState(undefined);
  if (loadingInfo)
    return (
      <div className="p-4">
        <div>
          If the page is loading too long. Please try refreshing the page or log
          out and in again.
        </div>
        <div>
          <Loading />
        </div>
      </div>
    );

  if (loadingError) return <div className="p-4">{loadingError}</div>;

  return (
    <>
      <Grid container>
        <Grid item xs={2}>
          <TeacherPortalSidebarNavigation students={studentInformation} course={course} activities={activities} setSelectedPage={setSelectedPage} quizesInformation={quizesInformation} activitiesWithUsageData={activitiesWithUsageData} activitiesWithUsageDataGroupedByType={activitiesWithUsageDataGroupedByType} userEvents={userEvents} loadMoreUserEvents={loadMoreUserEvents} />

        </Grid>
        <Grid item xs={10}>
          <Box sx={{ backgroundColor: "#f2f2f2", minHeight: "92vh", height: "100%", padding: "20px" }}>
            <Grid container>
              <Grid item xs={12}>
                {selectedPage}

              </Grid>
            </Grid>
          </Box>

        </Grid>
      </Grid>
      <Outlet />
    </>
  );
};

export default TeacherPortalCourseView;
