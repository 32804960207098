// default xapi verbs

export default {
  // General activities
  LOGIN: "logged-in",
  LOGOUT: "logged-out",
  TIMEOUT: "timeout-logged-out",
  LOAD: "loaded",
  STARTED: "started", // any activity started
  INTERACTED: "interacted", // any activity interacted with
  OPENEND: "opened", // any view, page, or activity loaded
  COMPLETED: "completed", // any activity completed
  CLOSED: "closed",
  CHANGED: "changed", // changed an answer
  GOALCHANGED: "changedgoal",


  // Quiz activities
  ANSWERED: "answered", // any question answered
  SUBMITTED: "submitted", // any question/survey submitted
  SAVED: "saved",

  
  // MESSAGES
  DISMISSED: "messageDismissed",

  // MOREDETAILS
  OUTSIDECLICK: {"clickedOutside":"true"}


};

/*
  [TEAMS ]
  started > any activity started
  interacted > user's interaction with any item not covered by other verbs. e.g.
      KM -> log what user interacted with
      video activity -> if user clicks something to play/pause/jump time etc.
  opened > any view, page, or activity loaded
  answered > raise event after every single answer selection + NEXT/PREV
  completed > video 90%, clicked mark as read, 90% of pdf, quiz all questions done
  submitted > submission of a quiz/survey
 */
