import { progressOutOf10, scoreOutOf10 } from "../../../utils/scores";
import { colors } from "../../../config/colors";
import DoubleProgressBar from "./DoubleProgressBar";
import { cutOff } from "../../../utils/feedback";

const GoalSupportProgressBar = ({ scores, goalOrientation, hovering, toolTipTitle }) => {
  // const secondProgress = hideProgress ? 0 : ((1 - mainProgress) * addOnProgress) / 100 + mainProgress;
  
  const userScore = progressOutOf10(scores.userscore, scores.maxscore);
  const avg_score = progressOutOf10(scores.avg_score, scores.maxscore);
  
  switch (goalOrientation) {
    case "mastery-approach": {
      const barColor =
        userScore >= cutOff["mastery"]/10
          ? colors.grd_blue_light_1
          : userScore >= cutOff["pass"]/10
          ? colors.grd_blue_light_2
          : colors.grd_blue_light_3;
      return (
        <DoubleProgressBar
          mainProgress={userScore}
          // addOnProgress={1 - userScore}
          number={userScore * 10}
          icon={undefined}
          showBar={true}
          showDetails={hovering}
          bgColor={barColor}
          toolTipTitle={toolTipTitle}
          hideProgress={true}
          showAddOn={false}
        />
      );
    }
    case "mastery-avoidance": {
      const barColor =
        userScore >= cutOff["mastery"]/10 ? colors.grd_blue_light_1
        : userScore >= cutOff["pass"]/10 ? colors.grd_blue_light_2
        : colors.grd_blue_light_3;
      // const thisAddOnProgress = 0.6 - userScore;
      return (
        <DoubleProgressBar
          mainProgress={userScore}
          // addOnProgress={thisAddOnProgress}
          number={userScore * 10}
          icon={undefined}
          showBar={true}
          showDetails={hovering}
          bgColor={barColor}
          toolTipTitle={toolTipTitle}
          hideProgress={true}
          showAddOn={false}
        />
      );
    }
    case "performance-approach": {
      const scoreDiff = userScore - avg_score;
      const thisAddOnProgress = avg_score;
      return (
        <div>
          {/* <div className={scoreDiff >= 0 ? "relative top-1 z-10" : "relative top-1 z-0"}> */}
          <div className="relative top-2 z-10">
            <DoubleProgressBar
              mainProgress={userScore}
              // addOnProgress={avg_score > userScore ? avg_score : 0}
              number={userScore * 10}
              icon={undefined}
              showBar={true}
              showDetails={hovering}
              bgColor={colors.bar_score}
              toolTipTitle={toolTipTitle}
              hideProgress={true}
              showAddOn={false}
            />
          </div>
          <div className={"static z-0"}>
            <DoubleProgressBar
              mainProgress={avg_score}
              addOnProgress={avg_score > userScore ? thisAddOnProgress : 0}
              number={avg_score * 10}
              icon={undefined}
              showDetails={hovering}
              showBar={true}
              bgColor={colors.bar_average}
              toolTipTitle={toolTipTitle}
              hideProgress={true}
              showAddOn={true}
            />
          </div>
        </div>
      );
    }
    case "performance-avoidance": {
      const scoreDiff = userScore - avg_score;
      const thisAddOnProgress = avg_score; // avg_score > userScore ? avg_score : 0
      return (
        <div>
          <div className="relative top-2 z-10">
            <DoubleProgressBar
              mainProgress={userScore}
              addOnProgress={thisAddOnProgress}
              number={userScore * 10}
              icon={undefined}
              showBar={true}
              showDetails={hovering}
              bgColor={colors.bar_score}
              toolTipTitle={toolTipTitle}
              hideProgress={true}
              showAddOn={true}
            />
          </div>
          <div className="static z-0">
            <DoubleProgressBar
              mainProgress={avg_score}
              addOnProgress={avg_score > userScore ? thisAddOnProgress : 0}
              number={avg_score * 10}
              icon={undefined}
              showDetails={hovering}
              showBar={true}
              bgColor={colors.bar_average}
              toolTipTitle={toolTipTitle}
              hideProgress={true}
              showAddOn={true}
            />
          </div>
        </div>
      );
    }
    default: {
      //statements;
      break;
    }
  }
};

export default GoalSupportProgressBar;
