import { useState } from "react";
import { FaStar } from "react-icons/fa";
import ReactTooltip from "react-tooltip";

function StarInputs({ value, onChange }) {
  const [rating, setRating] = useState(null);
  const [hover, setHover] = useState(null);

  const handleStarClick = (selected) => {
    setRating(selected);
    onChange(selected);   
  };

  return (
    <div className="flex" data-tip="How well have you answered the question?">
      {[...Array(5)].map((star, index) => {
        const ratingValue = index + 1;
        return (
          <label
            key={ratingValue}
            className=" transition ease-in-out delay-150 hover:scale-110 hover:translate-y-1 duration-150"
          >
            <input
              className="hidden"
              type="radio"
              name="rating"
              value={ratingValue}
              onClick={() => handleStarClick(ratingValue)}
            />
            <FaStar
              className="star"
              size={30}
              color={ratingValue <= (hover || rating || value) ? "FFCD00" : "AFAFAF"}
              onMouseEnter={() => setHover(ratingValue)}
              onMouseLeave={() => setHover(null)}
            />
          </label>
        );
      })}
      <ReactTooltip delayShow={300} effect="solid" />
    </div>
  );

}

export default StarInputs;
