export const getUserScores = (quizInformation) => {
  const userScores = {};
  quizInformation.user_activities.map((user_activity) => {
    userScores[user_activity.userid] = user_activity.score;
    return false;
  });

  return userScores;
};

export const getDistributionByUsers = (quizInformation) => {
  const userScores = getUserScores(quizInformation);

  const distributionLocal = quizInformation.questions.concat([0]).map(() => []);
  for (const [key, value] of Object.entries(userScores)) {
    distributionLocal[value] += 1;
  }

  return distributionLocal.map((value, index) => {
    return { value: value, index: index };
  });
};
