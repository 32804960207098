import { getCurrUnixDate } from "../../../utils/date";

export const quizInitState = {
  // State of quiz
  hasStarted: false,
  hasEnded: false,
  isSubmitted: false,

  //   Subquestion data
  subQuestionIndex: null,
  subQuestion: null,
  subQuestionData: null,
  selectedOptions: [],

  //   Time related data
  quizStartedTime: null,
  subQuestionStartedTime: null,
};

/**
 * Define the various methods which can be applied upon the quiz state
 */
export function quizReducer(state, action) {
  switch (action.type) {
    // action: { type, childActivities }
    case "handleStart":
      return {
        ...state,
        hasStarted: true,
        subQuestion: action.childActivities[0],
        // subQuestionData: JSON.parse(action.childActivities[0].datajson),
        subQuestionData: action.childActivities[0].datajson,
        subQuestionIndex: 0,
        selectedOptions: [],
        quizStartedTime: getCurrUnixDate(),
        subQuestionStartedTime: getCurrUnixDate(),
      };
    // action: { type, selectedOptions, childActivities }
    case "handleNextQuestion":
      // Check whether the user has answered the final question

      if (state.subQuestionIndex + 1 < action.childActivities.length) {
        const newSubQuestion =
          action.childActivities[state.subQuestionIndex + 1];

        return {
          ...state,
          hasStarted: true,
          subQuestion: newSubQuestion,
          subQuestionIndex: state.subQuestionIndex + 1,
          subQuestionData: newSubQuestion
            ? newSubQuestion.datajson
            : // ? JSON.parse(newSubQuestion.datajson)
              null,
          selectedOptions: action.selectedOptions,
          subQuestionStartedTime: getCurrUnixDate(),
        };
      }
      return {
        ...state,
        hasEnded: true,
      };

    // action: { type, subQuestion, childActivities }
    case "handlePreviousQuestion":
      const newSubQuestion = action.childActivities[state.subQuestionIndex - 1];

      return {
        ...state,
        hasStarted: true,
        subQuestion: newSubQuestion,
        subQuestionData: newSubQuestion
          ? newSubQuestion.datajson
          // ? JSON.parse(newSubQuestion.datajson)
          : null,
        subQuestionIndex: state.subQuestionIndex - 1,
        selectedOptions: action.selectedOptions,
        subQuestionStartedTime: getCurrUnixDate(),
      };
    // action: { type, options }
    case "updateSelectedOptions":
      return {
        ...state,
        selectedOptions: action.options,
      };
    case "submitQuiz":
      return {
        ...state,
        isSubmitted: true,
      };
    default:
      return state;
  }
}
