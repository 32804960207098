import DOMPurify from "dompurify";
import { useState } from "react";
import ReactTooltip from "react-tooltip";

const MessageCard = ({ messagetext, onDismiss }) => {
  const [dismissed, setDismissed] = useState(false);

  const handleDismiss = () => {
    setDismissed(true);
    setTimeout(() => {
      onDismiss();
    }, 500); // Change the delay time according to your preference
  };

  return (
    <div
      className={`border border-gray-100 rounded-md p-4 shadow-sm mb-4 w-full flex justify-between items-center transition-all duration-500 ${
        dismissed ? "opacity-0 scale-0" : "opacity-100 scale-100"
      }`}
    >
      <div className="text-lg flex-grow w-11/12" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(messagetext) }}></div>
      {onDismiss && (
        <button
          className="bg-red-500 text-white rounded-full w-8 h-8 flex items-center justify-center hover:bg-red-600 focus:outline-none focus:ring focus:ring-red-400 ml-2"
          onClick={handleDismiss}
          data-tip="Dismiss"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M10 12.586l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 11 4.293 6.707a1 1 0 111.414-1.414L10 9.586l4.293-4.293a1 1 0 111.414 1.414L11.414 11l4.293 4.293a1 1 0 11-1.414 1.414L10 12.586z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        
      )}
      <ReactTooltip place="left" className="bg-opacity-50" />
    </div>
  );
};

export default MessageCard;
