import { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import Icon from "../../general/Icon";
import MaterialCard from "../MaterialCard";

const MaterialList = ({ materialList, filter_types=[] }) => {
  return (
    <div>
      <div className="flex flex-wrap gap-3 px-4 py-2">
        {materialList?.length > 0 &&
          materialList.map(
            (material) =>
              material && (
                <MaterialCard
                  key={material.activityid}
                  material={material}
                  icon={<Icon size={40} type={material.activitytype} />}
                />
              )
          )}
      </div>
      <ReactTooltip effect="solid" delayShow={400} />
    </div>
  );
};

export default MaterialList;
