import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useCourse } from "../../../contexts/CourseProvider";
import { getDisplayState, getMonthOfDate } from "../../../utils/date";
import { colors } from "./../../../config/colors";

const LGInfo = ({ lg }) => {
  const navigate = useNavigate();

  // displayState can be past, active or future
  const displayState = getDisplayState(lg);
  const { activities } = useCourse();

  // Convert unix timestamp to javascript new Date()
  const dateactive = new Date(lg.lgdateactive * 1000);
  const displayDate = `${getMonthOfDate(dateactive)} ${dateactive.getDate()}`;

  // Find the first quiz activity
  const quizActivity = activities.find((a) => a.activityid == lg.mainactivityid);

  const exerciseActivity = activities.find((a) => a.activityid == lg.exerciseactivityid);
  const extraProperties = lg.extraProps1;

  let allowMultipleQuizAttempts = false;
  if (quizActivity?.datajson){ 
    allowMultipleQuizAttempts = JSON.parse(quizActivity?.datajson).allowMultiple;
  }

  return (
    <div className="flex flex-col items-center gap-2 rounded px-4 justify-center px-2 min-w-[150px]">
      <div
        className="font-MS text-txt_primary text-sm"
        style={{
          fontWeight: displayState === "active" ? "bold" : "default",
        }}
      >
        {/* {displayDate} */}
        &nbsp;
      </div>

      {quizActivity && displayState !== "future" && (
        <>
          <div
            onClick={() => navigate(`${quizActivity.activityid}`)}
            className="text-txt_secondary hover:scale-105 duration-200 cursor-pointer px-2 my-1 py-1 w-fit h-fit rounded shadow-default text-sm truncate"
            style={{
              backgroundColor: quizActivity.completed ? colors.completed : colors.accent,
            }}
          >
            {quizActivity.completed ? "Quiz Results" : "Take the Quiz"}
          </div>
          {quizActivity.completed && allowMultipleQuizAttempts && (
            <div
              onClick={() =>
                navigate(`${quizActivity.activityid}`, {
                  state: { retake: true },
                })
              }
              className="text-txt_secondary hover:scale-105 duration-200 cursor-pointer px-2 my-1 py-1 w-fit h-fit rounded shadow-default text-sm truncate"
              style={{
                backgroundColor: colors.accent,
              }}
            >
              Retake Quiz
            </div>
          )}

          {exerciseActivity && (
            <div
              onClick={() =>
                navigate(`${exerciseActivity.activityid}`, {
                  state: {},
                })
              }
              className="text-txt_secondary hover:scale-105 duration-200 cursor-pointer px-2 my-1 py-1 w-24 h-fit text-center rounded shadow-default text-sm truncate"
              style={{
                backgroundColor: colors.accent,
              }}
            >
              Exercises
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default LGInfo;
