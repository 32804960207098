import { IconButton, InputBase, Paper } from "@mui/material";
import React from "react";
import { AiOutlineSearch } from "react-icons/ai";


function TeacherPortalSearchBar({ name, setSearchQuery, setClicked }) {
    const [searchQuery, setSearchQueryState] = React.useState("");
    return <Paper
        sx={{
            p: '10px 4px',
            margin: "4px 0px 0px 0px",
            display: 'flex',
            alignItems: 'center',
            width: "100%",
            borderRadius: "40px"
        }}
    >
        <IconButton type="button" sx={{ p: '10px' }} aria-label="search"
            onClick={() => {
                if (setClicked)
                    setClicked(searchQuery);
            }}

        >
            <AiOutlineSearch />
        </IconButton>
        <InputBase
            sx={{
                ml: 1,
                flex: 1
            }}
            placeholder={name}
            inputProps={{ 'aria-label': 'search' }}
            onChange={(e) => {
                setSearchQueryState(e.currentTarget.value);
                if (setSearchQuery)
                    setSearchQuery(e.currentTarget.value);
            }}
            onKeyPress={(e) => {
                if (e.key === 'Enter') {
                    if (setClicked)
                        setClicked(searchQuery);
                }
            }}

        />
    </Paper>
}


export default TeacherPortalSearchBar;