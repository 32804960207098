import { colors } from "../config/colors";
import { useCourse } from "../contexts/CourseProvider";
import { progressOutOf10 } from "../utils/scores";

const useKMAdaptations = (concept, hovering, isActive, activityProgress) => {
  const { experimentGroup, conceptScores, goalOrientation } = useCourse();
  /**
   * This function checks whether an item (e.g. personal bar, class details) should be shown
   * when the right state is active.
   * @param {string} key key for which the correct boolean value can be retrieved from conceptcard.js
   * @returns boolean
   */
  const ableToShowItem = (key) => {
    if (hovering) return experimentGroup.cc[`hover_${key}`];
    if (isActive) return experimentGroup.cc[`expanded_${key}`];
    return experimentGroup.cc[`default_${key}`];
  };

  /**
   * Social bars -- ConceptSocialBars.jsx
   * Check wether to show personal bar, personal bar details, class bar and class bar details
   */
  const show_personal_bar = ableToShowItem("show_personal_bar");
  const show_personal_bar_details = ableToShowItem("show_personal_bar_details");
  const show_class_bar = ableToShowItem("show_class_bar");
  const show_class_bar_details = ableToShowItem("show_class_bar_details");

  // Check if the class bar/details are ever going to be shown
  const display_personal_bar =
    experimentGroup.cc.default_show_personal_bar_details ||
    experimentGroup.cc.hover_show_personal_bar_details ||
    experimentGroup.cc.expanded_show_personal_bar_details ||
    experimentGroup.cc.default_show_personal_bar ||
    experimentGroup.cc.hover_show_personal_bar ||
    experimentGroup.cc.expanded_show_personal_bar;

  // Check if the class bar/details are ever going to be shown
  const display_class_bar =
    experimentGroup.cc.default_show_class_bar_details ||
    experimentGroup.cc.hover_show_class_bar_details ||
    experimentGroup.cc.expanded_show_class_bar_details ||
    experimentGroup.cc.default_show_class_bar ||
    experimentGroup.cc.hover_show_class_bar ||
    experimentGroup.cc.expanded_show_class_bar;

  const show_second_progress = experimentGroup.cc.show_second_progress;
  
  // Don't show learning activity progress in Python course
  const show_goal_support_bars = experimentGroup.cc.show_goal_support_bars;
  // experimentGroup.cc.dont_show_progress?experimentGroup.cc.dont_show_progress:false;
  
  /**
   * Border outline -- ConceptCard.jsx / ConceptCardExpanded.jsx
   * Return the outline object which needs to be shown
   */

  const negative_border = {
    outlineColor: "#ff6961",
    outlineWidth: 1,
    outlineStyle: "solid",
  };

  const positive_border = {
    outlineColor: "#8BE580",
    outlineWidth: 1,
    outlineStyle: "solid",
  };

  const conceptScore = conceptScores[concept.id];

  // Calculate the user score and the average score
  const user_score = progressOutOf10( conceptScore?.userscore, conceptScore?.maxscore );

  const total_user_score = ((1 - user_score) * activityProgress) / 100 + user_score;

  const avg_score = experimentGroup.cc.use_individual_score_border
    ? 0.6
    : progressOutOf10(conceptScore?.avg_score, conceptScore?.maxscore);

  // Determine the border outline based on the user score compared to the average score
  const show_negative_border =
    ableToShowItem("show_negative_border") && total_user_score < avg_score;

  const show_positive_border = ableToShowItem("show_positive_border") && total_user_score > avg_score;
  
  const useBarColor = () => {
    let mainBarColor = colors.bar_lightblue;

    switch(goalOrientation) { 

      case "mastery-approach": { 
        return colors.bar_lightblue;
      } 
      case "mastery-avoidance": { 
         //statements; 
         break; 
      } 
      case "performance-approach": { 
        break;
      }
      case "performance-avoidance": { 
        break;
      }
      default: { 
         //statements; 
         break; 
      } 
   } 
  
    return colors.bar_score;
  };

  let bar_color = useBarColor();

  let concept_border = show_positive_border ? positive_border : show_negative_border ? negative_border : null;

  if (user_score <= 0) { concept_border = null; }

  // Return all the needed values
  return {
    show_personal_bar,
    show_personal_bar_details,
    show_class_bar_details,
    show_class_bar,
    display_personal_bar,
    display_class_bar,
    concept_border,
    bar_color,
    show_goal_support_bars,
    show_second_progress,
  };
};

export default useKMAdaptations;
