import { createContext, useContext, useState } from "react";
import { isMobile } from "react-device-detect";
import authApi from "../../api/auth";
import xapiverbs from "../../config/xapiverbs";
import { getItem, setItem } from "../../utils/persistanceStorage";
import { saveEvent } from "./../../api/userevents";

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [loading, setLoading] = useState(false);

  const authRegister = async (userDetails) => {
    setLoading(true);
    const register_res = await authApi.register(userDetails);
    setLoading(false);

    if (register_res.status === 200) {
      setItem("user-data", register_res.data);
      return { ok: true };
    }

    return {
      ok: false,
    };
  };

  const isLoggedIn = () => {
    const stored_data = getItem("user-data");
    return (stored_data);
  };

  const authLogin = async (credentials) => {
    setLoading(true);
    const login_res = await authApi.login({ ...credentials, isMobile });
    setLoading(false);

    if (login_res.status === 200) {
      setItem("user-data", login_res.data);
      saveEvent(-1, -1, xapiverbs.LOGIN);
      return { ok: true };
    }

    return {
      ok: false,
    };
  };

  const authLogout = async () => {
    saveEvent(-1, -1, xapiverbs.LOGOUT);
    await new Promise(resolve => setTimeout(resolve, 500));
    localStorage.clear();
  };

  const timeoutLogout = async () => {
    saveEvent(-1, -1, xapiverbs.TIMEOUT);
    await new Promise(resolve => setTimeout(resolve, 500));
    localStorage.clear();
  };

  const value = {
    authRegister,
    authLogin,
    authLogout,
    loading,
    setLoading,
    isLoggedIn,
    timeoutLogout,
  };

  return <AuthContext.Provider value={value}>{children} </AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}
