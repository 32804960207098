import { AiFillAudio, AiFillDashboard, AiFillQuestionCircle, AiFillVideoCamera } from "react-icons/ai";
import { BiArrowBack } from "react-icons/bi";
import { BsBarChartSteps, BsTrophyFill } from "react-icons/bs";
import { CgWebsite } from "react-icons/cg";
import { FaTableList } from "react-icons/fa6";
import { GiBookCover } from "react-icons/gi";
import { HiCursorClick } from "react-icons/hi";
import { HiCodeBracketSquare } from "react-icons/hi2";
import { MdAnimation } from "react-icons/md";
import { RiPagesFill, RiQuestionnaireFill } from "react-icons/ri";
import { colors } from "../../config/colors";


const Icon = ({ type, ...otherProps }) => {
  if (type === "learning_material_quiz")
    return (
      <RiQuestionnaireFill
        size={30}
        color={colors.icon_light}
        {...otherProps}
      />
    );

  if (type === "learning_material_video")
    return (
      <AiFillVideoCamera size={30} color={colors.icon_light} {...otherProps} />
    );
  if (type==="learning_material_readandanswer")    return (
    <FaTableList size={30} color={colors.icon_light} {...otherProps} />
  );

  if (type === "learning_material_book")
    return <GiBookCover size={30} color={colors.icon_light} {...otherProps} />;

  if (type === "learning_material_factsheet")
    return <RiPagesFill size={30} color={colors.icon_light} {...otherProps} />;

  if (type === "learning_material_audio")
    return <AiFillAudio size={30} color={colors.icon_light} {...otherProps} />;

  if (type === "learning_material_interactive")
    return (
      <HiCursorClick size={30} color={colors.icon_light} {...otherProps} />
    );

  if (type === "learning_material_paws_tracing")
    return (
      <HiCodeBracketSquare size={30} color={colors.icon_light} {...otherProps} />
    );
  if (type === "learning_material_paws_animated")
    return (
      <MdAnimation size={30} color={colors.icon_light} {...otherProps} />
    );
  if (type === "learning_material_paws_challenges")
    return (
      <BsTrophyFill size={30} color={colors.icon_light} {...otherProps} />
    );
  if (type === "learning_material_paws_parsons")
    return (
      <BsBarChartSteps size={30} color={colors.icon_light} {...otherProps} />
    );        
  if (type === "learning_material_paws_reading")
    return (
      <GiBookCover size={30} color={colors.icon_light} {...otherProps} />
    );        

  if (type === "learning_material_article" || type === "learning_material_notworkingarticle")
    return <CgWebsite size={30} color={colors.icon_light} {...otherProps} />;

  if (type === "learning_material_exercise")
  return <AiFillDashboard size={30} color={colors.icon_light} {...otherProps} />;

  if (type === "back_left") return <BiArrowBack {...otherProps} />;

  if (type === "quiz_question")
  return <AiFillQuestionCircle size={30} color={colors.icon_light} {...otherProps} />;

  console.error("The type of the icon was not recognized", type);

  return <div>icon</div>;
};

export default Icon;
