import { Card, Box, CardContent, CardHeader, Grid, Typography } from "@material-ui/core";
import TeacherPortalQuestionStatisticsOptions from "./QuestionStatisticsOptions";


const TeacherPortalQuestionStatistics = ({ selectedQuestion, selectedQuestionIndex }) => {

    return (<>
        <Card>
            <CardHeader title={"Question " + (selectedQuestionIndex + 1) + ": " + selectedQuestion.activitytitle} />
            <CardContent>
                <Box sx={{ marginBottom: 30 }}>
                    <Grid container>
                    <Grid item xs={8}>
                        <Typography dangerouslySetInnerHTML={{ __html: selectedQuestion.datajson.questionDescription }}>
                        </Typography>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    {(selectedQuestion.datajson.questionAttachment) ? 
                    <Grid item xs={3}>
                        <Box>
                            <img src={selectedQuestion.datajson.questionAttachment} alt="" />
                        </Box>
                    </Grid> : null}
                    </Grid>
                </Box>

                <TeacherPortalQuestionStatisticsOptions selectedQuestion={selectedQuestion} />
            </CardContent>
        </Card>
    </>)
};

export default TeacherPortalQuestionStatistics