import { PDFObject } from "react-pdfobject";
import { useActivity } from "../../../contexts/ActivityProvider";
import { requireAsset } from "./../../../utils/asset";
import FallBackLink from "./FallBackLink";

const PawsReading = () => {
  const { activityData } = useActivity();

  return (
    <div className="p-4">
      <PDFObject
        className="bg-blue-200"
        height="500px"
        url={activityData?.source === "studylens" ? requireAsset(activityData.url) : activityData.url + "?rs:embed=true"}
        forceIframe
      />
      <FallBackLink url={activityData?.source === "studylens" ? requireAsset(activityData.url) : activityData.url} />
    </div>
  );
};

export default PawsReading;
