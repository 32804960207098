import axios from "./axios";
import { getItem } from "../utils/persistanceStorage";

const getCoursesForUser = () => {
  return axios.get(
    `/get_courses_for_user`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const getUserActivityScores = (activityid) => {
  return axios.get(
    `/get_user_activity_scores`,
    { params: { activityid } },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const getOnboardingCompletionStatus = (courseid) => {

  return axios.get(
    `/get_onboarding_completion_status`,
    // postPawsContentLevels
    { params: { courseid } },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const setOnboardingCompleted = (courseid) => {
  return axios.post( `/set_onboarding_completion_status`,  { courseid }, {
      headers: { "Content-Type": "application/json", },
    }
  );
};

const getActivityCompletionStatus = (activityid) => {
  return axios.get(
    `/get_activity_completion_status`,
    { params: { activityid } },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

// const saveDashboardState = () => {
//   return axios.get(
//     `/`,
//     { params: { state_object } },
//     { headers: {
//         "Content-Type": "application/json",
//       },
//     }
//   );
// };

const incrementConceptScorePaws = (activityid) => {
  const usr = getItem("user-data")?.username;
  const svc = "SL24"
  const app = 1000
  const grp = ""
  const res = 1
  return axios.get(
    `/api/postPawsContentLevels`,
    { params: { activityid, usr, svc, app, grp, res } },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const getGoalOrientation = (courseid) => {
  return axios.get(
    `/goal_orientation`,
    { params: { courseid } },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const setGoalOrientation = (courseid, goalorientation) => {
  return axios.post(
    `/goal_orientation`,
    { courseid, goalorientation },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export default {
  getCoursesForUser,
  getUserActivityScores,
  getActivityCompletionStatus,
  getGoalOrientation,
  setGoalOrientation,
  incrementConceptScorePaws,
  getOnboardingCompletionStatus,
  setOnboardingCompleted,
};
