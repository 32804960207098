import { useState } from "react";
import { useActivity } from "../../../contexts/ActivityProvider";
import FlashPopup from "../../general/FlashPopup";
import LargeIcon from "../../general/LargeIcon";

const ReadingContent = ({onEvent}) => {
  const { activity, activityData } = useActivity();
  const [showPopup, setShowPopup] = useState(false);

  const clickCheck = () => {
    onEvent({action:"done"});
  };

  const clickSkip = () => {
    setShowPopup(true);
    onEvent({action:"skip"});
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const chapters = activityData.chapters.join(", ");
  const sections = activityData.sections.join(", ");

  return (
    <div className="grid grid-row">
      <div className="p-4 grid grid-cols-2 divide-x">
        <div className="pr-2">
          <div className="font-bold">What to read?</div>
          {chapters && <div>{`Chapter(s): ${chapters}`}</div>}
          {sections && <div>{`Sections(s): ${sections}`}</div>}
          <div>{activityData.description}</div>
        </div>
        <div className="relative pl-2">
          <img className="relative w-fit h-fit" src={activityData.url} alt="book cover" />
        </div>
      </div>


{/* 
      <div className="px-40 grid grid-cols-2 gap-4 center content-center content-between">
        <div>
          <LargeIcon type="check" onClick={() => clickCheck()} />
          Mark as Read
        </div>
        <div>
          <LargeIcon type="skip" onClick={() => clickSkip()} />
          Read Later
        </div>
      </div>
      {showPopup && (
        <FlashPopup image="path/to/your/image.png" message="Remember to mark as complete after you are done." time={1} onClose={handlePopupClose} />
      )} */}



      
    </div>
  );
};

export default ReadingContent;
