import { useNavigate, useLocation, useParams } from "react-router";
import { motion } from "framer-motion";
import { useActivity } from "../../../contexts/ActivityProvider";
import { saveEvent } from "../../../api/userevents";
import xapiverbs from "../../../config/xapiverbs";
import { useCourse } from "../../../contexts/CourseProvider";

//TODO: Use mintimecompleted to validate if the backend should be informed
const GoBackButton = ({ minTimeCompleted = true, timeSinceLoaded = 0, size = 16 }) => {
  const navigate = useNavigate();
  const params = useParams();
  const { updateConceptScores } = useCourse();
  const { activity, activityStatus, updateStoredActivityStatus, markPawsReadingAsCompleted } = useActivity();
  const currentLoadedUrl = useLocation().pathname;

  const handleGoBackButton = () => {
    saveEvent(activity?.activityid, params.courseid, xapiverbs.CLOSED, { secsSinceActivityLoaded: timeSinceLoaded });

    // If it is a quiz, we don't want to navigate away from the page
    if (
      activity.activitytype === "learning_material_quiz" &&
      !activityStatus.completed &&
      window.confirm("Are you sure you want to leave this page? You will lose your progress.")
    ) {
      navigate(-1);
    }

    // If it is a PAWS item, we will go to the knowledgemap
    // this solves the issue of PAWS items redirecting the users after each solution
    if (activity.activitytype.includes("learning_material_paws")) {
      if (activity.activitytype == "learning_material_paws_reading") {
        if (timeSinceLoaded > 4) {
          markPawsReadingAsCompleted(params.activityid);
        }
      }
      
      updateStoredActivityStatus(params.activityid);

      navigate(currentLoadedUrl.substring(0, currentLoadedUrl.lastIndexOf("/")), { replace: true });
    }

    // If it is not a quiz, we want to navigate away from the page
    if (activity.activitytype !== "learning_material_quiz" || activityStatus.completed) {
      navigate(-1);
    }
  };

  return (
    <motion.div onClick={handleGoBackButton} className="cursor-pointer p-2 rounded hover:bg-card_dark duration-200">
      <svg viewBox="0 0 32 32" focusable="false" height={size} width={size}>
        <path d="M18.1,16L27,7.1c0.6-0.6,0.6-1.5,0-2.1s-1.5-0.6-2.1,0L16,13.9l-8.9-9C6.5,4.3,5.6,4.3,5,4.9S4.4,6.4,5,7l8.9,8.9L5,24.8c-0.6,0.6-0.6,1.5,0,2.1c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4l8.9-8.9l8.9,8.9c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4c0.6-0.6,0.6-1.5,0-2.1L18.1,16z"></path>
      </svg>
    </motion.div>
  );
};

export default GoBackButton;
