export const _fullscreen = {
  name: "fullscreen",
  paddingLeft: "10px",
  paddingRight: "10px",
  paddingTop: "10px",
  paddingBottom: "10px",
};

export const _default = {
  name: "default",
  paddingLeft: "20vw",
  paddingRight: "20vw",
  paddingTop: "3rem",
  paddingBottom: "3rem",
};

export default {
  _fullscreen,
  _default,
};
