import { useUser } from "../../contexts/UserContext";
import SlCourseCard from "./SlCourseCard";

const SlAvailableCourses = ({ courses, linkPostfix = "" }) => {
  

  return (
    <div className="flex flex-wrap gap-4">
      {courses.map((course) => (
        <SlCourseCard key={course.courseid} course={course} linkPostfix={linkPostfix} />
      ))}
    </div>
  );
};

export default SlAvailableCourses;
