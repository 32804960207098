import { useState, useEffect, useRef } from "react";

export default function useOutsideClick(handleClick) {
  const ref = useRef(null);

  /**
   * Checks whether the user has clicked outside the component and triggers
   * the handleClick function.
   */
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      handleClick();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return { ref };
}
