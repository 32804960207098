import { useEffect, useRef, useState } from "react";
import { saveEvent } from "../../../api/userevents";
import xapiverbs from "../../../config/xapiverbs";
import { useCourse } from "../../../contexts/CourseProvider";
import useKMAdaptations from "./../../../hooks/useKMAdaptations";
import ConceptTileDisabled from "./ConceptTileDisabled";
import ConceptTileExpanded from "./ConceptTileExpanded";
import ReactTooltip from "react-tooltip";

/**
 * 
 * Concept Tile was created for Leaderboards to show larger cards on the left
 * @returns 
 */
const ConceptTile = ({ concept, lg, disabled = false, filterid }) => {
  const cardRef = useRef();
  const [hovering, setHovering] = useState(false);
  const [hoveringData, setHoveringDate] = useState(null);
  const [conact, setConstAct] = useState([]);
  const {
    course,
    getMisconceptionsWithActivities,
    conceptScores,
    activities,
    activeConcept,
    setActiveConcept,
    activeLearningGoal,
    setActiveLearningGoal,
    concepts
  } = useCourse();

  const isActive = concept.id == activeConcept?.id &&activeLearningGoal?.lgid === lg.lgid;
 
  let conceptActivities = concept.activity_ids.map((id) =>
    activities?.find((activity) => activity.activityid === id)
  );
  
  const related_activities = concepts.find((con) => con.id == filterid);

  const related_activity_ids = related_activities != undefined ? related_activities.activity_ids : [];

  const activityProgress =
     (conceptActivities.filter((act) => act.completed).length /
      conceptActivities.length) *
      100 || 0;

  const { concept_border } = useKMAdaptations(
    concept,
    hovering,
    isActive,
    activityProgress
  );

  // Handles the onClick event of the concept card
  const handleClick = () => {
    if (!isActive) {
      setActiveConcept(concept);
      setActiveLearningGoal(lg);
      setHovering(false);
      saveEvent(-1, course.courseid, xapiverbs.INTERACTED, {
        knowledgemap: true,
        clicked_concept_id: concept.id,
        learning_goal_id: lg.lgid,
      });
    }
  };

  // Handles the click event for the expanded concept card
  const handleExpandedClick = () => {
    setActiveConcept(null);
    setActiveLearningGoal(null);
  };

  // Handles the hover event for the concept card
  const handleHoverStart = () => {
    setHovering(true);
  };

  useEffect(() => {
    if (hovering) {
      setHoveringDate(Date.now());
    }
    if (!hovering) {
      // if hoveringdate is more than 1 second ago, then save event
      if (hoveringData && Date.now() - hoveringData > 500) {
        saveEvent(-1, course.courseid, xapiverbs.INTERACTED, {
          knowledgemap: true,
          hovered_concept_id: concept.id,
          learning_goal_id: lg.lgid,
        });
      }
      setHoveringDate(false);
    }
  }, [hovering]);

  if (disabled) return <div style={{width: "83%"}}><ConceptTileDisabled concept={concept} /></div>;

  return (
    <>
    <div style={{width: "100%", height: "100%"}}>
        {//isActive && 
        
        (
          <ConceptTileExpanded
            concept={concept}
            cardRef={cardRef?.current}
            conceptBorder={concept_border}
            handleClick={handleExpandedClick}
            activityProgress={activityProgress}
            filterid={related_activity_ids}
            conceptActivities={conceptActivities}
            lg={lg}
          />
        )}
        <ReactTooltip delayShow={400} effect="solid" />
    </div>

    </>
  );
};

export default ConceptTile;