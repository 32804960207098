import { Bar, BarChart, CartesianGrid, Legend, XAxis, YAxis, Tooltip, Label, Cell } from "recharts";

import React from 'react'
import { getUserScores } from "../../../../utils/teacher_portal/getUserDistributionOfQuiz";
import { Typography } from "@material-ui/core";
import { interlaceColorFromGreenToRed } from "../../../../utils/colors";
import { colors } from "../../../../config/colors";

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


const TeacherPortalQuizResultsChart = ({ quizesInformation, width, minHeight, maxHeight }) => {
    const [data, setData] = React.useState([]);
    const [showDetail, setShowDetail] = React.useState(false);
    const [zoomedInOn, setZoomedInOn] = React.useState(undefined);
    const [scoreRange, setScoreRange] = React.useState(undefined);

    // Prepare data for chart
    React.useEffect(() => {
        const quizesToShow = zoomedInOn !== undefined ? [quizesInformation[zoomedInOn]] : quizesInformation
        const data = quizesToShow.map((quiz, index) => {
            const scores = getUserScores(quiz)

            // Sort distribution by score
            const sorted_scores = Object.values(scores).sort((a, b) => { return a - b }).map((score) => { return score / quiz.questions.length })
            const twentyfiveth_percentile = sorted_scores[Math.round(sorted_scores.length * 0.25)] * 10
            const seventyfiveth_percentile = sorted_scores[Math.round(sorted_scores.length * 0.75)] * 10
            const median = sorted_scores[Math.round(sorted_scores.length * 0.5)] * 10
            const average = sorted_scores.reduce((a, b) => { return a + b }, 0) / sorted_scores.length * 10


            return { index: index, name: quiz.activitytitle, "twentyfiveth_percentile": Math.round(twentyfiveth_percentile * 9 + 1) / 10, "seventyfiveth_percentile": Math.round(seventyfiveth_percentile * 9 + 1) / 10, "median": Math.round(median * 9 + 1) / 10, "average": Math.round(average * 9 + 1) / 10 }
        });
        setData(data)
        setScoreRange({ min: Math.min(...data.map((d) => d.twentyfiveth_percentile)), max: Math.max(...data.map((d) => d.seventyfiveth_percentile)) })
    },
        [quizesInformation, zoomedInOn, showDetail]
    );


    // Handle clicks on charts/ bars etc.
    const clickOnBar = (eventargs) => {
        if (zoomedInOn !== undefined) {
            setZoomedInOn(undefined)
            setShowDetail(false)
        }
        else {
            setZoomedInOn(eventargs.index)
            setShowDetail(true)
        }
    }

    const clickOnChart = (eventargs) => {
        if (zoomedInOn !== undefined) {
            setZoomedInOn(undefined)
            setShowDetail(false)
        }
    }

    return (
        <>
            <Typography style={{ paddingTop: "35px", color: "grey", float: "right", marginTop: -60 }} >
                Click on a bar to zoom in/ out.
            </Typography>
            {/* <FormControlLabel control={<Checkbox checked={showDetail} onChange={(e) => setShowDetail(e.target.checked)} style={{ color: "rgb(255 205 0)" }} />} style={{ float: "right", marginTop: -60 }} label={"Show detail"} /> */}

            <BarChart data={data} width={width} height={Math.min(Math.max(minHeight, 100 * quizesInformation.length), maxHeight)} layout="vertical" margin={{ left: 50, bottom: 10, right: 20 }} onClick={clickOnChart}>

                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" domain={[1, 10]} ticks={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}>
                    <Label value="Grade" offset={-5} position="insideBottomRight" />
                </XAxis>
                <YAxis dataKey="name" type="category" />
                <Tooltip />
                <Legend verticalAlign="top" />
                {showDetail && <>
                    <Bar dataKey="twentyfiveth_percentile" unit="/10.0" name="25 percentile" fill="#ff6666" onClick={clickOnBar} style={{ cursor: "pointer" }} />
                    <Bar dataKey="median" unit="/10.0" name="Median" fill={colors.bar_score} onClick={clickOnBar} style={{ cursor: "pointer" }} />
                    <Bar dataKey="seventyfiveth_percentile" unit="/10.0" name="75 percentile" fill={colors.success} onClick={clickOnBar} style={{ cursor: "pointer" }} />
                </>
                }
                {!showDetail && <Bar dataKey="average" unit="/10.0" name="Average" fill={colors.error} onClick={clickOnBar} style={{ cursor: "pointer" }}>
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={interlaceColorFromGreenToRed((entry.average - scoreRange.min) / (scoreRange.max - scoreRange.min))} />
                    ))}
                </Bar>}


            </BarChart>
        </>
    )
}

export default TeacherPortalQuizResultsChart;