import { TableCell, TableSortLabel, Typography } from "@material-ui/core";
import * as React from "react";


const SortingBoldTableCell = ({ children, active, setActive, setDirection, direction, props, name }) => {

    const onClick = () => {
        if (active === name) {
            if (direction === 'asc') {
                setDirection('desc');
            } else {
                setDirection('asc');
            }
        }
        else {
            setActive(name);
        }
        setActive(name);
    }

    return (
        <TableCell>
            <TableSortLabel {...props} active={active === name} direction={direction} onClick={onClick}>
                <Typography><b>{children}</b></Typography>
            </TableSortLabel>
        </TableCell>
    )
}

export default SortingBoldTableCell