import moment from "moment";

const expiryInHours = 24 * 7; // one week

export const getItem = (key) => {
  try {
    const value = localStorage.getItem(key);
    const item = JSON.parse(value);

    if (!item) return null;

    if (isExpired(item)) {
      removeItem(key);
      return null;
    }

    return item.value;
  } catch (e) {
    console.error("Error in getting data from local storage ", e);
    return null;
  }
};

export const setItem = (key, value) => {
  try {
    const item = {
      value,
      timestamp: Date.now(),
    };

    localStorage.setItem(key, JSON.stringify(item));
  } catch (e) {
    console.error("Error in setting data in local storage ", e);
  }
};

export const removeItem = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.error("Error in removing data in local storage ", e);
  }
};

const isExpired = (item) => {
  const now = moment(Date.now());
  const storedTime = moment(item.timestamp);
  return now.diff(storedTime, "hours") > expiryInHours;
};
