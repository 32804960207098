import { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import Icon from "../../general/Icon";
import MaterialTile from "../MaterialTile";
import "./styletiles.css";

const MaterialListTiles = ({ materialList, filterid }) => {
  const chunkSize = 6;
  var chunk = [];
  for (let i = 0; i < materialList.length; i += chunkSize) {
    chunk.push(materialList.slice(i, i + chunkSize));
  }

  return (
    <div>
      <div className="flex gap-2 px-2 py-2" style={{ display: "block" }} key={Math.random()}>
        {materialList?.length > 0 &&
          chunk.map((material) => (
            <div style={{ display: "block" }} key={Math.random()}>
              {material.map(
                (materials) =>
                  materials && (
                    <div id="inlinetiles" key={Math.random()}>
                      <MaterialTile
                        material={materials}
                        filterid={filterid}
                        icon={<Icon size={40} type={materials.activitytype} />}
                      />
                    </div>
                  )
              )}
            </div>
          ))}
      </div>
      <ReactTooltip effect="solid" delayShow={400} />
    </div>
  );
};

export default MaterialListTiles;
