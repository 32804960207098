import { useEffect, useState } from "react";
import { useActivity } from "../../../contexts/ActivityProvider";
import CompleteActivityButton from "./CompleteActivityButton";
import FullScreenButton from "./FullScreenButton";
import GoBackButton from "./GoBackButton";

const ModalHeader = ({ minTimeSecs = 8 }) => {
  const { activity, loadingMainInfo } = useActivity();
  const [timeSinceLoad, setTimeSinceLoad] = useState(0);
  const [minTimeElapsed, setMinTimeElapsed] = useState(false);

  useEffect(() => {
    var timer = setInterval(() => {
      setTimeSinceLoad(timeSinceLoad + 1);
    }, 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  });

  useEffect(() => {
    if (timeSinceLoad > minTimeSecs) {
      setMinTimeElapsed(true);
    } 
  }, [timeSinceLoad]);

  return (
    <div className="px-4 py-2 sticky w-full h-[60px] flex border-b-2 border-card_dark justify-between items-center">
      <CompleteActivityButton active={minTimeElapsed} />
      <div className="text-center font-MS truncate mx-4 min-w-[100px]">{activity.activitytitle}</div>
      {/* <div className="h-[2px] bg-black">&nbsp;</div>       */}
      <div className="flex gap-4">
  
        <FullScreenButton />
        <GoBackButton minTimeCompleted={minTimeElapsed} timeSinceLoaded={timeSinceLoad} />
      </div>
    </div>
  );
};

export default ModalHeader;
