import { useState, useEffect } from "react";
import StarInputs from "./StarsInput";
import { useParams, useLocation } from "react-router";
import generalApi from "../../../api/general";
import { saveExercise } from "../../../api/postactivity";
import { saveEvent } from "../../../api/userevents";
import xapiverbs from "../../../config/xapiverbs";

function ExerciseQuestion({
  activityid,
  questionTitle,
  questionText,
  questionAttachment,
  attachmentCaption,
  isReadingOnly,
  answer,
  showAnswer,
}) {
  const [text, setText] = useState("");
  const [stars, setStars] = useState(0);
  const [numWords, setNumWords] = useState(0);
  const [saveColor, setSaveColor] = useState('lightgrey')
  const [saveText, setSaveText] = useState('Saved')
  const params = useParams();

  const updateFunction = () => {
    const activityAnswers = generalApi
      .getActivityAnswer(activityid, params.courseid)
      .then((activityAnswers) => {
        if (JSON.stringify(activityAnswers.data.results) === "{}") {
        } else {
          setText(activityAnswers.data.results.answer.text);
          setStars(activityAnswers.data.results.answer.stars);
          const count = activityAnswers.data.results.answer.text
            .replace(/-/g, " ")
            .trim()
            .split(/\s+/g).length;
          setNumWords(count);
        }
      });
  };

  useEffect(updateFunction, []); // Will be called when the component loads

  const handleStarClicked = (stars) => {
    setStars(stars);
    saveExercise({ activityid: activityid, stars: stars, text: text });
    saveEvent(activityid, params.courseid, xapiverbs.CHANGED, {"stars":stars});
  };

  const handleSaveClicked = () => {
    const processObject = { activityid: activityid, stars: stars, text: text };
    saveExercise(processObject)
      .then((res) => {
        if (res.data.success) {
          setTimeout(() => {}, 1000);
        } else {
          console.error("error", res.data);
        }
      })
      .catch((e) => console.error(e));
      saveEvent(activityid, params.courseid, xapiverbs.SAVED, {"stars":stars});
      setSaveColor("lightgrey")
      setSaveText("Saved")
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
    const count = text.replace(/-/g, " ").trim().split(/\s+/g).length;
    setNumWords(count);
    setSaveColor("#ffcd00");
    setSaveText("Save")
  };

  if (isReadingOnly) {
    return (
      <div className="container mx-auto w-max p-10">
        <h1 className="text-4xl font-normal leading-normal mt-0 mb-2 text-pink-800">
          {questionTitle}
        </h1>
        <div
          dangerouslySetInnerHTML={{
            __html: questionText,
          }}
        />
      </div>
    );
  }

  const answerContent = (
    <div className="w-11/12 px-6 py-2" dangerouslySetInnerHTML={{ __html: answer }} />
  );

  return (
    <div>
      <div className="min-w-screen flex items-center px-5 py-5">
        <div className="w-full max-w-6xl mx-auto rounded-xl bg-white shadow-lg text-gray-600 dark:text-gray-800 text-lg p-2">
          {questionTitle}
          <div dangerouslySetInnerHTML={{ __html: questionText }} />
          <br />
          <center>
            {questionAttachment ? (
              <img
                src={questionAttachment}
                alt="Question attachment"
                className="object-center"
              />
            ) : null}
            {attachmentCaption ? (
              <figcaption>{attachmentCaption}</figcaption>
            ) : null}
          </center>
          <br />
          <div className="overflow-hidden rounded-md">
            <textarea
              id="message"
              rows="4"
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Write your answer here..."
              defaultValue={text}
              onChange={handleTextChange}
            ></textarea>
            <div className="w-full flex justify-around border-b border-gray-200 text-xs text-gray-600 py-2">
              <button
                className="btn bg-accent hover:bg-amber-400 text-white font-bold px-4 rounded"
                onClick={handleSaveClicked}
                style={{
                  backgroundColor: saveColor
                }}
              >
                {saveText}
              </button>
              <div> {numWords} words</div>
              <StarInputs value={stars} onChange={handleStarClicked} />
            </div>
          </div>
            {showAnswer && answerContent}
        </div>
      </div>
    </div>
  );
}

export default ExerciseQuestion;
