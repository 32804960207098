import { Typography, Paper } from "@material-ui/core";
import TeacherPortalLearningMaterialUsageTimelineChart from "./LearningMaterialUsageTimeLineChart";

const TeacherPortalLearningMaterialUsage = ({ activityWithUsageData }) => {
    return <>
        <Paper style={{ padding: "8px" }}>
            <Typography variant="h6">Usage Timeline (of all users)</Typography>
            {activityWithUsageData.use_data.length > 0 ? <>
                {<TeacherPortalLearningMaterialUsageTimelineChart activityWithUsageData={activityWithUsageData} />}

            </> :
                <Typography variant="body2" align="left" >As this learning material has not been used yet, there is no timeline available.</Typography>
            }
        </Paper>
    </>
}

export default TeacherPortalLearningMaterialUsage;