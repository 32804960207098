import { BiExitFullscreen, BiFullscreen } from "react-icons/bi";
import { useActivity } from "../../../contexts/ActivityProvider";

const FullScreenButton = ({ size = 16 }) => {
  const { modalView, changeModalView } = useActivity();

  const handleClick = () => {
    if (modalView.name == "fullscreen") {
      changeModalView("default");
    } else {
      changeModalView("fullscreen");
    }
  };

  return (
    <div
      onClick={handleClick}
      className="cursor-pointer p-2 rounded hover:bg-card_dark duration-200"
    >
      {modalView.name == "fullscreen" ? (
        <BiExitFullscreen size={size} />
      ) : (
        <BiFullscreen size={size} />
      )}
    </div>
  );
};

export default FullScreenButton;
