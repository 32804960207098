import { AnimatePresence, motion } from "framer-motion";
import { BsFillPersonFill } from "react-icons/bs";
import { IoIosPeople } from "react-icons/io";
import { colors } from "./../../../config/colors";
import { useState, useEffect } from "react";

const DoubleProgressBar = ({
  mainProgress,
  addOnProgress,
  showDetails = false,
  showBar = false,
  number = undefined,
  icon = undefined,
  bgColor = colors.bar_score,
  bgEmpty = undefined, 
  gradient = false,
  toolTipTitle = "",
  toolTipTitleAddOn = "",
  addOnBgColor = "",
  suggestedHeight = { height: "16.99px" },
  showAddOn = false,
  displayNumber = false,
}) => {

  const { height = "16.99px" } = suggestedHeight;
  const secondProgress = !showAddOn ? 0 : ((1 - mainProgress) * addOnProgress) / 100 + mainProgress;
  const stripeBgColor = addOnBgColor === "" ? colors.bar_lightblue : addOnBgColor;
  const [dummystate, setDummyState] = useState(0);
  const gradientStyle = gradient
    ? {
        backgroundImage: `linear-gradient(left, ${colors.card_dark} -100px, ${bgColor} 300px)`,
        // eslint-disable-next-line no-dupe-keys
        backgroundImage: `-moz-linear-gradient(left, ${colors.card_dark} -100px, ${bgColor} 300px)`,
        // eslint-disable-next-line no-dupe-keys
        backgroundImage: `-webkit-linear-gradient(left, ${colors.card_dark} -100px, ${bgColor} 300px)`,
      }
    : null;

  const numberStyle = `text-sm ${gradient ? "text-icon_alt" : "text-txt_secondary"} drop-shadow z-30`;
  return (
    <div className="w-full flex items-center">
      {/* <div className="w-full z-1 absolute p-0 m-0 min-w-full h-4" style={{ backgroundColor: {bgEmpty} }}></div> */}
      {/* TODO: Empty bar background color  */}
      <motion.div
        className="w-full bg-bar_class rounded-r text-white h-[16.99px] relative z-0"
        style={{ ...gradientStyle, height: height, backgroundColor: bgColor }}
        initial={{ width: showBar ? `${Math.min((!showAddOn ? mainProgress : secondProgress) * 100, 100) || 0}%` : 0 }}
        animate={{ width: showBar ? `${Math.min((!showAddOn ? mainProgress : secondProgress) * 100, 100) || 0}%` : 0 }}
        transition={{ type: "ease" }}
      ></motion.div>

      {mainProgress > 0 && (
        <div
          className="absolute flex items-center justify-between px-1 gap-2 z-30"
          data-tip={toolTipTitle}
          style={{ width: `${Math.min(mainProgress * 100, 100)}%` }}
        >
          &nbsp;
          <div>
          &nbsp;
            {icon === "group" && (
              <IoIosPeople className="drop-shadow" color={gradient ? colors.icon_alt : colors.icon_light} size={1} />
            )}
            {icon === "person" && (
              <BsFillPersonFill className="drop-shadow" color={gradient ? colors.icon_alt : colors.icon_light} size={1} />
            )}
          </div>

          {displayNumber && (
            <AnimatePresence exitBeforeEnter>
              {showDetails && (
                <motion.span className={numberStyle} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                  {number}
                </motion.span>
              )}
            </AnimatePresence>
          )}
        </div>
      )}

      {showAddOn && addOnProgress > 0 && (
        <motion.div
          data-tip={toolTipTitleAddOn}
          className="h-[16.99px] w-[20px] rounded-r absolute left-0 z-1"
          initial={{ left: `${Math.min(mainProgress * 100, 100) || 0}%` }}
          style={{
            height: height,
            background: `repeating-linear-gradient(
              135deg,
              ${colors.bar_average} 0px,
              ${colors.bar_average} 5px,
              ${stripeBgColor} 5px,
              ${stripeBgColor} 10px              
            )`,
          }}
          animate={{
            width: showBar ? `${Math.min(secondProgress * 100, 100) - Math.min(mainProgress * 100, 100)}%` : 0,
          }}
          transition={{ type: "ease" }}
        />
      )}
    </div>
  );
};

export default DoubleProgressBar;