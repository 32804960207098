import axios from "./axios";

const getActivityDetails = (activityid, courseid) => {
  return axios.get(
    `/getactivity`,
    {
      params: { courseid, activityid },
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const getActivityAnswer = (activityid, courseid) => {
  return axios.get(
    `/getactivityanswer`,
    {
      params: { courseid, activityid },
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const getActivityFeedback = (activityid) => {
  return axios.get(`/getactivityfeedback`, { params: { activityid } }, { headers: { "Content-Type": "application/json" } });
};

const getMessage = async (msgtype, onlyactive, limit) => {
  return await axios.get(
    `/getmessage`,
    { params: { msgtype, limit, onlyactive } },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const setMessageInactive = async (messageid) => {
  const active = false;
  return await axios.get(
    `/updatemessage`,
    { params: { messageid, active } },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const sendHeartbeat = async () => {
  return await axios.post(
    `/heartbeat`,
    { params: { } },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export default {
  getActivityDetails,
  getActivityAnswer,
  getActivityFeedback,
  getMessage,
  setMessageInactive,
  sendHeartbeat,
};
