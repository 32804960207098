import { useState } from "react";
import { useActivity } from "../../../contexts/ActivityProvider";
import { useCourse } from "../../../contexts/CourseProvider";
import ReadAndAnswerActivity from "../readandanswer/ReadAndAnswerActivity";

const ExerciseContent = () => {
  const { activity, childActivities, markActivityAsCompleted } = useActivity();
  const [initialCompletion, setInitialCompletion] = useState(0);

  //TODO: Check if the activity is already completed. If it is, then don't call this function
  const handleExistingAnswerUpdate = (percent) => {
    percent == 1 && markActivityAsCompleted(activity.activityid);
  };

  return (
    <div className="p-4">
      <ReadAndAnswerActivity
        title="ExerciseTitle"
        data={childActivities}
        showAnswers={activity.datajson.showAnswers}
        onUpdateProgress={handleExistingAnswerUpdate}
      />
    </div>
  );
};

export default ExerciseContent;
