import { useEffect, useState } from "react";
import QuizSelectOption from "./QuizSelectOption";

const QuestionSelectList = ({
  score,
  questions,
  activeQuestion,
  setActiveQuestion,
}) => {
  return (
    <div className="sticky top-0 bg-background z-[55]  border-card_dark">
      <div className="flex items-center gap-4 w-full pt-2">
        <div className="font-MS">Questions</div>
        <div className="flex flex-wrap gap-4">
          {questions?.map((question, index) => (
            <QuizSelectOption
              score={score}
              question={question}
              key={`${question?.activityid}-${index}`}
              index={index}
              isActive={question?.activityid == activeQuestion?.activityid}
              handleOptionClick={() => setActiveQuestion(question)}
            />
          ))}
        </div>
      </div>
      <div className="bg-card_dark h-[2px] rounded w-full mt-4 mb-2"></div>
    </div>
  );
};

export default QuestionSelectList;
