import { useEffect } from "react";
import { useParams } from "react-router";
import { useCourse } from "../../contexts/CourseProvider";
import { saveEvent } from "../../api/userevents";
import xapiverbs from "../../config/xapiverbs";
import Modal from "./modal/Modal";
import { ActivityProvider } from "../../contexts/ActivityProvider";

const ActivityView = () => {
  const params = useParams();
  const { activity } = useCourse();

  return (
    <ActivityProvider>
      <Modal />
    </ActivityProvider>
  );
};

export default ActivityView;
