import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavBar from "./components/general/NavBar";
import { AuthProvider, UserProvider } from "./contexts";
import { Navigate, useOutlet, useLocation } from "react-router";
import ActivityView from "./components/activities/ActivityView";
import Heartbeat from "./components/general/Heartbeat";

import {
  ConceptExplorerView,
  CourseView,
  HomeView,
  LoginView,
  RegisterView,
  SettingsView,
  TeacherPortalCourseView,
  ForcedLogout,
} from "./views";
import { getItem } from "./utils/persistanceStorage";
import "react-loading-skeleton/dist/skeleton.css";
import { CourseProvider } from "./contexts/CourseProvider";
import { BrowserView, MobileView } from "react-device-detect";
import { TeacherPortalProvider } from "./contexts/TeacherPortalProvider";
export default function App() {
  // This effect runs once, after the first render
  useEffect(() => {
    document.title = "StudyLens";
  }, []);

  return (
    <>
      <MobileView>
        <div className="absolute w-screen h-screen">
          <div className="h-1/2 flex items-center justify-center flex-col gap-4 bg-card text-center px-4">
            <div className="font-MS font-bold text-[6vw] text-primary">STUDYLENS</div>
            <div className="font-MS text-[3vw]">Please use this website in a dekstop environment</div>
          </div>
          <div className="w-full h-[4px] bg-accent" />
          <div className="h-1/2 bg-card_dark flex justify-center items-center w-full">
            <div className="p-[30vw] h-full w-full flex justify-center items-center">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 381.092 381.092">
                <path d="M272.546,311.188H114.443l-38.897,35.739v16.688c0,9.65,7.825,17.477,17.477,17.477H288.07     c9.65,0,17.477-7.824,17.477-17.477V135.593l-33,30.322L272.546,311.188L272.546,311.188z M190.546,363.213     c-9.94,0-18-8.059-18-18c0-9.94,8.06-18,18-18c9.939,0,18,8.06,18,18C208.546,355.154,200.485,363.213,190.546,363.213z" />
                <path d="M365.249,12.022c-7.473-8.134-20.125-8.669-28.259-1.195l-31.444,28.892V17.477C305.546,7.825,297.722,0,288.069,0     H93.023c-9.651,0-17.477,7.824-17.477,17.477v233.576l-58.507,53.76c-8.134,7.475-8.669,20.125-1.195,28.26     c3.944,4.292,9.329,6.468,14.732,6.468c4.835,0,9.685-1.743,13.526-5.273L364.054,40.282     C372.187,32.807,372.722,20.155,365.249,12.022z M108.546,33h164v37.041l-164,150.689V33L108.546,33z" />
              </svg>
            </div>
          </div>
        </div>
      </MobileView>
      <BrowserView>
        <AuthProvider>
          <AppRouter />
        </AuthProvider>
      </BrowserView>
    </>
  );
}

function AppRouter() {
  return (
    <BrowserRouter>
      <Heartbeat />

      <Routes>
        {/* Authentication Views */}
        <Route path="/register" element={<RegisterView />} />
        <Route path="/login" element={<LoginView />} />

        {/* Protected Views */}
        <Route element={<RequireAuth />}>
          <Route element={<NavBar />}>
            <Route path="/timeout" element={<ForcedLogout />} />
            <Route index={true} path="/home" element={<HomeView />} exact />
            <Route
              path="/settings"
              element={
                <CourseProvider>
                  <SettingsView />
                </CourseProvider>
              }
            />
            <Route path="/conceptexplorer" element={<ConceptExplorerView />} />
            <Route
              path="/course/:courseid/teacher_portal"
              element={
                <TeacherPortalProvider>
                  <TeacherPortalCourseView />
                </TeacherPortalProvider>
              }
              exact
            ></Route>
            {/* Course Views */}
            <Route
              path="/course/:courseid"
              element={
                <CourseProvider>
                  <CourseView />
                </CourseProvider>
              }
              exact
            >
              <Route path=":activityid" element={<ActivityView />} exact />
            </Route>
          </Route>
        </Route>

        <Route path="*" element={<CheckWhereToGo />} />

        {/* <Route path="*" element={<Navigate to="/" />} exact /> */}
      </Routes>
    </BrowserRouter>
  );
}

function RequireAuth() {
  const outlet = useOutlet();
  const userData = getItem("user-data");

  if (!userData) {
    return <Navigate to="/login" replace />;
  }

  const decodedJwt = parseJwt(userData.accessToken);

  if (decodedJwt?.exp * 1000 < Date.now()) {
    // clear local storage
    localStorage.clear();
    return <Navigate to="/login" replace />;
  }

  return <UserProvider>{outlet}</UserProvider>;
}

function CheckWhereToGo() {
  const userData = getItem("user-data");

  if (!userData) {
    return <Navigate to="/login" replace />;
  }

  return <Navigate to="/home" replace />;
}

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};
