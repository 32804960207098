import { useReducer, useState } from "react";
import { useActivity } from "../../../contexts/ActivityProvider";
import { useCourse } from "../../../contexts/CourseProvider";
import { quizInitState, quizReducer } from "./../quiz/QuizReducer";
import { useParams, useNavigate, Navigate } from "react-router";
import { saveEvent } from "../../../api/userevents";
import xapiverbs from "../../../config/xapiverbs";
import { getCurrUnixDate } from "../../../utils/date";
import { processAccessmentActivity } from "../../../api/postactivity";
import { getItem } from "../../../utils/persistanceStorage";
import { StartOfQuiz, EndOfQuiz, ActiveQuiz } from "../quiz";
import { motion } from "framer-motion";

//TODO: Functionality for Multiple answer questions
const QuizContent = () => {
  const { course, updateConceptScores } = useCourse();
  const { activity, childActivities, markActivityAsCompleted } = useActivity();
  const [quizState, quizDispatch] = useReducer(quizReducer, quizInitState);
  const [answers, setAnswers] = useState([]);

  const params = useParams();

  const updateAnswer = (index, answer) => {
    const newAnswers = [...answers];
    newAnswers[index] = answer;
    setAnswers(newAnswers);
  };

  const handleBeginQuiz = () => {
    quizDispatch({ type: "handleStart", childActivities });
    saveEvent(activity.activityid, course.courseid, xapiverbs.STARTED);
  };

  const handleNextQuestion = () => {
    const answer = {
      activityid: quizState.subQuestion.activityid,
      startedTimestamp: quizState.subQuestionStartedTime,
      endedTimestamp: getCurrUnixDate(),
      userAnswerOptionIndexes: quizState.selectedOptions,
      userAnswerString: "",
    };

    saveEvent(
      quizState.subQuestion.activityid,
      course.courseid,
      xapiverbs.ANSWERED,
      {
        option: quizState.options,
        timestamp: getCurrUnixDate(),
      }
    );

    // Update the answer
    if (answers.length > quizState.subQuestionIndex) {
      updateAnswer(quizState.subQuestionIndex, answer);
      let newAnswers = answers;
      newAnswers[quizState.subQuestionIndex] = answer;
      setAnswers(newAnswers);
    } else {
      setAnswers([...answers, answer]);
    }

    const getSelectedOptions = () => {
      try {
        return answers[quizState.subQuestionIndex + 1].userAnswerOptionIndexes;
      } catch (e) {
        return [];
      }
    };

    // Reset to handle next question
    quizDispatch({
      type: "handleNextQuestion",
      childActivities,
      selectedOptions: getSelectedOptions() || [],
    });
  };

  const handlePreviousQuestion = () => {
    const getSelectedOptions = () => {
      try {
        return answers[quizState.subQuestionIndex - 1].userAnswerOptionIndexes;
      } catch (e) {
        return [];
      }
    };

    // Reset to handle previous question
    quizDispatch({
      type: "handlePreviousQuestion",
      childActivities,
      selectedOptions: getSelectedOptions() || [],
    });
  };

  const handleCompleteQuiz = () => {
    const userData = getItem("user-data");

    const processObject = {
      sessionid: userData.sessionid,
      courseid: params.courseid,
      activityid: params.activityid,
      startedTimestamp: quizState.quizStartedTime,
      endedTimestamp: getCurrUnixDate(),
      childActivities: answers,
    };

    processAccessmentActivity(processObject)
      .then((res) => {
        if (res.data.success) {
          saveEvent(
            activity.activityid,
            course.courseid,
            xapiverbs.SUBMITTED,
            processObject
          );
          quizDispatch({ type: "submitQuiz" });
          setTimeout(() => {
            markActivityAsCompleted(params?.activityid);
            updateConceptScores();
          }, 1000);
          window.location.reload();
        } else {
          console.error("error", res.data);
        }
      })
      .catch((e) => console.error(e));
  };

  /**
   * Get progress percentage
   */
  const getProgressPercentage = () => {
    const totalQuestions = childActivities.length;
    const completedQuestions = answers.length;
    return `${Math.round((completedQuestions / totalQuestions) * 100)}%`;
  };

  if (activity.completed) return <div>Completed</div>;

  return (
    <>
      <motion.div
        className="sticky top-0 left-0 w-0 h-1 bg-accent z-[53]"
        animate={{ width: getProgressPercentage() }}
        transition={{ duration: 0.3, type: "tween" }}
      />
      <div className="relative flex flex-col gap-4 w-full p-4">
        {/* Motion line that shows progress */}

        {/* Beginning of quiz */}
        {!quizState.hasStarted && (
          <StartOfQuiz activity={activity} handleBeginQuiz={handleBeginQuiz} />
        )}
        {/* During the quiz, show the subquestions */}
        {quizState.hasStarted && !quizState.hasEnded && (
          <ActiveQuiz
            quizState={quizState}
            quizDispatch={quizDispatch}
            childActivities={childActivities}
            handlePreviousQuestion={handlePreviousQuestion}
            handleNextQuestion={handleNextQuestion}
          />
        )}
        {/* After the quiz, show result section */}
        {quizState.hasEnded && (
          <EndOfQuiz
            handleCompleteQuiz={handleCompleteQuiz}
            isSubmitted={quizState.isSubmitted}
          />
        )}
      </div>
    </>
  );
};

export default QuizContent;
