const OnboardingSlide = (props) => {
  const slide = props.slide;

  return (
    <div className="flex flex-col grow bg-card">
      <div className="flex flex-row grow sm:mx-12 sm:mt-12">
        <div className="w-full max-w-6xl mx-auto bg-background sm:border-2 border-b-0 border-background_fade p-8">
          {Object.keys(slide.datajson).length == 0 &&
            slide.activitytitle != null && (
              <div className="flex flex-col justify-center w-full h-full">
                <span className="text-center text-4xl font-MW mb-12">
                  {slide.activitytitle}
                </span>
              </div>
            )}
          {Object.keys(slide.datajson).length > 0 && (
            <div className="w-full h-full flex items-center justify-center">
              <div>
                {slide.activitytitle != null && (
                  <h1 className="text-4xl text-center font-MS font-extrabold p-6">
                    {slide.activitytitle}
                  </h1>
                )}
                <div className="flex flex-col grow justify-center py-6">
                  {slide.datajson.text != null && ( <div className="pb-6">{slide.datajson.text}</div>)}
                  {slide.datajson.html != null && ( <div dangerouslySetInnerHTML={{ __html: slide.datajson.html }} /> )}
                  {slide.datajson.type == "youtube" && (
                    <iframe
                      className="w-full max-w-4xl mx-auto aspect-video"
                      src={slide.datajson.url}
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                  )}
                  {slide.datajson.type == "image" && (
                    <img
                      className="w-full max-w-4xl mx-auto aspect-video border border-background_fade"
                      src={slide.datajson.url}
                      alt={slide.activitytitle}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OnboardingSlide;
