import { Outlet } from "react-router";
import { Grid } from "@material-ui/core";

import TeacherPortalQuizStatistics from "./QuizStatistics";
import React from "react";

const TeacherPortalQuiz = ({ quizInformation }) => {
    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <TeacherPortalQuizStatistics quizInformation={quizInformation} />
                </Grid>
            </Grid>
            <Outlet />
        </>
    );
};

export default TeacherPortalQuiz;
