import { useEffect, useState } from "react";
import { useActivity } from "../../../contexts/ActivityProvider";
import PDFContent from "./PDFContent";
import QuizContent from "./QuizContent";
import ReadingContent from "./ReadingContent";
import ReadingArticleContent from "./ReadingArticleContent";
import WebContent from "./WebContent";
import VideoContent from "./VideoContent";
import AudioContent from "./AudioContent";
import InteractiveContent from "./InteractiveContent";
import QuizResultContent from "./QuizResult/QuizResultContent";
import PawsTracing from "./PawsTracing";
import ExerciseContent from "./ExerciseContent";
import PawsChallenges from "./PawsChallenges";
import PawsAnimation from "./PawsAnimation";
import PawsPearson from "./PawsPearson";
import PawsReading from "./PawsReading";
import ReadAndAnswerContent from "./ReadAndAnswerContent";

//TODO: Process events from ReadingActivity
const ActivityContent = () => {
  const {
    activity,
    activityStatus,
    activityData,
    modalView,
    setCorrectModalView,
  } = useActivity();

  useEffect(() => {
    setCorrectModalView(activity.activitytype);
  }, []);

  const handleEvent = (e) => {
    alert("Event detected", e);
  };

  // Check what type of activity it is and show the appropriate content.
  if (activityData === null) {
    return (
      <div className="p-4">
        Something went wrong loading the activity content. Please inform the teacher or the developers.
      </div>
    );
  }

  const showQuizResults =
    !activityStatus.retake &&
    activity?.activitytype === "learning_material_quiz" &&
    activityStatus.completed;

  const showQuizContent =
    activityStatus.retake ||
    (activity?.activitytype === "learning_material_quiz" &&
      !activityStatus.completed);

  return (
    <div
      className="relative h-full overflow-y-auto overflow-x-hidden"
      style={{
        height: `calc(100vh - ${modalView.paddingTop} - ${modalView.paddingBottom} - 60px)`,
        maxHeight: `calc(100vh - ${modalView.paddingTop} - ${modalView.paddingBottom} - 60px)`,
      }}
    >
      {activity?.activitytype === "learning_material_article" && <WebContent />}
      {activity?.activitytype === "learning_material_notworkingarticle" && <ReadingArticleContent />}
      {activity?.activitytype === "learning_material_book" && (
        <ReadingContent onEvent={() => handleEvent()} />
      )}
      {activity?.activitytype === "learning_material_video" && <VideoContent />}
      {activity?.activitytype === "learning_material_exercise" && <ExerciseContent />}
      {activity?.activitytype === "learning_material_readandanswer" && <ReadAndAnswerContent />}
      {showQuizResults && <QuizResultContent />}
      {showQuizContent && <QuizContent />}
      {activity?.activitytype === "learning_material_factsheet" && (
        <PDFContent />
      )}
      {activity?.activitytype === "learning_material_audio" && <AudioContent />}
      {activity?.activitytype === "learning_material_interactive" && (
        <InteractiveContent />
      )}
      {activity?.activitytype === "learning_material_paws_tracing" && <PawsTracing />}
      {activity?.activitytype === "learning_material_paws_animated" && <PawsAnimation />}
      {activity?.activitytype === "learning_material_paws_challenges" && <PawsChallenges />}
      {activity?.activitytype === "learning_material_paws_parsons" && <PawsPearson />}
      {activity?.activitytype === "learning_material_paws_reading" && <PawsReading />}
      
    </div>
  );
};

export default ActivityContent;
