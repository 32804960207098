import { Box } from "@material-ui/core";
import { forwardRef } from "react";
import { colors } from "../../../../config/colors";

const TeacherPortalVerticalProgressBar = forwardRef((props, ref) => {

    return <>
        <Box sx={{ width: "80%", height: "20px", margin: 20, border: "1px solid " + colors.txt_primary }} ref={ref} {...props}>
            <Box sx={{ backgroundColor: colors.txt_primary, width: Math.round(100 * props.floatProgress) + "%", height: "100%" }}>
            </Box>
        </Box>
    </>
});

export default TeacherPortalVerticalProgressBar