import { TableContainer, Table, TableHead, TableRow, TableBody, Paper } from "@material-ui/core";
import React from "react";
import TeacherPortalLearningMaterialRow from "./LearningMaterialRow";
import SortingBoldTableCell from "../../general/table/SortingBoldTableCell";

const TeacherPortalLearningMaterialTable = ({ activitiesWithUsageData }) => {
    const [active, setActive] = React.useState('activitytype');
    const [direction, setDirection] = React.useState('asc');

    activitiesWithUsageData = activitiesWithUsageData.map((activityWithUsageData) => {
        const firstTimestamp = (
            activityWithUsageData.use_data
            && activityWithUsageData.use_data.length > 0
            && activityWithUsageData.use_data[0].timestamp)
            ? activityWithUsageData.use_data[0].timestamp
            : undefined
        const lastTimestamp = (
            activityWithUsageData.use_data
            && activityWithUsageData.use_data.length > 0
            && activityWithUsageData.use_data[activityWithUsageData.use_data.length - 1].timestamp)
            ? activityWithUsageData.use_data[activityWithUsageData.use_data.length - 1].timestamp
            : undefined

        activityWithUsageData.first_accessed = firstTimestamp
        activityWithUsageData.last_accessed = lastTimestamp

        return activityWithUsageData
    })

    const activitiesWithUsageDataSorted = () => {
        const activitiesWithUsageDataSorted = activitiesWithUsageData.sort((a, b) => {
            var aValue
            var bValue
            if (active === 'activitytype') {
                aValue = a.activitytype.toLowerCase();
                bValue = b.activitytype.toLowerCase();
            }
            else if (active === 'activitytitle') {
                aValue = a.activitytitle.toLowerCase();
                bValue = b.activitytitle.toLowerCase();
            }
            else if (active === 'first_accessed') {
                aValue = a.first_accessed ? new Date(a.first_accessed) : new Date(0);
                bValue = b.first_accessed ? new Date(b.first_accessed) : new Date(0);
            }
            else if (active === 'last_accessed') {
                aValue = a.last_accessed ? new Date(a.last_accessed) : new Date(0);
                bValue = b.last_accessed ? new Date(b.last_accessed) : new Date(0);
            }
            else if (active === 'count_usage_data') {
                aValue = a.total_usage_data.count_events;
                bValue = b.total_usage_data.count_events;
            }
            else if (active === 'count_users') {
                aValue = a.total_usage_data.count_users;
                bValue = b.total_usage_data.count_users;
            }
            else if (active === 'count_users_last_30_days') {

                aValue = a.total_usage_data.count_users_last_30_days;
                bValue = b.total_usage_data.count_users_last_30_days;
            }


            if (direction === 'asc') {
                if (aValue < bValue) {
                    return -1;
                }
                if (aValue > bValue) {
                    return 1;
                }
                return 0;
            }
            else {
                if (aValue > bValue) {
                    return -1;
                }
                if (aValue < bValue) {
                    return 1;
                }
                return 0;
            }
        })
        return activitiesWithUsageDataSorted;
    }

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <SortingBoldTableCell
                            active={active}
                            setActive={setActive}
                            setDirection={setDirection}
                            direction={direction}
                            name="activitytype"
                        >
                            Activity Type</SortingBoldTableCell>
                        <SortingBoldTableCell
                            active={active}
                            setActive={setActive}
                            setDirection={setDirection}
                            direction={direction}
                            name="activitytitle"
                        >
                            Activity Title</SortingBoldTableCell>
                        <SortingBoldTableCell
                            active={active}
                            setActive={setActive}
                            setDirection={setDirection}
                            direction={direction}
                            name="first_accessed"
                        >
                            First Accessed</SortingBoldTableCell>
                        <SortingBoldTableCell
                            active={active}
                            setActive={setActive}
                            setDirection={setDirection}
                            direction={direction}
                            name="last_accessed"
                        >
                            Last Accessed</SortingBoldTableCell>
                        <SortingBoldTableCell
                            active={active}
                            setActive={setActive}
                            setDirection={setDirection}
                            direction={direction}
                            name="count_usage_data"
                        >
                            Amount of actions</SortingBoldTableCell>
                        <SortingBoldTableCell
                            active={active}
                            setActive={setActive}
                            setDirection={setDirection}
                            direction={direction}
                            name="count_users"
                        >
                            Total users</SortingBoldTableCell>
                        <SortingBoldTableCell
                            active={active}
                            setActive={setActive}
                            setDirection={setDirection}
                            direction={direction}
                            name="count_users_last_30_days"
                        >
                            Users in last 30 days</SortingBoldTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {activitiesWithUsageDataSorted().map((activityWithUsageData) =>
                        <TeacherPortalLearningMaterialRow activityWithUsageData={activityWithUsageData} />
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}


export default TeacherPortalLearningMaterialTable;
