import { Bar, BarChart, CartesianGrid, Legend, XAxis, YAxis, Tooltip, Label, Cell } from "recharts";

import React from 'react';
import { Typography } from "@material-ui/core";
import { interlaceColorFromGreenToRed } from "../../../utils/colors";
import TeacherPortalLearningMaterialModal from "./learning_material/LearningMaterialModal";
import { activityTypeToName } from "../../../utils/teacher_portal/activityTypeToString";

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const TeacherPortalLearningMaterialInteractionChart = ({ activitiesWithUsageDataGroupedByType, width, height }) => {
    const [data, setData] = React.useState([]);
    const [zoomedInOn, setZoomedInOn] = React.useState(undefined)
    const [modalLearningMaterialId, setModalLearningMaterialId] = React.useState(undefined)
    const [userClickedOnBar, setUserClickedOnBar] = React.useState(false)
    const [userClickedOnChart, setUserClickedOnChart] = React.useState(false);
    const [maxAmountOfUsers, setMaxAmountOfUsers] = React.useState(0)

    // Prepare data for chart
    React.useEffect(() => {
        if (zoomedInOn === undefined) {
            const data = Object.entries(activitiesWithUsageDataGroupedByType).map((pair) => {
                const maximumLearningMaterialsUsedByUsers = pair[1].map(obj => obj.total_usage_data?.count_users).reduce((a, b) => { return Math.max(a, b) })
                const totalSumLearningMaterialsUsedByUsers = pair[1].map(obj => obj.total_usage_data?.count_users).reduce((a, b) => { return a + b })
                const minimumLearningMaterialsUsedByUsers = pair[1].map(obj => obj.total_usage_data?.count_users).reduce((a, b) => { return Math.min(a, b) })
                return { key: pair[0], name: activityTypeToName(pair[0]), "minimum_users_for_type": minimumLearningMaterialsUsedByUsers, "maximum_users_for_type": maximumLearningMaterialsUsedByUsers, "average_users_per_activity": Math.round(totalSumLearningMaterialsUsedByUsers / pair[1].length) }
            });
            data.sort((a, b) => b.average_users_per_activity - a.average_users_per_activity)
            setData(data)

            setMaxAmountOfUsers(data.map((entry) => entry.maximum_users_for_type).reduce((a, b) => { return Math.max(a, b) }, 0))  // Get max amount of users for scaling
        }
        else {
            const data = Object.entries(activitiesWithUsageDataGroupedByType[zoomedInOn]).map((activity) => {
                const totalSumLearningMaterialsUsedByUsers = activity[1].total_usage_data.count_users
                return { key: activity[0], name: activity[1].activitytitle, "users_for_activity": totalSumLearningMaterialsUsedByUsers }
            });
            data.sort((a, b) => b.users_for_activity - a.users_for_activity)
            setData(data)
        }

    },
        [activitiesWithUsageDataGroupedByType, zoomedInOn]
    );


    // Handle chart events
    const clickOnBar = (eventargs) => {
        if (zoomedInOn !== undefined) {

            openLearningMaterialModal(eventargs)
        }
        else {
            setZoomedInOn(eventargs.key)
        }
        setUserClickedOnBar(true)

    }

    const openLearningMaterialModal = (eventargs) => {
        setModalLearningMaterialId(+eventargs.key)

    }

    // Sadly we are unable to silence bubbling of event to chart from bar,
    // so we use a use state that checks if only one of them was clicked
    // If so, we know that the user clicked on the chart 
    const clickOnChart = (eventargs) => {
        setUserClickedOnChart(true)
    }

    React.useEffect(() => {
        if (userClickedOnChart && !userClickedOnBar) {
            setZoomedInOn(undefined)
            setUserClickedOnChart(false)
            setUserClickedOnBar(false)
        }
        else {
            setUserClickedOnBar(false)
            setUserClickedOnChart(false)
        }
    }, [userClickedOnChart, userClickedOnBar])



    return (
        <>
            {zoomedInOn !== undefined
                && modalLearningMaterialId !== undefined
                && <TeacherPortalLearningMaterialModal
                    activityWithUsageData={activitiesWithUsageDataGroupedByType[zoomedInOn][modalLearningMaterialId]}
                    onClose={() => setModalLearningMaterialId(undefined)} />
            }
            <Typography
                style={{
                    paddingTop: "35px",
                    color: "grey",
                    float: "right",
                    marginTop: -60
                }} >
                {zoomedInOn === undefined ? "Click on a bar to zoom in" : "Click on a bar to see details, on chart to zoom out"}
            </Typography>

            <BarChart style={{ cursor: zoomedInOn !== undefined && "pointer" }} data={data} width={width} height={height} layout="horizontal" margin={{ left: 50, bottom: 10, right: 20 }} onClick={clickOnChart}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" ticks={zoomedInOn ? [""] : null}>
                </XAxis>
                <YAxis domain={[0, maxAmountOfUsers + 2]}>
                    <Label value="Number of users" angle={-90} position="insideLeft" />
                </YAxis>
                <Tooltip />
                <Legend verticalAlign="top" />
                {zoomedInOn === undefined ?
                    <>
                        <Bar dataKey="average_users_per_activity" unit=" Users" name="Average material used by" fill="rgb(255 205 0)" onClick={clickOnBar} style={{ cursor: "pointer" }}>
                            {data.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={interlaceColorFromGreenToRed(entry.average_users_per_activity / maxAmountOfUsers)} />
                            ))}
                        </Bar>
                    </>
                    :
                    <>
                        <Bar dataKey="users_for_activity" unit=" Users" name="Users that have used material" fill="rgb(255 205 0)" onClick={clickOnBar} style={{ cursor: "pointer" }}>
                            {data.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={interlaceColorFromGreenToRed(entry.users_for_activity / maxAmountOfUsers)} />
                            ))}

                        </Bar>
                    </>
                }
            </BarChart>
        </>
    )
}

export default TeacherPortalLearningMaterialInteractionChart;