import axios from "./axios";
import { getItem, setItem } from "../utils/persistanceStorage";
import { scoringMethod } from "../config/experimentgroups";

const getActivitiesForCourse = async (courseid) => {
  return await axios.get(
    `/getactivitiesforusercourse`,
    {
      params: { courseid },
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
const getCourseOnboardingActivity = async (courseid) => {
  return await axios.get(
    `onboarding_activity`,
    {
      params: { courseid }
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}
const getCourseLGs = async (courseid) => {
  return await axios.get(
    `/get_learning_goals`,
    { params: { courseid } },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const getCourseConcepts = async (courseid) => {
  return await axios.get(
    `/concepts`,
    { params: { courseid } },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const getCourseConceptRelations = async (courseid) => {
  return await axios.get(
    `/concept_relations`,
    { params: { courseid } },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const getCourseConceptScores = async (courseid) => {
  const userGroup = getItem("user-courses").find((course) => course.courseid === courseid);
  const scoringmethod = scoringMethod(Number(userGroup.experimentgroup));
    return await axios.get(
    `/concept_scores`,
    { params: { courseid, scoringmethod } },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const getConceptsForActivity = async (activityid) => {
  return await axios.get(
    `/getconceptsforactivity`,
    {params: {activityid}},
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const getLeaderboard = async (courseid, quizid) => {
  return await axios.get(
    `/get_leaderboard`,
    {params: {courseid, quizid}},
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const getLeaderboardQuiz = async (courseid, quizid) => {
  return await axios.get(
    `/get_leaderboardquiz`,
    {params: {courseid, quizid}},
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const getLeaderboardConstruct = async (courseid, constructid) => {
  return await axios.get(
    `/get_leaderboardconstruct`,
    {params: {courseid, constructid}},
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const getLeaderboardProgress = async (courseid) => {
  return await axios.get(
    `/get_leaderboard_progress`,
    {params: {courseid}},
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const getQuizInformationOfCourse = async (courseid) => {
  return await axios.get(
    `/get_quiz_information_of_course`,
    {params: {courseid}},
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const getQuizInformation = async (quizid) => {
  return await axios.get(
    `/get_quiz_information`,
    {params: {quizid}},
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const getStudentsInformation = async (courseid) => {
  return await axios.get(
    `/students_information_of_course`,
    { params: { courseid } },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};


export default {
  getActivitiesForCourse,
  getCourseOnboardingActivity,
  getCourseLGs,
  getCourseConceptRelations,
  getCourseConceptScores,
  getCourseConcepts,
  getConceptsForActivity,
  getLeaderboard,
  getLeaderboardQuiz,
  getLeaderboardConstruct,
  getLeaderboardProgress,
  getStudentsInformation,
  getQuizInformationOfCourse,
  getQuizInformation,
  
};
