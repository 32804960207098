export const RP_CONFIG = [
  // Social Group 0 - No Social comparison
  {
    show_misconceptions: false,
    show_concepts: false,
    show_class_score: false,
  },
  // Social Group 1 - Social comparison on demand
  {
    show_misconceptions: true,
    show_concepts: true,
    show_class_score: true,
  },
  // Social Group 2 - Social comparison by default
  {
    show_misconceptions: true,
    show_concepts: true,
    show_class_score: true,
  },
  // Leaderbord (Pauline)
  {
  },
  // Goal orientation group - mastery-approach
  {
  },
  // Goal orientation group - mastery-avoidant
  {
  },
  // Goal orientation group - performance-approach
  {
  },
  // Goal orientation group - performance-avoidant
  {
  }
];
