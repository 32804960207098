import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useCourse } from "../../../contexts/CourseProvider";
import { getDisplayState, getMonthOfDate } from "../../../utils/date";
import { colors } from "../../../config/colors";

const QuizButton = ({ lg, quizid, quizindex}) => {
  const navigate = useNavigate();

  // displayState can be past, active or future
  const displayState = getDisplayState(lg);

  const { activities } = useCourse();

  // Convert unix timestamp to javascript new Date()
  const dateactive = new Date(lg.lgdateactive * 1000);
  const displayDate = `${getMonthOfDate(dateactive)} ${dateactive.getDate()}`;

  // Find the first quiz activity
  const quizActivity = activities.find(
    (a) => a.activityid == quizid
  );
  /*const practicumActivity = activities.find(
    (a) => a.activityid == lg.mainactivityid
  );*/

  //const exerciseActivity = activities.find((a) => a.activityid == lg.exerciseactivityid);
  const extraProperties = lg.extraProps1;

  function handleClick() {
    navigate(`${quizActivity.activityid}`)
  }

  return (
    <div className="flex flex-col items-center gap-2 rounded px-4 justify-center px-2" style={{height: "70px", color: "white", display: 'inline'}}>
      {quizActivity && (
        <>
          <div
            onClick={() => handleClick()}
            className="text-txt_secondary hover:scale-105 duration-200 cursor-pointer px-2 my-1 py-1 w-fit h-fit rounded shadow-default text-sm truncate button-40"
            style={{color:"white"}}
          >
          {quizActivity.completed ? "Quiz " + quizindex + " Results" : "Take Quiz " + quizindex}
          </div>
        </>
      )}
      </div>
  );
};

export default QuizButton;
