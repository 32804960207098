import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/auth/AuthContext";
import generalApi from "../../api/general";
import { autoevents } from "../../config/monitor";
import { useCourse } from "../../contexts/CourseProvider";


const Heartbeat = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    const sendHeartBeatCaller = () => {
      if (isLoggedIn()) {
        generalApi.sendHeartbeat()
          .then((response) => {
            if (response.data.time_since_last_event > autoevents.inactiveLimit) {
              console.log("Timeout - Forcing logout for security");
              navigate("/timeout");
            }
          })
          .catch((error) => {
            console.error("Error sending heartbeat:", error);
          });
      }
    };

    const heartbeatInterval = setInterval(sendHeartBeatCaller, autoevents.heartbeatInterval); 

    return () => {
      clearInterval(heartbeatInterval);
    };
  }, []);

  return null;
};

export default Heartbeat;
