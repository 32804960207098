import { useEffect, useState } from "react";

const EndOfQuiz = ({ handleCompleteQuiz, isSubmitted }) => {
  return (
    <div className="relative">
      {!isSubmitted ? (
        <div>
          You have successfully finished this quiz! To save the results and see
          how you performed press the SUBMIT ANSWERS button.
        </div>
      ) : (
        <div>The results have been submitted. The results are loading.</div>
      )}
      {!isSubmitted && (
        <div
          onClick={handleCompleteQuiz}
          className="mt-2 p-4 w-fit bg-accent shadow-default rounded-lg cursor-pointer hover:scale-110 duration-200"
        >
          SUBMIT ANSWERS
        </div>
      )}
    </div>
  );
};

export default EndOfQuiz;
