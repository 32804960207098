import { Outlet } from "react-router";
import { Grid, Card, CardHeader, CardContent } from "@material-ui/core";
import React from "react";
import TeacherPortalLearningMaterialTable from "./table/LearningMaterialsTable";


const TeacherPortalLearningMaterials = ({ activitiesWithUsageData, course }) => {

    return (
        <>

            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title="Learning Materials" />
                        <CardContent>
                            <TeacherPortalLearningMaterialTable activitiesWithUsageData={activitiesWithUsageData} />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={1}></Grid>

            </Grid>
            <Outlet />
        </>
    );
};

export default TeacherPortalLearningMaterials;
