import axios from "./axios";


const getActivityDetails = (activityid, courseid) => {
  return axios.get(
    `/getactivity`,
    {
      params: { courseid, activityid },
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const getSessionStats = (sessionid,courseid) => {
  return axios.get(
    `/get_session_stats`,
    {
      params: { sessionid, courseid },
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

export default {
  getActivityDetails,
  getSessionStats,
};
