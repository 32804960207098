import { useState } from "react";
import { useAuth } from "../contexts/auth/AuthContext";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import Loading from "./../components/general/Loading";

const LoginView = () => {
  const { authLogin } = useAuth();
  let navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true);
    setErrorMessage("");
    if (email.trim().length <= 0) {
      setErrorMessage("Please fill in a valid login.");
      setLoading(false);
      return;
    }
    if (password.trim().length <= 0) {
      setErrorMessage("Please fill in a password.");
      setLoading(false);
      return;
    }

    authLogin({ email, password })
      .then((res) => {
        navigate("/home");
        setLoading(false);
      })
      .catch((err) => {
        switch (err.response.status) {
          case 400:
            setErrorMessage("Your email/password combination didn't match. Please try again.");
            break;
          case 401:
            setErrorMessage("Your email/password combination didn't match. Please try again.");
            break;
          default:
            setErrorMessage("Something went wrong. Please try again later.");
            break;
        }
        setLoading(false);
      });
  };

  return (
    <div className="flex justify-center items-center w-screen h-screen">
      <div className="flex flex-col gap-2 mt-[-100px] items-center justify-center w-fit h-screen">
        <div className="text-5xl font-bold mb-12 text-txt_primary_light">StudyLens</div>
        <div className="text-3xl font-bold">Sign In</div>
        {/* <NavLink className="text-base text-green-500" to="/register">
          Need an account?
        </NavLink> */}
        {loading && <Loading />}
        <div className="text-base text-error my-2">{errorMessage}</div>
        <div className="flex flex-col gap-2 items-center justify-center">
          <input
            className="shadow-large rounded p-2 w-[90vw] md:w-[30vw] md:min-w-[400px]"
            placeholder="Studylens login..."
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            className="shadow-large rounded p-2 w-[90vw] md:w-[30vw] md:min-w-[400px]"
            placeholder="Password..."
            type="password"
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <button className="btn btn-blue py-2 px-4 rounded place-self-end cursor-pointer" onClick={handleSubmit}>
            Login
          </button>
        </div>
        <div className="flex flex-col gap-2 items-center justify-center min-w-full text-slate-500">
          <div className="bg-rose-50 min-w-full px-5 animate-attention">
            Your username and password are provided by your teacher. Username looks like evo23456 or code23456. <br />If the problem due to logging failure
            persists, please contact your teacher.
          </div>

          <div className="bg-rose-50 min-w-full px-5 animate-attention">
            Notice an issue?{" "}
            <a
              href="https://forms.office.com/e/WTAp04gTbv"
              className="text-red-300 hover:text-red-500"
              target="_blank"
              rel="noreferrer"
            >
              Click here to report
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginView;
