import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";

const QuestionFormat = ({
  question,
  questionData,

  answersIndexes = [],
  showAnswers = false,

  handleOptionStyle = () => null,
  handleOptionClick = () => null,
  enableClick = false,
}) => {
  const UserSelection = ({ index }) => {
    const userSelected = answersIndexes.includes(index);

    if (userSelected && questionData?.answerScores[index]) {
      return <AiOutlineCheck size={30} />;
    }
    if (userSelected) {
      return <AiOutlineClose size={30} />;
    }
    return null;
  };

  return (
    <div className="space-y-2 my-2">
      <div>{question?.activitytitle}</div>
      {questionData?.questionAttachment && (
        <div>
          <img
            className="hover:scale-150 ease-in-out duration-500 origin-left w-1/2 max-w-screen-md"
            src={questionData?.questionAttachment}
            alt="question attachment"
          />
        </div>
      )}
      <div
        dangerouslySetInnerHTML={{
          __html: questionData?.questionDescription,
        }}
      />
      <div className="flex flex-col gap-4">
        {questionData?.options.map((option, index) => (
          <motion.div
            key={`option-${index}`}
            className="shadow-default text-center p-2 rounded bg-card_dark flex items-center gap-2"
            style={{
              ...handleOptionStyle(index),
              cursor: enableClick ? "pointer" : "default",
            }}
            whileHover={{ scale: enableClick ? 1.01 : 1 }}
            onClick={() => handleOptionClick(index)}
          >
            {showAnswers && <UserSelection index={index} />}
            {/* <div >{option}</div> */}
            <div dangerouslySetInnerHTML={{ __html: option }} />
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default QuestionFormat;
