import MessageCard from "./MessageCard";
import generalApi from "../../api/general";
import { saveEvent } from "../../api/userevents";
import xapiverbs from "../../config/xapiverbs";
import { getItem } from "../../utils/persistanceStorage";
import { useParams } from "react-router";

const WelcomeScreen = ({ onClose, messages, onActivity }) => {
  const params = useParams();
  const dismissMessage = (eid) => {
    generalApi.setMessageInactive(eid);

    saveEvent(-1, params.courseid, getItem("user-data").sessionid, xapiverbs.DISMISSED, {
      target: "message",
      context: "WelcomeScreen",
      eid: eid,
    });
  };

  return (
    <div className="z-[51] fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-75 justify-center flex">
      <div className="flex mx-12 mt-12 h-3/4 overflow-hidden">
        <div className="bg-background border-2 flex flex-col p-8 w-full h-full items-center justify-start">
          <div className="text-2xl text-center py-4 ">Welcome Back</div>
          <div className="overflow-y-scroll overflow-x-hidden h-full">
            {messages.map((message) => (
              <MessageCard messagetext={message.text} key={message.eid} onDismiss={() => dismissMessage(message.eid)} />
            ))}
          </div>

          <div className="flex-grow"></div>

          <div className="btn bg-accent text-center w-32 cursor-pointer rounded text-white p-2 mt-4" onClick={onClose}>
            Thank You
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeScreen;
