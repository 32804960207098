import { Tree, TreeNode } from "react-organizational-chart";
import ConceptCard from "./ConceptCard";
import { useCourse } from "../../../contexts/CourseProvider";
import { getDisplayState } from "../../../utils/date";
import ConceptNode from "./ConceptNode";
import ReactTooltip from "react-tooltip";

const ConceptTree = ({ concept, lg }) => {
  const { getConceptChildren } = useCourse();

  const hasConceptChildren = getConceptChildren(concept.id).filter(
    (con) => con.type === "concept"
  );

  return (
    <div className="px-4 py-1">
      <Tree
        label={<LabelNode concept={concept} lg={lg} />}
        lineWidth={
          concept.type === "fauxconcept" || hasConceptChildren.length <= 0
            ? "0px"
            : "1px"
        }
      >
        {getConceptChildren(concept.id).map(
          (childConcept) =>
            childConcept.type === "concept" && (
              <ConceptNode
                key={`treenode-${childConcept.id}-`}
                concept={childConcept}
                lg={lg}
              />
            )
        )}
      </Tree>
      <ReactTooltip place="top" type="info" backgroundColor="#10101044" effect="float" />
    </div>
  );
};

const LabelNode = ({ concept, lg }) => {
  // displayState can be past, active or future
  const displayState = getDisplayState(lg);

  return (
    <div className="flex align-center justify-center">
      <ConceptCard
        concept={concept}
        disabled={displayState === "future"}
        lg={lg}
      />
    </div>
  );
};

export default ConceptTree;
