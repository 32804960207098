import { useEffect, useState } from "react";
import { useActivity } from "../../../contexts/ActivityProvider";
import { requireAsset } from "../../../utils/asset";

const ReadingArticleContent = () => {
  const { activity, activityData } = useActivity();

  //const chapters = activityData.chapters.join(", ");
  //const sections = activityData.sections.join(", ");

  return (

        

      <div className="px-40 grid grid-cols-2 center content-center content-between pt-20">

        <div className="text-xl w-[400px]">
          Click the Link to open the article on another tab. Come back after you read the article and click <i>Mark as Completed </i> to track your progress.
          
        </div>
        <div  className="text-xl w-[300px]">
          <a href={activityData.url} style={{textDecoration: "underline"}} target="_blank" rel="noreferrer">{activityData.url} </a>
        </div>
      </div>

   
  );
};

export default ReadingArticleContent;
