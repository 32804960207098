import { useState } from "react";
import ExerciseQuestion from "./ExerciseQuestion";
import "./exercise.css";


function Exercise({ title, introText, bridge, questions, showAnswer }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="container mx-auto w-max">
      <h1 className="text-4xl font-normal leading-normal mt-0 mb-2 text-pink-800">
        {title}
      </h1>
      <div
        dangerouslySetInnerHTML={{
          __html: introText,
        }}
      />
      <div className="py-5">{bridge}</div>
      {questions.map((question, index) => {
        const questionParsed = question.datajson;
        return (
          <ExerciseQuestion
            key={question.activityid}
            activityid={question.activityid}
            questionTitle={question.activitytitle}
            questionText={questionParsed.questionText}
            questionAttachment={questionParsed.questionAttachment}
            attachmentCaption={questionParsed.attachmentCaption}
            isReadingOnly={questionParsed.readingOnly}
            answer={questionParsed.questionAnswer}
            showAnswer={showAnswer}
          />
        );
      })}
      <div className="px-5 py-5 text-sm">
        <center>
        Remember to save all your answers before closing this window. <br />If you have completed all the answers, you can click 
        `Mark as Complete` on the top left of this screen. <br />You can come back to edit your answers at any time. 
        </center>
      </div>
    </div>
  );
}

export default Exercise;
