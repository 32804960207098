import { useState, useEffect } from "react";
import {
  AiOutlineMenu,
  AiOutlineSetting,
  AiOutlineHome,
  AiFillBook,
  AiOutlineStar,
} from "react-icons/ai";
import { FiLogOut } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate, Outlet, useLocation } from "react-router";
import { getItem } from "../../utils/persistanceStorage";
import { useAuth } from "../../contexts/auth/AuthContext";
import { colors } from "../../config/colors";
import { useUser } from "../../contexts/UserContext";
import { BrowserView, MobileView } from "react-device-detect";
import { HiAcademicCap } from "react-icons/hi";
const DASHBOARD_ITEMS = [
  { text: "Home", Icon: AiOutlineHome, route: "/home" },
  { text: "Concept Map", Icon: AiOutlineStar, route: "/conceptexplorer" },
  // { text: "Settings", Icon: AiOutlineSetting, route: "/settings" },
];

const NavBar = () => {
  const [showDrawer, setShowDrawer] = useState(false);
  const { courses } = useUser();
  const { authLogout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [courseDashBoardItems, setCourseDashBoardItems] = useState([]);

  useEffect(() => {
    // get courses from local storage and add them to DASHBOARD_ITEMS
    const locCourses = getItem("user-courses");
    if (locCourses?.length > 0) {
      var newCourseDashboardItems = locCourses.map((course) => {
        return {
          text: course.coursename,
          Icon: AiFillBook,
          route: `/course/${course.courseid}`,
        };
      });
      newCourseDashboardItems = newCourseDashboardItems.concat(
        locCourses
          .filter((course) => course.role === "teacher")
          .map((course) => {
            return {
              text: course.coursename + " Teacher view",
              Icon: HiAcademicCap,
              route: `/course/${course.courseid}/teacher_portal`,
            };
          })
      );

      setCourseDashBoardItems([...newCourseDashboardItems]);
    }
  }, [courses]);

  /**
   * close the drawer whenever there is a navigation change
   */
  useEffect(() => {
    setShowDrawer(false);
  }, [location]);

  const handleLogout = () => {
    authLogout();
    navigate("/login", { replace: true });
  };

  return (
    <>
      <nav className="h-[75px]">
        {/* Toolbar */}
        <div className="font-MS relative w-full h-full flex justify-between p-4 py-4 bg-accent text-txt_primary elevation text-2xl shadow-default">
          <div className="flex items-center gap-8">
            {/* Hamburger menu */}
            <div
              className="cursor-pointer"
              onClick={() => setShowDrawer((showDrawer) => !showDrawer)}
            >
              <AiOutlineMenu />
            </div>
            {/* Studylens title */}
            <NavLink to="/">
              <div className="font-bold text-txt_secondary">STUDYLENS</div>
            </NavLink>
          </div>
          <div className="flex gap-4 items-center justify-between">
            <div className="text-lg font-bold">
              {getItem("user-data")?.displayname}
            </div>
            <div
              className="flex items-center justify-center gap-4 text-lg cursor-pointer"
              onClick={handleLogout}
            >
              <FiLogOut />
            </div>
          </div>
          {/* Sign out  */}
        </div>
        {/* Drawer */}
        <AnimatePresence exitBeforeEnter>
          {showDrawer && (
            <>
              <motion.div
                initial={{ x: -400 }}
                animate={{ x: 0 }}
                exit={{ x: -400 }}
                transition={{ type: "ease" }}
                className="z-50 absolute top-0 pt-4 left-0 h-screen bg-background shadow-xl"
              >
                <div className="px-12 pt-4 flex items-center font-MS font-semibold gap-4">
                  <span className="text-txt_primary">General</span>
                  <div className="border-b-2 border-card_dark grow" />
                </div>
                {/* General drawer items */}
                {DASHBOARD_ITEMS.map((item) => (
                  <NavLink to={item.route} key={item.route}>
                    <div className="px-12 py-4 flex items-center gap-4">
                      <item.Icon className="stroke-icon" size={30} />
                      <span>{item.text}</span>
                    </div>
                  </NavLink>
                ))}
                {/* Shows enrolled courses KM */}
                {courseDashBoardItems.length > 0 && (
                  <div className="px-12 pt-4 flex items-center font-MS font-semibold gap-4">
                    <span className="text-txt_primary">Courses</span>
                    <div className="border-b-2 border-card_dark grow" />
                  </div>
                )}
                {courseDashBoardItems.map((item) => (
                  <NavLink to={item.route} key={item.route}>
                    <div className="px-12 py-4 flex items-center gap-4">
                      <item.Icon size={30} />
                      <span className="text-txt_primary">{item.text}</span>
                    </div>
                  </NavLink>
                ))}
              </motion.div>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="z-30 absolute top-0 left-0 h-screen w-screen bg-background_fade"
                onClick={() => setShowDrawer(false)}
              />
            </>
          )}
        </AnimatePresence>
      </nav>
      <div className="z-10 relative">
        <Outlet />
      </div>
    </>
  );
};

export default NavBar;
